import React, { lazy } from 'react'

const DebtorLedgerSubmenu = lazy(() =>
  import('./DebtorLedgerSubmenu').then(module => ({
    default: module.DebtorLedgerSubmenu,
  }))
)

// const GeneralDebtorLedgerListing = lazy(() =>
//   import(
//     '../DebtorAccountModule/DebtorLedgerListing/GeneralDebtorLedgerListing'
//   ).then(module => ({
//     default: module.GeneralDebtorLedgerListing,
//   }))
// )

const DebtorInvoiceListing = lazy(() =>
  import(
    '../DebtorAccountModule/DebtorLedgerListing/DebtorInvoiceListing'
  ).then(module => ({
    default: module.DebtorInvoiceListing,
  }))
)
const DebtorDebitNoteListing = lazy(() =>
  import(
    '../DebtorAccountModule/DebtorLedgerListing/DebtorDebitNoteListing'
  ).then(module => ({
    default: module.DebtorDebitNoteListing,
  }))
)
const DebtorCreditNoteListing = lazy(() =>
  import(
    '../DebtorAccountModule/DebtorLedgerListing/DebtorCreditNoteListing'
  ).then(module => ({
    default: module.DebtorCreditNoteListing,
  }))
)
const DebtorRefundListing = lazy(() =>
  import('../DebtorAccountModule/DebtorLedgerListing/DebtorRefundListing').then(
    module => ({
      default: module.DebtorRefundListing,
    })
  )
)
const DebtorReceiptListing = lazy(() =>
  import(
    '../DebtorAccountModule/DebtorLedgerListing/DebtorReceiptListing'
  ).then(module => ({
    default: module.DebtorReceiptListing,
  }))
)

const GeneralDebtorLedgerDetail = lazy(() =>
  import(
    '../DebtorAccountModule/DebtorLedgerDetail/GeneralDebtorLedgerDetail'
  ).then(module => ({
    default: module.GeneralDebtorLedgerDetail,
  }))
)

const DebtorInvoiceForm = lazy(() =>
  import('../DebtorAccountModule/DebtorLedgerForm/DebtorInvoiceForm').then(
    module => ({
      default: module.DebtorInvoiceForm,
    })
  )
)

const DebtorDebitNoteForm = lazy(() =>
  import('../DebtorAccountModule/DebtorLedgerForm/DebtorDebitNoteForm').then(
    module => ({
      default: module.DebtorDebitNoteForm,
    })
  )
)

const DebtorCreditNoteForm = lazy(() =>
  import('../DebtorAccountModule/DebtorLedgerForm/DebtorCreditNoteForm').then(
    module => ({
      default: module.DebtorCreditNoteForm,
    })
  )
)

const DebtorReceiptForm = lazy(() =>
  import('../DebtorAccountModule/DebtorLedgerForm/DebtorReceiptForm').then(
    module => ({
      default: module.DebtorReceiptForm,
    })
  )
)

const DebtorRefundForm = lazy(() =>
  import('../DebtorAccountModule/DebtorLedgerForm/DebtorRefundForm').then(
    module => ({
      default: module.DebtorRefundForm,
    })
  )
)

const GeneralDebtorLedgerPDF = lazy(() =>
  import('./DebtorLedgerPDFView/GeneralDebtorLedgerPDF').then(module => ({
    default: module.GeneralDebtorLedgerPDF,
  }))
)

const debtorAccountRoutes = [
  //..........Submenu..........//
  {
    props: {
      exact: true,
      path: '/hotel/:hotelID/debtor/account/:debtorID',
    },
    component: <DebtorLedgerSubmenu />,
  },

  //..........Listing..........//
  {
    props: {
      exact: true,
      path: '/hotel/:hotelID/debtor/account/invoices/:debtorID',
    },
    component: <DebtorInvoiceListing ledgerType="invoices" />,
  },
  {
    props: {
      exact: true,
      path: '/hotel/:hotelID/debtor/account/debit-notes/:debtorID',
    },
    component: <DebtorDebitNoteListing ledgerType="debit-notes" />,
  },
  {
    props: {
      exact: true,
      path: '/hotel/:hotelID/debtor/account/credit-notes/:debtorID',
    },
    component: <DebtorCreditNoteListing ledgerType="credit-notes" />,
  },
  {
    props: {
      exact: true,
      path: '/hotel/:hotelID/debtor/account/receipts/:debtorID',
    },
    component: <DebtorReceiptListing ledgerType="receipts" />,
  },
  {
    props: {
      exact: true,
      path: '/hotel/:hotelID/debtor/account/refunds/:debtorID',
    },
    component: <DebtorRefundListing ledgerType="refunds" />,
  },

  //..........Detail..........//
  {
    props: {
      exact: true,
      path:
        '/hotel/:hotelID/debtor/account/invoices/detail/:debtorID/:ledgerID',
    },
    component: <GeneralDebtorLedgerDetail ledgerType="invoices" />,
  },
  {
    props: {
      exact: true,
      path:
        '/hotel/:hotelID/debtor/account/debit-notes/detail/:debtorID/:ledgerID',
    },
    component: <GeneralDebtorLedgerDetail ledgerType="debit-notes" />,
  },
  {
    props: {
      exact: true,
      path:
        '/hotel/:hotelID/debtor/account/credit-notes/detail/:debtorID/:ledgerID',
    },
    component: <GeneralDebtorLedgerDetail ledgerType="credit-notes" />,
  },
  {
    props: {
      exact: true,
      path:
        '/hotel/:hotelID/debtor/account/receipts/detail/:debtorID/:ledgerID',
    },
    component: <GeneralDebtorLedgerDetail ledgerType="receipts" />,
  },
  {
    props: {
      exact: true,
      path: '/hotel/:hotelID/debtor/account/refunds/detail/:debtorID/:ledgerID',
    },
    component: <GeneralDebtorLedgerDetail ledgerType="refunds" />,
  },

  //..........Form..........//
  //...Invoice...//
  {
    props: {
      exact: true,
      path: '/hotel/:hotelID/debtor/account/invoices/:debtorID/add',
    },
    component: <DebtorInvoiceForm ledgerType="invoices" formMode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/hotel/:hotelID/debtor/account/invoices/:debtorID/:ledgerID/edit',
    },
    component: <DebtorInvoiceForm ledgerType="invoices" formMode="edit" />,
  },
  //...DebitNote...//
  {
    props: {
      exact: true,
      path: '/hotel/:hotelID/debtor/account/debit-notes/:debtorID/add',
    },
    component: <DebtorDebitNoteForm ledgerType="debit-notes" formMode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/hotel/:hotelID/debtor/account/debit-notes/:debtorID/:ledgerID/edit',
    },
    component: <DebtorDebitNoteForm ledgerType="debit-notes" formMode="edit" />,
  },
  //...CreditNote...//
  {
    props: {
      exact: true,
      path: '/hotel/:hotelID/debtor/account/credit-notes/:debtorID/add',
    },
    component: (
      <DebtorCreditNoteForm ledgerType="credit-notes" formMode="add" />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/hotel/:hotelID/debtor/account/credit-notes/:debtorID/:ledgerID/edit',
    },
    component: (
      <DebtorCreditNoteForm ledgerType="credit-notes" formMode="edit" />
    ),
  },
  //...Receipt...//
  {
    props: {
      exact: true,
      path: '/hotel/:hotelID/debtor/account/receipts/:debtorID/add',
    },
    component: <DebtorReceiptForm ledgerType="receipts" formMode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/hotel/:hotelID/debtor/account/receipts/:debtorID/:ledgerID/edit',
    },
    component: <DebtorReceiptForm ledgerType="receipts" formMode="edit" />,
  },
  //...Refund...//
  {
    props: {
      exact: true,
      path: '/hotel/:hotelID/debtor/account/refunds/:debtorID/add',
    },
    component: <DebtorRefundForm ledgerType="refunds" formMode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/hotel/:hotelID/debtor/account/refunds/:debtorID/:ledgerID/edit',
    },
    component: <DebtorRefundForm ledgerType="refunds" formMode="edit" />,
  },

  //..........PDF..........//

  {
    props: {
      exact: true,
      path: '/hotel/:hotelID/debtor/account/invoices/pdf/:debtorID/:ledgerID',
    },
    component: <GeneralDebtorLedgerPDF ledgerType="invoices" />,
  },
  {
    props: {
      exact: true,
      path:
        '/hotel/:hotelID/debtor/account/credit-notes/pdf/:debtorID/:ledgerID',
    },
    component: <GeneralDebtorLedgerPDF ledgerType="credit-notes" />,
  },
  {
    props: {
      exact: true,
      path:
        '/hotel/:hotelID/debtor/account/debit-notes/pdf/:debtorID/:ledgerID',
    },
    component: <GeneralDebtorLedgerPDF ledgerType="debit-notes" />,
  },
  {
    props: {
      exact: true,
      path: '/hotel/:hotelID/debtor/account/receipts/pdf/:debtorID/:ledgerID',
    },
    component: <GeneralDebtorLedgerPDF ledgerType="receipts" />,
  },
  {
    props: {
      exact: true,
      path: '/hotel/:hotelID/debtor/account/refunds/pdf/:debtorID/:ledgerID',
    },
    component: <GeneralDebtorLedgerPDF ledgerType="refunds" />,
  },
]

export default debtorAccountRoutes
