import React from 'react'
import { Redirect } from 'react-router-dom'
import authenticationRoutes from 'containers/AccountModule/AccountRoutes'
import homeRoutes from 'containers/HomeModule/HomeRoutes'
import debtorRoutes from 'containers/DebtorModule/DebtorRoutes'
import debtorAccountRoutes from 'containers/DebtorModule/DebtorAccountModule/DebtorAccountRoutes'
import generalSettingRoutes from 'containers/GeneralSettingModule/GeneralSettingRoutes'
import DebtorTypeSetupRoutes from 'containers/GeneralSettingModule/DebtorType/DebtorTypeRoutes'
import ScheduleListingRoutes from 'containers/ScheduleBillingModule/ScheduleBillingRoutes'
const StartRoute = [
  {
    props: { exact: true, path: '/debtor' },
    component: <Redirect to="/" />,
  },
]

const errorRoute = [
  {
    // props: { exact: true, path: '/' },
    component: <Redirect to="/authentication/404" />,
  },
]
// errorRoute must be last else gg
const routes = [
  ...debtorRoutes,
  ...debtorAccountRoutes,
  // ...homeRoutes,
  ...authenticationRoutes,
  ...generalSettingRoutes,
  ...ScheduleListingRoutes,
  ...DebtorTypeSetupRoutes,
  ...StartRoute,
  ...errorRoute,
]

export default routes
