import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** Description of my custom scalar type */
  MyCustomScalar: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type SalesChannelEntity = InterfaceBase & {
  __typename?: 'SalesChannelEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Department?: Maybe<DepartmentEntity>;
};


export type ReasonEntity = InterfaceBase & {
  __typename?: 'ReasonEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Department?: Maybe<DepartmentEntity>;
};

export type GalleryEntity = InterfaceBase & {
  __typename?: 'GalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  ImageURL?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
};

export type RoomTypeAmenitiesEntity = InterfaceBase & {
  __typename?: 'RoomTypeAmenitiesEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  Name: Scalars['String'];
  GalleryID: Scalars['String'];
  IsSelected: Scalars['Boolean'];
  Hotel?: Maybe<HotelEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type LocationEntity = InterfaceBase & {
  __typename?: 'LocationEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  Room?: Maybe<Array<RoomEntity>>;
};


export type LocationEntityRoomArgs = {
  RoomTypeID?: Maybe<Scalars['String']>;
};

export type RoomStatusEntity = InterfaceBase & {
  __typename?: 'RoomStatusEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Description: RoomStatus;
  Code?: Maybe<Scalars['String']>;
  VacantOrOccupied?: Maybe<Scalars['String']>;
  CleanOrDirty?: Maybe<Scalars['String']>;
  RGBColor?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
};

export enum RoomStatus {
  VacantClean = 'Vacant_Clean',
  VacantDirty = 'Vacant_Dirty',
  OccupiedClean = 'Occupied_Clean',
  OccupiedDirty = 'Occupied_Dirty',
  VacantInspection = 'Vacant_Inspection',
  VacantReady = 'Vacant_Ready',
  OutOfOrder = 'Out_of_Order',
  OutOfInventory = 'Out_of_Inventory'
}

export type BlockRoomLogEntity = InterfaceTrx & {
  __typename?: 'BlockRoomLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  BlockDate: Scalars['DateTime'];
  ReleaseDate: Scalars['DateTime'];
  BlockStatusID: Scalars['String'];
  Reason?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  GalleryID?: Maybe<Scalars['String']>;
  IsRelease?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
  Room?: Maybe<RoomEntity>;
  BlockStatus?: Maybe<RoomStatusEntity>;
  Gallery?: Maybe<GalleryEntity>;
  BlockRoomLogGallery?: Maybe<Array<BlockRoomLogGalleryEntity>>;
  ReleaseRoomStatus: Scalars['String'];
};

export type BlockRoomLogGalleryEntity = InterfaceBase & {
  __typename?: 'BlockRoomLogGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BlockRoomLogID: Scalars['String'];
  GalleryID: Scalars['String'];
  BlockRoomLog?: Maybe<BlockRoomLogEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type HouseKeepingLogEntity = InterfaceTrx & {
  __typename?: 'HouseKeepingLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  HotelDate: Scalars['DateTime'];
  Status: Scalars['String'];
  CleanedBy?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  Room?: Maybe<RoomEntity>;
};

export type RoomDiscrepancyLogEntity = InterfaceTrx & {
  __typename?: 'RoomDiscrepancyLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  RoomStatusID?: Maybe<Scalars['String']>;
  HouseKeepingStatusID?: Maybe<Scalars['String']>;
  HotelDate: Scalars['DateTime'];
  DiscrepancyReason: DiscrepancyReason;
  AttendedBy: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  IsSolved?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
  Room?: Maybe<RoomEntity>;
  RoomStatus?: Maybe<RoomStatusEntity>;
  HouseKeepingStatus?: Maybe<RoomStatusEntity>;
};

export enum DiscrepancyReason {
  Skip = 'Skip',
  Sleep = 'Sleep'
}

/** JsonType */
export type Address = {
  __typename?: 'Address';
  siteName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export enum AddressType {
  Primary = 'PRIMARY',
  Delivery = 'DELIVERY',
  Site = 'SITE'
}

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  name?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContactEntity = InterfaceBase & {
  __typename?: 'ContactEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  FullName?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportExpireDate?: Maybe<Scalars['DateTime']>;
  Nationality?: Maybe<Nationality>;
  Marital?: Maybe<Marital>;
  Gender?: Maybe<Gender>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<Address>;
  Designation?: Maybe<Scalars['String']>;
  ProfilePicture?: Maybe<Scalars['String']>;
  GeneralEmail?: Maybe<Scalars['String']>;
  ReservationEmail?: Maybe<Scalars['String']>;
  BusinessEmail?: Maybe<Scalars['String']>;
  InvoiceEmail?: Maybe<Scalars['String']>;
  OwnerEmail?: Maybe<Scalars['String']>;
  NotificationMobileNo?: Maybe<Scalars['String']>;
  WebUrl?: Maybe<Scalars['String']>;
};

export enum Nationality {
  Afghan = 'Afghan',
  Albanian = 'Albanian',
  Algerian = 'Algerian',
  American = 'American',
  Argentine = 'Argentine',
  Argentinian = 'Argentinian',
  Australian = 'Australian',
  Austrian = 'Austrian',
  Bangladeshi = 'Bangladeshi',
  Batswana = 'Batswana',
  Belgian = 'Belgian',
  Bolivian = 'Bolivian',
  Brazilian = 'Brazilian',
  British = 'British',
  Bulgarian = 'Bulgarian',
  Cambodian = 'Cambodian',
  Cameroonian = 'Cameroonian',
  Canadian = 'Canadian',
  Chilean = 'Chilean',
  Chinese = 'Chinese',
  Colombian = 'Colombian',
  CostaRican = 'Costa_Rican',
  Croatian = 'Croatian',
  Cuban = 'Cuban',
  Czech = 'Czech',
  Danish = 'Danish',
  Dominican = 'Dominican',
  Dutch = 'Dutch',
  Ecuadorian = 'Ecuadorian',
  Egyptian = 'Egyptian',
  Emirati = 'Emirati',
  English = 'English',
  Estonian = 'Estonian',
  Ethiopian = 'Ethiopian',
  Fijian = 'Fijian',
  Finnish = 'Finnish',
  French = 'French',
  German = 'German',
  Ghanaian = 'Ghanaian',
  Greek = 'Greek',
  Guatemalan = 'Guatemalan',
  Haitian = 'Haitian',
  Honduran = 'Honduran',
  Hungarian = 'Hungarian',
  Icelandic = 'Icelandic',
  Indian = 'Indian',
  Indonesian = 'Indonesian',
  Iranian = 'Iranian',
  Iraqi = 'Iraqi',
  Irish = 'Irish',
  Israeli = 'Israeli',
  Italian = 'Italian',
  Jamaican = 'Jamaican',
  Japanese = 'Japanese',
  Jordanian = 'Jordanian',
  Kenyan = 'Kenyan',
  Korean = 'Korean',
  Kuwaiti = 'Kuwaiti',
  Lao = 'Lao',
  Latvian = 'Latvian',
  Lebanese = 'Lebanese',
  Libyan = 'Libyan',
  Lithuanian = 'Lithuanian',
  Malagasy = 'Malagasy',
  Malaysian = 'Malaysian',
  Malian = 'Malian',
  Maltese = 'Maltese',
  Mexican = 'Mexican',
  Mongolian = 'Mongolian',
  Moroccan = 'Moroccan',
  Mozambican = 'Mozambican',
  Namibian = 'Namibian',
  Nepalese = 'Nepalese',
  NewZealand = 'New_Zealand',
  Nicaraguan = 'Nicaraguan',
  Nigerian = 'Nigerian',
  Norwegian = 'Norwegian',
  Pakistani = 'Pakistani',
  Panamanian = 'Panamanian',
  Paraguayan = 'Paraguayan',
  Peruvian = 'Peruvian',
  Philippine = 'Philippine',
  Polish = 'Polish',
  Portuguese = 'Portuguese',
  Romanian = 'Romanian',
  Russian = 'Russian',
  Salvadorian = 'Salvadorian',
  Saudi = 'Saudi',
  Scottish = 'Scottish',
  Senegalese = 'Senegalese',
  Serbian = 'Serbian',
  Singaporean = 'Singaporean',
  Slovak = 'Slovak',
  SouthAfrican = 'South_African',
  Spanish = 'Spanish',
  SriLankan = 'Sri_Lankan',
  Sudanese = 'Sudanese',
  Swedish = 'Swedish',
  Swiss = 'Swiss',
  Syrian = 'Syrian',
  Taiwanese = 'Taiwanese',
  Tajikistani = 'Tajikistani',
  Thai = 'Thai',
  Tongan = 'Tongan',
  Tunisian = 'Tunisian',
  Turkish = 'Turkish',
  Ukrainian = 'Ukrainian',
  Uruguayan = 'Uruguayan',
  Venezuelan = 'Venezuelan',
  Vietnamese = 'Vietnamese',
  Welsh = 'Welsh',
  Zambian = 'Zambian',
  Zimbabwean = 'Zimbabwean'
}

export enum Marital {
  Divorced = 'Divorced',
  Married = 'Married',
  Seperated = 'Seperated',
  Single = 'Single',
  Widowed = 'Widowed'
}

export enum Gender {
  Male = 'Male',
  Female = 'Female'
}

export enum Race {
  Malay = 'Malay',
  Chinese = 'Chinese',
  Indian = 'Indian',
  Others = 'Others'
}

export enum Religion {
  Islam = 'Islam',
  Buddhism = 'Buddhism',
  Christianity = 'Christianity',
  Hinduism = 'Hinduism',
  Taoism = 'Taoism',
  Others = 'Others'
}

export type TestCountry = {
  __typename?: 'TestCountry';
  con: Country;
};

export enum Country {
  Algeria = 'Algeria',
  AmericanSamoa = 'American_Samoa',
  Andorra = 'Andorra',
  Angola = 'Angola',
  Anguilla = 'Anguilla',
  AntiguaAndBarbuda = 'Antigua_and_Barbuda',
  Argentina = 'Argentina',
  Armenia = 'Armenia',
  Aruba = 'Aruba',
  Australia = 'Australia',
  Austria = 'Austria',
  Azerbaijan = 'Azerbaijan',
  Bahamas = 'Bahamas',
  Bahrain = 'Bahrain',
  Bangladesh = 'Bangladesh',
  Barbados = 'Barbados',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  Belize = 'Belize',
  Benin = 'Benin',
  Bermuda = 'Bermuda',
  Bolivia = 'Bolivia',
  BosniaHerzegovina = 'Bosnia_Herzegovina',
  Brazil = 'Brazil',
  BritishVirginIslands = 'British_Virgin_Islands',
  Brunei = 'Brunei',
  Bulgaria = 'Bulgaria',
  BurkinaFaso = 'Burkina_Faso',
  Burundi = 'Burundi',
  Cambodia = 'Cambodia',
  Cameroon = 'Cameroon',
  Canada = 'Canada',
  CapeVerde = 'Cape_Verde',
  CaymanIslands = 'Cayman_Islands',
  CentralAfricanRepublic = 'Central_African_Republic',
  Chad = 'Chad',
  Chile = 'Chile',
  China = 'China',
  Colombia = 'Colombia',
  Comoros = 'Comoros',
  Congo = 'Congo',
  CookIslands = 'Cook_Islands',
  CostaRica = 'Costa_Rica',
  CoteDIvoire = 'Cote_d_Ivoire',
  Croatia = 'Croatia',
  Cuba = 'Cuba',
  Cyprus = 'Cyprus',
  CzechRepublic = 'Czech_Republic',
  Djibouti = 'Djibouti',
  DominicaCommonwealth = 'Dominica_Commonwealth',
  DominicanRepublic = 'Dominican_Republic',
  Ecuador = 'Ecuador',
  ElSalvador = 'El_Salvador',
  Eritrea = 'Eritrea',
  Estonia = 'Estonia',
  Ethiopia = 'Ethiopia',
  FaeroeIsland = 'Faeroe_Island',
  FalklandIslands = 'Falkland_Islands',
  Fiji = 'Fiji',
  Finland = 'Finland',
  France = 'France',
  FrenchGuiana = 'French_Guiana',
  FrenchPolynesia = 'French_Polynesia',
  Gabon = 'Gabon',
  Gambia = 'Gambia',
  Georgia = 'Georgia',
  Germany = 'Germany',
  Ghana = 'Ghana',
  Gibraltar = 'Gibraltar',
  Greece = 'Greece',
  Greenland = 'Greenland',
  Grenada = 'Grenada',
  Guadaloupe = 'Guadaloupe',
  Guam = 'Guam',
  Guatemala = 'Guatemala',
  Guinea = 'Guinea',
  GuineaBissau = 'Guinea_Bissau',
  Guyana = 'Guyana',
  Haiti = 'Haiti',
  Honduras = 'Honduras',
  HongKong = 'HongKong',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  India = 'India',
  Indonesia = 'Indonesia',
  Iran = 'Iran',
  Iraq = 'Iraq',
  Ireland = 'Ireland',
  Israel = 'Israel',
  Italy = 'Italy',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Jordan = 'Jordan',
  Kazakhstan = 'Kazakhstan',
  Kenya = 'Kenya',
  Kiribati = 'Kiribati',
  NorthKorea = 'North_Korea',
  SouthKorea = 'South_Korea',
  Kuwait = 'Kuwait',
  Kyrgyzstan = 'Kyrgyzstan',
  Laos = 'Laos',
  Latvia = 'Latvia',
  Lebanon = 'Lebanon',
  Lesotho = 'Lesotho',
  Liberia = 'Liberia',
  Libya = 'Libya',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Macau = 'Macau',
  Macedonia = 'Macedonia',
  Madagascar = 'Madagascar',
  Malawi = 'Malawi',
  Malaysia = 'Malaysia',
  Maldives = 'Maldives',
  Mali = 'Mali',
  Malta = 'Malta',
  MarshallIslands = 'Marshall_Islands',
  Martinique = 'Martinique',
  Mauritania = 'Mauritania',
  Mauritius = 'Mauritius',
  Mayotte = 'Mayotte',
  Mexico = 'Mexico',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Mongolia = 'Mongolia',
  Montserrat = 'Montserrat',
  Morocco = 'Morocco',
  Mozambique = 'Mozambique',
  Myanmar = 'Myanmar',
  Namibia = 'Namibia',
  Nauru = 'Nauru',
  Nepal = 'Nepal',
  Netherlands = 'Netherlands',
  NetherlandsAntilles = 'Netherlands_Antilles',
  NewCaledonia = 'New_Caledonia',
  NewZealand = 'New_Zealand',
  Nicaragua = 'Nicaragua',
  Niger = 'Niger',
  Nigeria = 'Nigeria',
  NiueIsland = 'Niue_Island',
  NorfolkIsland = 'Norfolk_Island',
  NorthernMarianaIslands = 'Northern_Mariana_Islands',
  Norway = 'Norway',
  Oman = 'Oman',
  Pakistan = 'Pakistan',
  Palau = 'Palau',
  Panama = 'Panama',
  PapuaNewGuinea = 'Papua_New_Guinea',
  Paraguay = 'Paraguay',
  Peru = 'Peru',
  Philippines = 'Philippines',
  Poland = 'Poland',
  Portugal = 'Portugal',
  PuertoRico = 'Puerto_Rico',
  Qatar = 'Qatar',
  Reunion = 'Reunion',
  Romania = 'Romania',
  Russia = 'Russia',
  Rwanda = 'Rwanda',
  Samoa = 'Samoa',
  SanMarino = 'San_Marino',
  SaoTomeAndPrincipe = 'Sao_Tome_and_Principe',
  SaudiArabia = 'Saudi_Arabia',
  Senegal = 'Senegal',
  Seychelles = 'Seychelles',
  SierraLeone = 'Sierra_Leone',
  Singapore = 'Singapore',
  Slovenia = 'Slovenia',
  SolomonIslands = 'Solomon_Islands',
  Somalia = 'Somalia',
  SouthAfrica = 'South_Africa',
  Spain = 'Spain',
  SriLanka = 'Sri_Lanka',
  StHelena = 'St_Helena',
  StKittsAndNevis = 'St_Kitts_and_Nevis',
  StLucia = 'St_Lucia',
  StPierreAndMiquelon = 'St_Pierre_and_Miquelon',
  StVincentAndTheGrenadines = 'St_Vincent_and_the_Grenadines',
  Sudan = 'Sudan',
  Suriname = 'Suriname',
  Swaziland = 'Swaziland',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  Syria = 'Syria',
  Taiwan = 'Taiwan',
  Tajikistan = 'Tajikistan',
  Tanzania = 'Tanzania',
  Thailand = 'Thailand',
  Togo = 'Togo',
  Tokelau = 'Tokelau',
  Tonga = 'Tonga',
  TrinidadAndTobago = 'Trinidad_and_Tobago',
  Tunisia = 'Tunisia',
  Turkey = 'Turkey',
  Turkmenistan = 'Turkmenistan',
  TurksAndCaicosIslands = 'Turks_and_Caicos_Islands',
  Tuvalu = 'Tuvalu',
  UsVirginIslands = 'US_Virgin_Islands',
  Uganda = 'Uganda',
  Ukraine = 'Ukraine',
  UnitedArabEmirates = 'United_Arab_Emirates',
  UnitedKingdom = 'United_Kingdom',
  UnitedStatesOfAmerica = 'United_States_of_America',
  Uruguay = 'Uruguay',
  Uzbekistan = 'Uzbekistan',
  Vanuatu = 'Vanuatu',
  Venezuela = 'Venezuela',
  Vietnam = 'Vietnam',
  WallisAndFutuna = 'Wallis_and_Futuna',
  Yemen = 'Yemen',
  Zambia = 'Zambia',
  Zimbabwe = 'Zimbabwe'
}

export type SegmentEntity = InterfaceBase & {
  __typename?: 'SegmentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
};

export type DebtorTypeEntity = InterfaceBase & {
  __typename?: 'DebtorTypeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorType: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  ControlAccount?: Maybe<Scalars['String']>;
  DepositAccount?: Maybe<Scalars['String']>;
  AdvanceAccount?: Maybe<Scalars['String']>;
  AccrualAccount?: Maybe<Scalars['String']>;
  RevenueAccount?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
};

export type ContractRateEntity = InterfaceBase & {
  __typename?: 'ContractRateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContractID: Scalars['String'];
  RatePolicyID: Scalars['String'];
  Contract?: Maybe<ContractEntity>;
  RatePolicy?: Maybe<RatePolicyEntity>;
};

export type ContractAllotmentEntity = InterfaceBase & {
  __typename?: 'ContractAllotmentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContractID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  AllotDate: Scalars['DateTime'];
  InitialAllot?: Maybe<Scalars['Float']>;
  Adjust?: Maybe<Scalars['Float']>;
  SysAdjust?: Maybe<Scalars['Float']>;
  PickUp?: Maybe<Scalars['Float']>;
  SysRelease?: Maybe<Scalars['Float']>;
  Contract?: Maybe<ContractEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
};

export type ContractEntity = InterfaceBase & {
  __typename?: 'ContractEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  ContractNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  RoomUpgrade?: Maybe<Scalars['Boolean']>;
  Elastic?: Maybe<Scalars['Boolean']>;
  Allotment?: Maybe<Scalars['Boolean']>;
  CutOffDays?: Maybe<Scalars['Float']>;
  PaymentTerms?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  Debtor?: Maybe<DebtorEntity>;
  ContractRate?: Maybe<Array<ContractRateEntity>>;
  ContractAllotment?: Maybe<Array<ContractAllotmentEntity>>;
};

export type DebtorContactEntity = InterfaceBase & {
  __typename?: 'DebtorContactEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  ContactID: Scalars['String'];
  SalesChannelID: Scalars['String'];
  Debtor?: Maybe<DebtorEntity>;
  Contact?: Maybe<ContactEntity>;
};

export type DebtorProfileEntity = InterfaceBase & {
  __typename?: 'DebtorProfileEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContactID: Scalars['String'];
  Email?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  Debtor?: Maybe<Array<DebtorEntity>>;
  Contact?: Maybe<ContactEntity>;
  accessToken?: Maybe<Scalars['String']>;
};

export type DebtorEntity = InterfaceBase & {
  __typename?: 'DebtorEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ContactID: Scalars['String'];
  DebtorProfileID?: Maybe<Scalars['String']>;
  DebtorTypeID?: Maybe<Scalars['String']>;
  DebtorCategory?: Maybe<DebtorCategory>;
  Name: Scalars['String'];
  DebtorAccount?: Maybe<Scalars['String']>;
  DebtorAccountRemark?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CreditTerm?: Maybe<Scalars['Float']>;
  CreditLimit?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  SalesChannel?: Maybe<Scalars['String']>;
  DebtorContacts?: Maybe<Scalars['String']>;
  Segment?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  Contact?: Maybe<ContactEntity>;
  DebtorType?: Maybe<DebtorTypeEntity>;
  SegmentDetails?: Maybe<SegmentEntity>;
  Contract?: Maybe<Array<ContractEntity>>;
  Booking?: Maybe<Array<BookingEntity>>;
  DebtorContact?: Maybe<Array<DebtorContactEntity>>;
  DebtorProfile?: Maybe<DebtorProfileEntity>;
  accessToken?: Maybe<Scalars['String']>;
  ARTotalOutstanding: Scalars['Float'];
  ARTotalDue: Scalars['Float'];
};

export enum DebtorCategory {
  Corporate = 'Corporate',
  TravelAgent = 'TravelAgent',
  Government = 'Government'
}

export enum Currency {
  Afn = 'AFN',
  All = 'ALL',
  Dzd = 'DZD',
  Aoa = 'AOA',
  Ars = 'ARS',
  Amd = 'AMD',
  Awg = 'AWG',
  Aud = 'AUD',
  Azn = 'AZN',
  Bsd = 'BSD',
  Bhd = 'BHD',
  Bdt = 'BDT',
  Bbd = 'BBD',
  Byn = 'BYN',
  Bzd = 'BZD',
  Bmd = 'BMD',
  Btn = 'BTN',
  Bob = 'BOB',
  Bam = 'BAM',
  Bwp = 'BWP',
  Brl = 'BRL',
  Gbp = 'GBP',
  Bnd = 'BND',
  Bgn = 'BGN',
  Mmk = 'MMK',
  Bif = 'BIF',
  Khr = 'KHR',
  Cad = 'CAD',
  Cve = 'CVE',
  Kyd = 'KYD',
  Xaf = 'XAF',
  Xpf = 'XPF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Kmf = 'KMF',
  Cdf = 'CDF',
  Crc = 'CRC',
  Hrk = 'HRK',
  Cuc = 'CUC',
  Cup = 'CUP',
  Czk = 'CZK',
  Dkk = 'DKK',
  Djf = 'DJF',
  Dop = 'DOP',
  Xcd = 'XCD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fkp = 'FKP',
  Fjd = 'FJD',
  Gmd = 'GMD',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gtq = 'GTQ',
  Gnf = 'GNF',
  Gyd = 'GYD',
  Htg = 'HTG',
  Hnl = 'HNL',
  Hkd = 'HKD',
  Huf = 'HUF',
  Isk = 'ISK',
  Inr = 'INR',
  Idr = 'IDR',
  Irr = 'IRR',
  Iqd = 'IQD',
  Ils = 'ILS',
  Jmd = 'JMD',
  Jpy = 'JPY',
  Jod = 'JOD',
  Kzt = 'KZT',
  Kes = 'KES',
  Kwd = 'KWD',
  Kgs = 'KGS',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lsl = 'LSL',
  Lrd = 'LRD',
  Lyd = 'LYD',
  Mop = 'MOP',
  Mkd = 'MKD',
  Mga = 'MGA',
  Mwk = 'MWK',
  Myr = 'MYR',
  Mvr = 'MVR',
  Mru = 'MRU',
  Mur = 'MUR',
  Mxn = 'MXN',
  Mdl = 'MDL',
  Mnt = 'MNT',
  Mad = 'MAD',
  Mzn = 'MZN',
  Nad = 'NAD',
  Npr = 'NPR',
  Ang = 'ANG',
  Twd = 'TWD',
  Nzd = 'NZD',
  Nio = 'NIO',
  Ngn = 'NGN',
  Kpw = 'KPW',
  Nok = 'NOK',
  Omr = 'OMR',
  Pkr = 'PKR',
  Pab = 'PAB',
  Pgk = 'PGK',
  Pyg = 'PYG',
  Pen = 'PEN',
  Php = 'PHP',
  Pln = 'PLN',
  Qar = 'QAR',
  Ron = 'RON',
  Rub = 'RUB',
  Rwf = 'RWF',
  Shp = 'SHP',
  Wst = 'WST',
  Stn = 'STN',
  Sar = 'SAR',
  Rsd = 'RSD',
  Scr = 'SCR',
  Sll = 'SLL',
  Sgd = 'SGD',
  Sbd = 'SBD',
  Sos = 'SOS',
  Zar = 'ZAR',
  Krw = 'KRW',
  Ssp = 'SSP',
  Lkr = 'LKR',
  Sdg = 'SDG',
  Srd = 'SRD',
  Szl = 'SZL',
  Sek = 'SEK',
  Chf = 'CHF',
  Syp = 'SYP',
  Tjs = 'TJS',
  Tzs = 'TZS',
  Thb = 'THB',
  Top = 'TOP',
  Ttd = 'TTD',
  Tnd = 'TND',
  Try = 'TRY',
  Tmt = 'TMT',
  Ugx = 'UGX',
  Uah = 'UAH',
  Aed = 'AED',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vuv = 'VUV',
  Ves = 'VES',
  Vnd = 'VND',
  Xof = 'XOF',
  Yer = 'YER',
  Zmw = 'ZMW'
}

export type AdvancePaymentEntity = InterfaceTrx & {
  __typename?: 'AdvancePaymentEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID?: Maybe<Scalars['String']>;
  ReversedAdvancePaymentID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  PaymentDate: Scalars['DateTime'];
  PaymentClass?: Maybe<PaymentClass>;
  PaymentType?: Maybe<PaymentType>;
  DebtorAccount?: Maybe<Scalars['String']>;
  PaySessionNo?: Maybe<Scalars['Float']>;
  CheckOutID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  PaymentStatus?: Maybe<PaymentStatus>;
  IsPosted: Scalars['Boolean'];
  Hotel?: Maybe<HotelEntity>;
  Registration?: Maybe<RegistrationEntity>;
  booking?: Maybe<BookingEntity>;
};

export enum PaymentClass {
  Advance = 'Advance',
  Room = 'Room',
  Bill = 'Bill',
  Tax = 'Tax',
  Folio = 'Folio',
  Refund = 'Refund',
  Forfeit = 'Forfeit'
}

export enum PaymentType {
  Online = 'Online',
  Cash = 'Cash',
  VisaCard = 'VisaCard',
  MasterCard = 'MasterCard',
  AmericanExpressCard = 'AmericanExpressCard',
  DinersCard = 'DinersCard',
  JcbCard = 'JCBCard',
  Unionpay = 'UNIONPAY',
  CityLedger = 'CityLedger'
}

export enum PaymentStatus {
  Pending = 'Pending',
  Paid = 'Paid',
  Cancelled = 'Cancelled',
  Rejected = 'Rejected'
}

export type BookingEntity = InterfaceTrx & {
  __typename?: 'BookingEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingNo: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  DepartureDate: Scalars['DateTime'];
  ContactID: Scalars['String'];
  GroupName?: Maybe<Scalars['String']>;
  SalesChannelID?: Maybe<Scalars['String']>;
  Source?: Maybe<Source>;
  SegmentID?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  BillingInstruction?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  BookingStatus: BookingStatus;
  Hotel?: Maybe<HotelEntity>;
  Contact?: Maybe<ContactEntity>;
  SalesChannel?: Maybe<SalesChannelEntity>;
  Segment?: Maybe<SegmentEntity>;
  Registration?: Maybe<Array<RegistrationEntity>>;
  AllRegistration?: Maybe<Array<RegistrationEntity>>;
  BookedRegistration?: Maybe<Array<RegistrationEntity>>;
  AdvancePayment?: Maybe<Array<AdvancePaymentEntity>>;
  Debtor?: Maybe<DebtorEntity>;
  TotalAdvPayment: Scalars['Float'];
  TotalRoomRate: Scalars['Float'];
};

export enum Source {
  WalkIn = 'Walk_In',
  Online = 'Online',
  Booking = 'Booking',
  Corporate = 'Corporate',
  Government = 'Government',
  TravelAgent = 'Travel_Agent',
  ChannelManager = 'Channel_Manager',
  Alliance = 'Alliance'
}

export enum BookingStatus {
  Booked = 'Booked',
  Cancelled = 'Cancelled',
  NoShow = 'NoShow',
  Inhouse = 'Inhouse',
  CheckOut = 'CheckOut',
  Pending = 'Pending'
}

export type GuestProfileEntity = InterfaceBase & {
  __typename?: 'GuestProfileEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContactID: Scalars['String'];
  Company?: Maybe<Scalars['String']>;
  IsVIP?: Maybe<Scalars['Boolean']>;
  Login?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  VehicleNo?: Maybe<Scalars['String']>;
  IdPicture?: Maybe<Scalars['String']>;
  PassportPicture?: Maybe<Scalars['String']>;
  NewsLetter?: Maybe<Scalars['Boolean']>;
  ICGallery?: Maybe<GalleryEntity>;
  PassportGallery?: Maybe<GalleryEntity>;
  Contact?: Maybe<ContactEntity>;
  accessToken?: Maybe<Scalars['String']>;
  GuestHistoryList: Array<RegistrationEntity>;
  ServiceRequestCount: Scalars['Float'];
};

export type BillScheduleEntity = InterfaceTrx & {
  __typename?: 'BillScheduleEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RegistrationID: Scalars['String'];
  ReversedBillScheduleID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  ChargeType: ChargeType;
  TransactionType?: Maybe<TransactionType>;
  HotelID?: Maybe<Scalars['String']>;
  TransactionID?: Maybe<Scalars['String']>;
  AdvancePaymentID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  IsPosted: Scalars['Boolean'];
  SplitGroup?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Transaction?: Maybe<IncidentalChargeEntity>;
};

export enum ChargeType {
  Booking = 'Booking',
  Package = 'Package',
  ExtendStay = 'ExtendStay',
  ExtendStayPkg = 'ExtendStayPkg',
  LateCheckOut = 'LateCheckOut',
  RoomUpgrade = 'RoomUpgrade',
  Taxes = 'Taxes'
}

export enum TransactionType {
  Rate = 'Rate',
  Package = 'Package'
}

export type RoomLedgerEntity = InterfaceTrx & {
  __typename?: 'RoomLedgerEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  ReversedRoomLedgerID?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Reason: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  ChargeType: ChargeType;
  TransactionType: TransactionType;
  TransactionID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  SplitGroup?: Maybe<Scalars['Float']>;
  Folio?: Maybe<FolioEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Transaction?: Maybe<IncidentalChargeEntity>;
};

export type TaxLedgerEntity = InterfaceTrx & {
  __typename?: 'TaxLedgerEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  ReversedTaxLedgerID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  TaxType: TaxType;
  TrxDate: Scalars['DateTime'];
  TaxAmount?: Maybe<Scalars['Float']>;
  Folio?: Maybe<FolioEntity>;
  Registration?: Maybe<RegistrationEntity>;
};

export enum TaxType {
  HeritageTax = 'HeritageTax',
  TourismTax = 'TourismTax',
  Levy = 'Levy',
  GovTax = 'GovTax',
  ServiceCharge = 'ServiceCharge'
}

export type DepositLedgerEntity = InterfaceTrx & {
  __typename?: 'DepositLedgerEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID: Scalars['String'];
  RegistrationID: Scalars['String'];
  ReversedID?: Maybe<Scalars['String']>;
  DepositClass: DepositClass;
  PaymentType: PaymentType;
  TrxDate: Scalars['DateTime'];
  DepositAmount?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  Folio?: Maybe<FolioEntity>;
  Registration?: Maybe<RegistrationEntity>;
};

export enum DepositClass {
  SecurityDeposit = 'SecurityDeposit',
  KeyCardDeposit = 'KeyCardDeposit',
  Refund = 'Refund',
  Forfeiture = 'Forfeiture',
  Reversed = 'Reversed'
}

export type BillLedgerEntity = InterfaceTrx & {
  __typename?: 'BillLedgerEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  ReversedBillLedgerID?: Maybe<Scalars['String']>;
  IncidentalChargeID: Scalars['String'];
  Reason: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  Qty?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  IsDelivered?: Maybe<Scalars['Boolean']>;
  Signature?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  IsChargeToRoom?: Maybe<Scalars['Boolean']>;
  Folio?: Maybe<FolioEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Hotel?: Maybe<HotelEntity>;
  IncidentalCharge?: Maybe<IncidentalChargeEntity>;
  BillLedgerSignature?: Maybe<GalleryEntity>;
  PrincipalRegistration: RegistrationEntity;
};

export type OnlineItemEntity = InterfaceTrx & {
  __typename?: 'OnlineItemEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
};

export type FolioEntity = InterfaceTrx & {
  __typename?: 'FolioEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  FolioType: FolioType;
  RefID: Scalars['String'];
  FolioNo: Scalars['String'];
  FolioDate: Scalars['DateTime'];
  FolioAmount?: Maybe<Scalars['Float']>;
  ClosedDate: Scalars['DateTime'];
  ClosedBy: Scalars['String'];
  Hotel?: Maybe<HotelEntity>;
  RoomLedger?: Maybe<Array<RoomLedgerEntity>>;
  TaxLedger?: Maybe<Array<TaxLedgerEntity>>;
  DepositLedger?: Maybe<Array<DepositLedgerEntity>>;
  BillLedger?: Maybe<Array<BillLedgerEntity>>;
  Payment?: Maybe<Array<PaymentEntity>>;
};

export enum FolioType {
  GuestFolio = 'GuestFolio',
  GroupFolio = 'GroupFolio',
  NonGuest = 'NonGuest',
  DepositFolio = 'DepositFolio',
  OpenFolio = 'OpenFolio',
  RefundForfeiture = 'RefundForfeiture'
}

export type PaymentEntity = InterfaceTrx & {
  __typename?: 'PaymentEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  ReceiptNo?: Maybe<Scalars['String']>;
  PaymentDate: Scalars['DateTime'];
  PaymentClass?: Maybe<PaymentClass>;
  PaymentType: PaymentType;
  DebtorAccount?: Maybe<Scalars['String']>;
  PaySessionNo: Scalars['Float'];
  CheckOutID?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  PaymentStatus: PaymentStatus;
  Hotel?: Maybe<HotelEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Folio?: Maybe<FolioEntity>;
};

export type RegistrationEntity = InterfaceTrx & {
  __typename?: 'RegistrationEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  BookingID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  PromoCode?: Maybe<Scalars['String']>;
  RoomRate?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  SharerNo?: Maybe<Scalars['Float']>;
  NoOfAdult?: Maybe<Scalars['Float']>;
  NoOfChild?: Maybe<Scalars['Float']>;
  NoOfInfant?: Maybe<Scalars['Float']>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  Signature?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  RegistrationStatus?: Maybe<BookingStatus>;
  Booking?: Maybe<BookingEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  RatePolicy?: Maybe<RatePolicyEntity>;
  Room?: Maybe<RoomEntity>;
  Guest?: Maybe<GuestProfileEntity>;
  BillSchedule?: Maybe<Array<BillScheduleEntity>>;
  Payment?: Maybe<Array<PaymentEntity>>;
  AdvancePayment?: Maybe<Array<AdvancePaymentEntity>>;
  RoomLedger?: Maybe<Array<RoomLedgerEntity>>;
  TaxLedger?: Maybe<Array<TaxLedgerEntity>>;
  DepositLedger?: Maybe<Array<DepositLedgerEntity>>;
  BillLedger?: Maybe<Array<BillLedgerEntity>>;
  RegistrationSignature?: Maybe<GalleryEntity>;
  DepositSum: Scalars['Float'];
  PaymentSum: Scalars['Float'];
  IncidentalChargesSum: Scalars['Float'];
  IncidentalChargesCount: Scalars['Float'];
  TotalRoomPrice: Scalars['Float'];
  TotalPackagePrice: Scalars['Float'];
  TotalPrice: Scalars['Float'];
};

export type RoomEntity = InterfaceBase & {
  __typename?: 'RoomEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  LocationID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  RoomNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  RoomSize?: Maybe<Scalars['Float']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  Location?: Maybe<LocationEntity>;
  BlockRoomLog?: Maybe<Array<BlockRoomLogEntity>>;
  HouseKeepingLog?: Maybe<Array<HouseKeepingLogEntity>>;
  RoomDiscrepancyLog?: Maybe<Array<RoomDiscrepancyLogEntity>>;
  PrincipalRegistration?: Maybe<Array<RegistrationEntity>>;
  Registrations?: Maybe<Array<RegistrationEntity>>;
  RoomStatus?: Maybe<RoomStatusEntity>;
  RoomAssignmentStatus?: Maybe<RoomStatusEntity>;
  ServiceRequestCount: Scalars['Float'];
  RoomStatusCode: Scalars['String'];
};


export type RoomEntityRoomAssignmentStatusArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};

export type AvailabilityEntity = InterfaceTrx & {
  __typename?: 'AvailabilityEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  BookedRoom?: Maybe<Scalars['Float']>;
  BlockedRoom?: Maybe<Scalars['Float']>;
  AllottedRoom?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
};

export type PricingOutput = {
  __typename?: 'PricingOutput';
  Date?: Maybe<Scalars['DateTime']>;
  Price?: Maybe<Scalars['Float']>;
  BasePrice?: Maybe<Scalars['Float']>;
  PackageName?: Maybe<Scalars['Float']>;
  PackagePrice?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  Packages?: Maybe<Array<PackagesEntity>>;
};

export type PackagePriceOutput = {
  __typename?: 'PackagePriceOutput';
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomTypeCode?: Maybe<Scalars['String']>;
  RoomTypeName?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  TotalVacant?: Maybe<Scalars['Float']>;
  Pricing?: Maybe<Array<PricingOutput>>;
};

export type AvailabilityOutput = {
  __typename?: 'AvailabilityOutput';
  ID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  BookedRoom?: Maybe<Scalars['Float']>;
  BlockedRoom?: Maybe<Scalars['Float']>;
  AllottedRoom?: Maybe<Scalars['Float']>;
  TotalOccupied?: Maybe<Scalars['Float']>;
  TotalVacant?: Maybe<Scalars['Float']>;
  SeasonType: SeasonType;
  Pricing?: Maybe<Array<PricingEntity>>;
};

export enum SeasonType {
  Peak = 'Peak',
  SuperPeak = 'SuperPeak',
  NonPeak = 'NonPeak'
}

export type Occupancy = {
  __typename?: 'Occupancy';
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  Season: SeasonType;
  Occupancy?: Maybe<Scalars['Float']>;
  TotalOccupied?: Maybe<Scalars['Float']>;
  TotalRoom?: Maybe<Scalars['Float']>;
};

export type RoomTypesListOutput = {
  __typename?: 'RoomTypesListOutput';
  ID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  RoomTypeAvailability?: Maybe<Array<AvailabilityOutput>>;
};

export type HotelAvailabilityOutput = {
  __typename?: 'HotelAvailabilityOutput';
  RoomTypesList?: Maybe<Array<RoomTypesListOutput>>;
  Occupancy?: Maybe<Array<Occupancy>>;
};

export type TotalPackagesResponse = {
  __typename?: 'TotalPackagesResponse';
  PackageName?: Maybe<Scalars['String']>;
  Price?: Maybe<Scalars['Float']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  PriceID?: Maybe<Scalars['String']>;
};

export type RoomTypeEntity = InterfaceBase & {
  __typename?: 'RoomTypeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  PropertyType?: Maybe<PropertyType>;
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  RoomSize?: Maybe<Scalars['Float']>;
  MeasureType?: Maybe<MeasureType>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  RoomTypeAmenities?: Maybe<Array<RoomTypeAmenitiesEntity>>;
  RoomTypeGallery?: Maybe<Array<RoomTypeGalleryEntity>>;
  Room?: Maybe<Array<RoomEntity>>;
  Availability?: Maybe<Array<AvailabilityEntity>>;
  Price?: Maybe<Array<PricingEntity>>;
  ContractAllotment?: Maybe<Array<ContractAllotmentEntity>>;
  RoomTypeAvailability?: Maybe<Array<AvailabilityOutput>>;
  TotalPackages?: Maybe<Array<TotalPackagesResponse>>;
};

export enum PropertyType {
  Apartment = 'Apartment',
  Bungalow = 'Bungalow',
  Cabin = 'Cabin',
  Campsite = 'Campsite',
  Cottage = 'Cottage',
  Dorm = 'Dorm',
  Room = 'Room',
  Villa = 'Villa'
}

export enum MeasureType {
  SquareMetre = 'Square_Metre',
  FootSquare = 'Foot_Square'
}

export type RoomTypeGalleryEntity = InterfaceBase & {
  __typename?: 'RoomTypeGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RoomTypeID: Scalars['String'];
  GalleryID: Scalars['String'];
  RoomType?: Maybe<RoomTypeEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type PricingEntity = InterfaceBase & {
  __typename?: 'PricingEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RatePolicyID: Scalars['String'];
  RoomTypeID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Standard?: Maybe<Scalars['Float']>;
  Ceiling?: Maybe<Scalars['Float']>;
  Floor?: Maybe<Scalars['Float']>;
  PeakStandard?: Maybe<Scalars['Float']>;
  PeakCeiling?: Maybe<Scalars['Float']>;
  PeakFloor?: Maybe<Scalars['Float']>;
  SuperPeakStandard?: Maybe<Scalars['Float']>;
  SuperPeakCeiling?: Maybe<Scalars['Float']>;
  SuperPeakFloor?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  RatePolicy?: Maybe<RatePolicyEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  PackageAmount?: Maybe<Scalars['Float']>;
  SellingPrice?: Maybe<Scalars['Float']>;
  Season?: Maybe<Scalars['String']>;
};

export type RatePolicyEntity = InterfaceBase & {
  __typename?: 'RatePolicyEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Category?: Maybe<RateCategory>;
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  PublishOnline?: Maybe<Scalars['Boolean']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Hotel?: Maybe<HotelEntity>;
  Package?: Maybe<Array<PackagesEntity>>;
  Pricing?: Maybe<Array<PricingEntity>>;
};

export enum RateCategory {
  Corporate = 'Corporate',
  Government = 'Government',
  TravelAgent = 'TravelAgent',
  Public = 'Public',
  DayUse = 'DayUse',
  HouseUse = 'HouseUse',
  Complimentary = 'Complimentary'
}

export type PackagesEntity = InterfaceBase & {
  __typename?: 'PackagesEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RatePolicyID: Scalars['String'];
  IncidentalChargeID?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  GalleryID?: Maybe<Scalars['String']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Boolean']>;
  ServiceCharge?: Maybe<Scalars['Boolean']>;
  ComputationRules?: Maybe<ComputationRules>;
  IncludeRate?: Maybe<Scalars['Boolean']>;
  RatePolicy?: Maybe<RatePolicyEntity>;
  IncidentalCharge?: Maybe<IncidentalChargeEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export enum ComputationRules {
  FlatRate = 'FlatRate',
  PerPerson = 'PerPerson'
}

export type IncidentalChargeEntity = InterfaceBase & {
  __typename?: 'IncidentalChargeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Boolean']>;
  ServiceCharge?: Maybe<Scalars['Boolean']>;
  IsRoomRevenue?: Maybe<Scalars['Boolean']>;
  IsFnB?: Maybe<Scalars['Boolean']>;
  IsEShop?: Maybe<Scalars['Boolean']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  GalleryID?: Maybe<Scalars['String']>;
  Department?: Maybe<DepartmentEntity>;
  Gallery?: Maybe<GalleryEntity>;
  Package?: Maybe<Array<PackagesEntity>>;
};

export type DepartmentEntity = InterfaceBase & {
  __typename?: 'DepartmentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AllowReason: Scalars['Boolean'];
  AllowSalesChannel: Scalars['Boolean'];
  AllowIncidental: Scalars['Boolean'];
  AllowGuestApp?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
  SalesChannel?: Maybe<Array<SalesChannelEntity>>;
  Reason?: Maybe<Array<ReasonEntity>>;
  IncidentalCharge?: Maybe<Array<IncidentalChargeEntity>>;
  ServiceRequest?: Maybe<Array<ServiceRequestEntity>>;
};

export type ServiceRequestEntity = InterfaceTrx & {
  __typename?: 'ServiceRequestEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DepartmentID: Scalars['String'];
  RoomID: Scalars['String'];
  RequestBy: Scalars['String'];
  Title: Scalars['String'];
  Description: Scalars['String'];
  EstimatedCompletion: Scalars['DateTime'];
  GalleryID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  Status: ServiceRequestStatus;
  GuestID?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  Department?: Maybe<DepartmentEntity>;
  Room?: Maybe<RoomEntity>;
  Gallery?: Maybe<GalleryEntity>;
  GuestProfile?: Maybe<GuestProfileEntity>;
  ServiceRequestGallery?: Maybe<Array<ServiceRequestGalleryEntity>>;
};

export enum ServiceRequestStatus {
  Open = 'Open',
  InProgress = 'InProgress',
  Completed = 'Completed'
}

export type ServiceRequestGalleryEntity = InterfaceBase & {
  __typename?: 'ServiceRequestGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ServiceRequestID: Scalars['String'];
  GalleryID: Scalars['String'];
  ServiceRequest?: Maybe<ServiceRequestEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type ArSpecification = {
  __typename?: 'ARSpecification';
  Age1?: Maybe<Scalars['Float']>;
  Age2?: Maybe<Scalars['Float']>;
  Age3?: Maybe<Scalars['Float']>;
  Age4?: Maybe<Scalars['Float']>;
  Reminder1?: Maybe<Scalars['Float']>;
  Reminder2?: Maybe<Scalars['Float']>;
  Reminder3?: Maybe<Scalars['Float']>;
  Reminder4?: Maybe<Scalars['Float']>;
  InterestTrx?: Maybe<Scalars['String']>;
  InterestAccount?: Maybe<Scalars['String']>;
  FinalReminder?: Maybe<Scalars['Float']>;
};

export type ChannelManagerEntity = InterfaceBase & {
  __typename?: 'ChannelManagerEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Pass?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
};

export type PaymentGatewayEntity = InterfaceBase & {
  __typename?: 'PaymentGatewayEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Client?: Maybe<Scalars['String']>;
  Secret?: Maybe<Scalars['String']>;
  Store?: Maybe<Scalars['String']>;
  PrivateKey?: Maybe<Scalars['String']>;
  PublicKey?: Maybe<Scalars['String']>;
  Environment?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
};

export type FacilityEntity = InterfaceBase & {
  __typename?: 'FacilityEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  FacilityGallery?: Maybe<Array<FacilityGalleryEntity>>;
};

export type FacilityGalleryEntity = InterfaceBase & {
  __typename?: 'FacilityGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  FacilityID: Scalars['String'];
  GalleryID: Scalars['String'];
  Facility?: Maybe<FacilityEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type NewsEntity = InterfaceBase & {
  __typename?: 'NewsEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  NewsGallery?: Maybe<Array<NewsGalleryEntity>>;
};

export type NewsGalleryEntity = InterfaceBase & {
  __typename?: 'NewsGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NewsID: Scalars['String'];
  GalleryID: Scalars['String'];
  News?: Maybe<NewsEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type DocumentNumberingEntity = InterfaceBase & {
  __typename?: 'DocumentNumberingEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Booking?: Maybe<Scalars['Float']>;
  Incidental?: Maybe<Scalars['Float']>;
  Deposit?: Maybe<Scalars['Float']>;
  Receipt?: Maybe<Scalars['Float']>;
  Folio?: Maybe<Scalars['Float']>;
  CreditNote?: Maybe<Scalars['Float']>;
  DebitNote?: Maybe<Scalars['Float']>;
  Refund?: Maybe<Scalars['Float']>;
  PaySession?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
};

export type PromotionEntity = InterfaceBase & {
  __typename?: 'PromotionEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DiscountType: DiscountType;
  DiscountValue?: Maybe<Scalars['Float']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Hotel?: Maybe<HotelEntity>;
  PromotionDetail?: Maybe<Array<PromotionDetailEntity>>;
  PromotionDetailRatePolicy?: Maybe<Array<PromotionDetailEntity>>;
};


export type PromotionEntityPromotionDetailRatePolicyArgs = {
  RatePolicyID?: Maybe<Scalars['String']>;
};

export enum DiscountType {
  ByAmount = 'By_Amount',
  ByPercentage = 'By_Percentage'
}

export type PromotionDetailEntity = InterfaceBase & {
  __typename?: 'PromotionDetailEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PromotionID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['Float']>;
  Promotion?: Maybe<PromotionEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  RatePolicy?: Maybe<RatePolicyEntity>;
};

export type SeasonCalendarEntity = InterfaceBase & {
  __typename?: 'SeasonCalendarEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  SeasonType: SeasonType;
  Hotel?: Maybe<HotelEntity>;
};

export type LateCheckOutChargeEntity = InterfaceBase & {
  __typename?: 'LateCheckOutChargeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Name: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
};

export type EmailCampignEntity = InterfaceBase & {
  __typename?: 'EmailCampignEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  IsDraft?: Maybe<Scalars['Boolean']>;
  IsSent?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
};

export type HotelGalleryEntity = InterfaceBase & {
  __typename?: 'HotelGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  GalleryID: Scalars['String'];
  IsDefault?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type HotelEntity = InterfaceBase & {
  __typename?: 'HotelEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelName?: Maybe<Scalars['String']>;
  HotelAddress?: Maybe<Address>;
  SSTRegNo?: Maybe<Scalars['String']>;
  TTxRegNo?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  Logo?: Maybe<Scalars['String']>;
  HotelType?: Maybe<PropertyType>;
  ContactID?: Maybe<Scalars['String']>;
  CheckInTime?: Maybe<Scalars['DateTime']>;
  CheckOutTime?: Maybe<Scalars['DateTime']>;
  Currency?: Maybe<Scalars['String']>;
  NAProcessTime?: Maybe<Scalars['DateTime']>;
  IsPostPaid?: Maybe<Scalars['Boolean']>;
  Tax?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  IsInclusive?: Maybe<Scalars['Boolean']>;
  TTx?: Maybe<Scalars['Float']>;
  HTx?: Maybe<Scalars['Float']>;
  Levy?: Maybe<Scalars['Float']>;
  CheckInPrompt: Scalars['Boolean'];
  SecurityDeposit?: Maybe<Scalars['Float']>;
  KeycardDeposit?: Maybe<Scalars['Float']>;
  CheckInRoomStatusID?: Maybe<Scalars['String']>;
  CancelCheckInRoomStatusID?: Maybe<Scalars['String']>;
  TransferRoomStatusID?: Maybe<Scalars['String']>;
  BlockRoomReleaseStatusID?: Maybe<Scalars['String']>;
  CheckOutRoomStatusID?: Maybe<Scalars['String']>;
  CancelCheckOutRoomStatusID?: Maybe<Scalars['String']>;
  CeilingPercentage?: Maybe<Scalars['Float']>;
  FloorPercentage?: Maybe<Scalars['Float']>;
  DayUseID?: Maybe<Scalars['String']>;
  HouseUseID?: Maybe<Scalars['String']>;
  ComplimentaryID?: Maybe<Scalars['String']>;
  FloorPriceKickInDays?: Maybe<Scalars['Float']>;
  AllowCancellationDays?: Maybe<Scalars['Float']>;
  OnlineTnC?: Maybe<Scalars['String']>;
  RegCardTnC?: Maybe<Scalars['String']>;
  OnlineBookingURL?: Maybe<Scalars['String']>;
  AboutHotelGalleryID?: Maybe<Scalars['String']>;
  AboutHotel?: Maybe<Scalars['String']>;
  ARSpecification?: Maybe<ArSpecification>;
  ChannelManagerID?: Maybe<Scalars['String']>;
  PaymentGatewayID?: Maybe<Scalars['String']>;
  OnlinePaymentStoreID?: Maybe<Scalars['String']>;
  Contact?: Maybe<ContactEntity>;
  CheckInRoomStatus?: Maybe<RoomStatusEntity>;
  CancelCheckInRoomStatus?: Maybe<RoomStatusEntity>;
  TransferRoomStatus?: Maybe<RoomStatusEntity>;
  CheckOutRoomStatus?: Maybe<RoomStatusEntity>;
  BlockRoomReleaseStatus?: Maybe<RoomStatusEntity>;
  CancelCheckOutRoomStatus?: Maybe<RoomStatusEntity>;
  DayUse?: Maybe<RatePolicyEntity>;
  HouseUse?: Maybe<RatePolicyEntity>;
  Complimentary?: Maybe<RatePolicyEntity>;
  AboutHotelImage?: Maybe<GalleryEntity>;
  LogoImage?: Maybe<GalleryEntity>;
  ChannelManager?: Maybe<ChannelManagerEntity>;
  PaymentGateway?: Maybe<PaymentGatewayEntity>;
  Facility?: Maybe<Array<FacilityEntity>>;
  News?: Maybe<Array<NewsEntity>>;
  Segment?: Maybe<Array<SegmentEntity>>;
  DocumentNumbering?: Maybe<DocumentNumberingEntity>;
  HotelRating?: Maybe<Array<HotelRatingEntity>>;
  Department?: Maybe<Array<DepartmentEntity>>;
  Promotion?: Maybe<Array<PromotionEntity>>;
  Location?: Maybe<Array<LocationEntity>>;
  RoomStatus?: Maybe<Array<RoomStatusEntity>>;
  RoomType?: Maybe<Array<RoomTypeEntity>>;
  Room?: Maybe<Array<RoomEntity>>;
  SeasonCalendar?: Maybe<Array<SeasonCalendarEntity>>;
  LateCheckOutCharge?: Maybe<Array<LateCheckOutChargeEntity>>;
  RatePolicy?: Maybe<Array<RatePolicyEntity>>;
  Debtor?: Maybe<Array<DebtorEntity>>;
  DebtorType?: Maybe<Array<DebtorTypeEntity>>;
  HouseKeepingLog?: Maybe<Array<HouseKeepingLogEntity>>;
  BlockRoomLog?: Maybe<Array<BlockRoomLogEntity>>;
  RoomDiscrepancyLog?: Maybe<Array<RoomDiscrepancyLogEntity>>;
  ServiceRequest?: Maybe<Array<ServiceRequestEntity>>;
  Booking?: Maybe<Array<BookingEntity>>;
  EmailCampign?: Maybe<Array<EmailCampignEntity>>;
  Availability?: Maybe<Array<AvailabilityEntity>>;
  BillSchedule?: Maybe<Array<BillScheduleEntity>>;
  Folio?: Maybe<Array<FolioEntity>>;
  BillLedger?: Maybe<Array<BillLedgerEntity>>;
  Payment?: Maybe<Array<PaymentEntity>>;
  Price?: Maybe<Array<PricingOutput>>;
  HotelDebtor?: Maybe<DebtorEntity>;
  HotelRate?: Maybe<Array<RoomTypeEntity>>;
  HotelGallery?: Maybe<Array<HotelGalleryEntity>>;
};

export type HotelRatingEntity = InterfaceBase & {
  __typename?: 'HotelRatingEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  TravelAs?: Maybe<Scalars['String']>;
  Cleanliness?: Maybe<Scalars['Float']>;
  Facilities?: Maybe<Scalars['Float']>;
  Location?: Maybe<Scalars['Float']>;
  ComfortQuality?: Maybe<Scalars['Float']>;
  Service?: Maybe<Scalars['Float']>;
  MoneyValue?: Maybe<Scalars['Float']>;
  Comments?: Maybe<Scalars['String']>;
  ReviewDate: Scalars['DateTime'];
  ReviewCount?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  Booking?: Maybe<BookingEntity>;
};

export type PlatformAmenitiesEntity = InterfaceBase & {
  __typename?: 'PlatformAmenitiesEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  GalleryID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Gallery?: Maybe<GalleryEntity>;
};

export type CoreLedger = InterfaceTrx & {
  __typename?: 'CoreLedger';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export enum ArTransactionStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Retention = 'RETENTION'
}

export type CoreTrxLedger = InterfaceTrx & {
  __typename?: 'CoreTrxLedger';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  TransactionID: Scalars['String'];
  TrxName?: Maybe<Scalars['String']>;
  TrxAmount: Scalars['Float'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
};

export type BankDetails = {
  __typename?: 'BankDetails';
  Name?: Maybe<Scalars['String']>;
  AcctHolder?: Maybe<Scalars['String']>;
  AcctNo?: Maybe<Scalars['String']>;
  Branch?: Maybe<Scalars['String']>;
};

export type DebitNoteEntity = InterfaceTrx & {
  __typename?: 'DebitNoteEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  LedgerTrx: Array<DebitNoteTrxEntity>;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
};

export type DebitNoteTrxEntity = InterfaceTrx & {
  __typename?: 'DebitNoteTrxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  TransactionID: Scalars['String'];
  TrxName?: Maybe<Scalars['String']>;
  TrxAmount: Scalars['Float'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  DebitNoteID: Scalars['String'];
};

export type CreditNoteEntity = InterfaceTrx & {
  __typename?: 'CreditNoteEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  LedgerTrx: Array<CreditNoteTrxEntity>;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
};

export type CreditNoteTrxEntity = InterfaceTrx & {
  __typename?: 'CreditNoteTrxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  TransactionID: Scalars['String'];
  TrxName?: Maybe<Scalars['String']>;
  TrxAmount: Scalars['Float'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  CreditNoteID: Scalars['String'];
};

export type RefundEntity = InterfaceTrx & {
  __typename?: 'RefundEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Commission: Scalars['Float'];
  bankDetails: BankDetails;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
};

export type OfficialReceiptEntity = InterfaceTrx & {
  __typename?: 'OfficialReceiptEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Commission: Scalars['Float'];
  bankDetails: BankDetails;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
};

export type InvoiceEntity = InterfaceTrx & {
  __typename?: 'InvoiceEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  LedgerTrx: Array<InvoiceTrxEntity>;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
};

export type InvoiceTrxEntity = InterfaceTrx & {
  __typename?: 'InvoiceTrxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  TransactionID: Scalars['String'];
  TrxName?: Maybe<Scalars['String']>;
  TrxAmount: Scalars['Float'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  InvoiceID: Scalars['String'];
};

export type AllocationEntity = InterfaceTrx & {
  __typename?: 'AllocationEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID: Scalars['String'];
  DebitRefTable?: Maybe<Scalars['String']>;
  DebitID?: Maybe<Scalars['String']>;
  CreditRefTable?: Maybe<Scalars['String']>;
  CreditID?: Maybe<Scalars['String']>;
  AllocationAmount: Scalars['Float'];
  CreditDetail: Array<DebitNoteEntity>;
};

export type ReminderEntity = InterfaceTrx & {
  __typename?: 'ReminderEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID: Scalars['String'];
  LedgerID: Scalars['String'];
  ReminderNo: Scalars['String'];
  BalanceAmt: Scalars['Float'];
  ReminderDate: Scalars['DateTime'];
  ReminderSeq: Scalars['Float'];
};

export type InterestEntity = InterfaceTrx & {
  __typename?: 'InterestEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID: Scalars['String'];
  LedgerID: Scalars['String'];
  OverDueDays: Scalars['Float'];
  OverDueAmt: Scalars['Float'];
  InterestDate: Scalars['DateTime'];
  InterestRate: Scalars['DateTime'];
  InteresAmt: Scalars['Float'];
  InterestAmt: Scalars['Float'];
  TrxStatus: ArTransactionStatus;
  IsWaived: Scalars['Boolean'];
};

export type SharedGalleryEntity = InterfaceBase & {
  __typename?: 'SharedGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  GalleryID: Scalars['String'];
  Gallery?: Maybe<GalleryEntity>;
};

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type CorePermissionEntity = {
  __typename?: 'CorePermissionEntity';
  index: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRoleEntity = AuditEntity & {
  __typename?: 'CoreRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'CoreRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type CoreEntityRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreEntityRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
};

export type RoleEntity = AuditEntity & {
  __typename?: 'RoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  rolePerm?: Maybe<Array<PermRoleAsgEntity>>;
};

export type PermRoleAsgEntity = AuditEntity & {
  __typename?: 'PermRoleAsgEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
  permName?: Maybe<Scalars['String']>;
};

export type EntRoleUsrAsgEntity = AuditEntity & {
  __typename?: 'EntRoleUsrAsgEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
};

export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
};

export enum CommonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

export type PlatformHeritageTaxEntity = InterfaceBase & {
  __typename?: 'PlatformHeritageTaxEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
};

export type PlatformTourismTaxEntity = InterfaceBase & {
  __typename?: 'PlatformTourismTaxEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
};

export type PlatformLevyEntity = InterfaceBase & {
  __typename?: 'PlatformLevyEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
};

export type PlatformServiceChargeEntity = InterfaceBase & {
  __typename?: 'PlatformServiceChargeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  DefaultValue?: Maybe<Scalars['Float']>;
};

export type PlatformTaxEntity = InterfaceBase & {
  __typename?: 'PlatformTaxEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TaxScheme?: Maybe<Scalars['String']>;
  TaxCode?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
};

export type HotelTaxPolicyEntity = InterfaceBase & {
  __typename?: 'HotelTaxPolicyEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  TaxType?: Maybe<TaxType>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
};

export type TaxOutput = {
  __typename?: 'TaxOutput';
  DiscountAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TotalAmount?: Maybe<Scalars['Float']>;
};

export type HotelTaxOutput = {
  __typename?: 'HotelTaxOutput';
  TaxScheme?: Maybe<Scalars['String']>;
  TaxCode?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
};

export type CurrentTaxOutput = {
  __typename?: 'currentTaxOutput';
  TaxScheme?: Maybe<Scalars['String']>;
  TaxCode?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
};

export type CurrentTaxDetailsOutput = {
  __typename?: 'CurrentTaxDetailsOutput';
  HotelID?: Maybe<Scalars['String']>;
  GovTax?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
};

export type AtestEntity = InterfaceTrx & {
  __typename?: 'AtestEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  Address?: Maybe<Array<Address>>;
};

export type DocumentTemplateEntity = InterfaceBase & {
  __typename?: 'DocumentTemplateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RegCard?: Maybe<Scalars['String']>;
  Folio?: Maybe<Scalars['String']>;
  Receipt?: Maybe<Scalars['String']>;
  Invoice?: Maybe<Scalars['String']>;
};

export type EmailTemplateEntity = InterfaceBase & {
  __typename?: 'EmailTemplateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Welcome?: Maybe<Scalars['String']>;
  ThankYou?: Maybe<Scalars['String']>;
  Prepaid?: Maybe<Scalars['String']>;
  Postpaid?: Maybe<Scalars['String']>;
  CancelBooking?: Maybe<Scalars['String']>;
};

export type NotificationEntity = {
  __typename?: 'NotificationEntity';
  ID: Scalars['ID'];
  HotelID?: Maybe<Scalars['String']>;
  Message?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
};

export type PlatformDepartmentEntity = InterfaceBase & {
  __typename?: 'PlatformDepartmentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AllowReason?: Maybe<Scalars['Boolean']>;
  AllowSalesChannel?: Maybe<Scalars['Boolean']>;
  AllowIncidental?: Maybe<Scalars['Boolean']>;
  AllowGuestApp?: Maybe<Scalars['Boolean']>;
};

export type TaxEffectiveDateEntity = InterfaceBase & {
  __typename?: 'TaxEffectiveDateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PlatformID?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['DateTime'];
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
  Name?: Maybe<Scalars['String']>;
};

export type MeasurementEntity = InterfaceBase & {
  __typename?: 'MeasurementEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  Measurement: Scalars['String'];
  Size?: Maybe<Scalars['Float']>;
};

export type PackageRedemptionEntity = InterfaceBase & {
  __typename?: 'PackageRedemptionEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  BillScheduleID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  GuestName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type ArSummaryInfo = {
  __typename?: 'ARSummaryInfo';
  LastCreatedDT?: Maybe<Scalars['DateTime']>;
  TotalOstandingAmount?: Maybe<Scalars['Float']>;
  TotalAmount?: Maybe<Scalars['Float']>;
  noOfDocument?: Maybe<Scalars['Float']>;
};

export type ArAccountSummary = {
  __typename?: 'ARAccountSummary';
  DNSum?: Maybe<Scalars['Float']>;
  InvSum?: Maybe<Scalars['Float']>;
  CNSum?: Maybe<Scalars['Float']>;
  RefundSum?: Maybe<Scalars['Float']>;
  ORSum?: Maybe<Scalars['Float']>;
};

export type ArAccountOsLastPayment = {
  __typename?: 'ARAccountOSLastPayment';
  LastPaymentDT?: Maybe<Scalars['DateTime']>;
  TotalOstandingAmount?: Maybe<Scalars['Float']>;
};

export type ArAgingAnalysisRespose = {
  __typename?: 'ARAgingAnalysisRespose';
  day30: Scalars['Float'];
  day60: Scalars['Float'];
  day90: Scalars['Float'];
  day120: Scalars['Float'];
  day150: Scalars['Float'];
  day180: Scalars['Float'];
  daygt180: Scalars['Float'];
};

export type SegmentReportOutput = {
  __typename?: 'SegmentReportOutput';
  SegmentID?: Maybe<Scalars['String']>;
  SegmentName?: Maybe<Scalars['String']>;
  SegmentYRoomCount?: Maybe<Scalars['Float']>;
  SegmentYGuestCount?: Maybe<Scalars['Float']>;
  SegmentYRoomRevenue?: Maybe<Scalars['Float']>;
  SegmentYOtherRevenue?: Maybe<Scalars['Float']>;
  SegmentYARR?: Maybe<Scalars['Float']>;
  SegmentMRoomCount?: Maybe<Scalars['Float']>;
  SegmentMGuestCount?: Maybe<Scalars['Float']>;
  SegmentMRoomRevenue?: Maybe<Scalars['Float']>;
  SegmentMOtherRevenue?: Maybe<Scalars['Float']>;
  SegmentMARR?: Maybe<Scalars['Float']>;
  SegmentDRoomCount?: Maybe<Scalars['Float']>;
  SegmentDGuestCount?: Maybe<Scalars['Float']>;
  SegmentDRoomRevenue?: Maybe<Scalars['Float']>;
  SegmentDOtherRevenue?: Maybe<Scalars['Float']>;
  SegmentDARR?: Maybe<Scalars['Float']>;
};

export type SourceReportOutput = {
  __typename?: 'SourceReportOutput';
  SourceName?: Maybe<Scalars['String']>;
  SourceYRoomCount?: Maybe<Scalars['Float']>;
  SourceYGuestCount?: Maybe<Scalars['Float']>;
  SourceYRoomRevenue?: Maybe<Scalars['Float']>;
  SourceYOtherRevenue?: Maybe<Scalars['Float']>;
  SourceYARR?: Maybe<Scalars['Float']>;
  SourceMRoomCount?: Maybe<Scalars['Float']>;
  SourceMGuestCount?: Maybe<Scalars['Float']>;
  SourceMRoomRevenue?: Maybe<Scalars['Float']>;
  SourceMOtherRevenue?: Maybe<Scalars['Float']>;
  SourceMARR?: Maybe<Scalars['Float']>;
  SourceDRoomCount?: Maybe<Scalars['Float']>;
  SourceDGuestCount?: Maybe<Scalars['Float']>;
  SourceDRoomRevenue?: Maybe<Scalars['Float']>;
  SourceDOtherRevenue?: Maybe<Scalars['Float']>;
  SourceDARR?: Maybe<Scalars['Float']>;
};

export type CountryReportOutput = {
  __typename?: 'CountryReportOutput';
  CountryName?: Maybe<Scalars['String']>;
  CountryYRoomCount?: Maybe<Scalars['Float']>;
  CountryYGuestCount?: Maybe<Scalars['Float']>;
  CountryYRoomRevenue?: Maybe<Scalars['Float']>;
  CountryYOtherRevenue?: Maybe<Scalars['Float']>;
  CountryYARR?: Maybe<Scalars['Float']>;
  CountryMRoomCount?: Maybe<Scalars['Float']>;
  CountryMGuestCount?: Maybe<Scalars['Float']>;
  CountryMRoomRevenue?: Maybe<Scalars['Float']>;
  CountryMOtherRevenue?: Maybe<Scalars['Float']>;
  CountryMARR?: Maybe<Scalars['Float']>;
  CountryDRoomCount?: Maybe<Scalars['Float']>;
  CountryDGuestCount?: Maybe<Scalars['Float']>;
  CountryDRoomRevenue?: Maybe<Scalars['Float']>;
  CountryDOtherRevenue?: Maybe<Scalars['Float']>;
  CountryDARR?: Maybe<Scalars['Float']>;
};

export type DepartmentReportOutput = {
  __typename?: 'DepartmentReportOutput';
  DepartmentName?: Maybe<Scalars['String']>;
  TransactionName?: Maybe<Scalars['String']>;
  TransactionDAmount?: Maybe<Scalars['Float']>;
  TransactionMAmount?: Maybe<Scalars['Float']>;
  TransactionYAmount?: Maybe<Scalars['Float']>;
};

export type FolioOutput = {
  __typename?: 'FolioOutput';
  FolioID?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  FolioAmount?: Maybe<Scalars['Float']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  FolioType?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  RegID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  PromoCode?: Maybe<Scalars['String']>;
  RegistrationStatus?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  RoomRate?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  SharerNo?: Maybe<Scalars['Float']>;
  NoOfAdult?: Maybe<Scalars['Float']>;
  NoOfChild?: Maybe<Scalars['Float']>;
  NoOfInfant?: Maybe<Scalars['Float']>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  Signature?: Maybe<Scalars['String']>;
};

export type TaxReportOutput = {
  __typename?: 'TaxReportOutput';
  DepartmentName?: Maybe<Scalars['String']>;
  TransactionName?: Maybe<Scalars['String']>;
  TransactionDAmount?: Maybe<Scalars['Float']>;
  TransactionMAmount?: Maybe<Scalars['Float']>;
  TransactionYAmount?: Maybe<Scalars['Float']>;
};

export type PaymentOutput = {
  __typename?: 'PaymentOutput';
  DepartmentName?: Maybe<Scalars['String']>;
  TransactionName?: Maybe<Scalars['String']>;
  TransactionDAmount?: Maybe<Scalars['Float']>;
  TransactionMAmount?: Maybe<Scalars['Float']>;
  TransactionYAmount?: Maybe<Scalars['Float']>;
};

export type BookingCancellation = {
  __typename?: 'BookingCancellation';
  GuestName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  NewExtendDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  RateAmount?: Maybe<Scalars['Float']>;
  BookingNo?: Maybe<Scalars['String']>;
};

export type VipInHouseOutput = {
  __typename?: 'VIPInHouseOutput';
  GuestName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type GroupInHouseOutput = {
  __typename?: 'GroupInHouseOutput';
  GroupName?: Maybe<Scalars['String']>;
  NoOfRoom?: Maybe<Scalars['Float']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type TmrGroupOutput = {
  __typename?: 'TmrGroupOutput';
  GroupName?: Maybe<Scalars['String']>;
  NoOfRoom?: Maybe<Scalars['Float']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type HouseUseOutput = {
  __typename?: 'HouseUseOutput';
  GuestName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type CompOutput = {
  __typename?: 'CompOutput';
  GuestName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type ExtendStayOutPut = {
  __typename?: 'ExtendStayOutPut';
  GuestName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  NewExtendDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type ForecastOutPut = {
  __typename?: 'ForecastOutPut';
  Category?: Maybe<Scalars['String']>;
  TodayDate?: Maybe<Scalars['Float']>;
  TomorrowDate?: Maybe<Scalars['Float']>;
  DayAfterTomorrow?: Maybe<Scalars['Float']>;
};

export type RegAndBooking = {
  __typename?: 'RegAndBooking';
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
};

export type ManagerReportOutput = {
  __typename?: 'ManagerReportOutput';
  SegmentReport?: Maybe<Array<SegmentReportOutput>>;
  SourceReport?: Maybe<Array<SourceReportOutput>>;
  CountryReport?: Maybe<Array<CountryReportOutput>>;
  DepartmentReport?: Maybe<Array<DepartmentReportOutput>>;
  TaxReport?: Maybe<Array<TaxReportOutput>>;
  PaymentReport?: Maybe<Array<PaymentOutput>>;
  BookingCancellation?: Maybe<Array<BookingCancellation>>;
  VIPInHouse?: Maybe<Array<VipInHouseOutput>>;
  GroupInHouse?: Maybe<Array<GroupInHouseOutput>>;
  TomorrowGroup?: Maybe<Array<TmrGroupOutput>>;
  HouseUseRoom?: Maybe<Array<HouseUseOutput>>;
  CompRoom?: Maybe<Array<CompOutput>>;
  ExtendStay?: Maybe<Array<ExtendStayOutPut>>;
  ThreeDayForcast?: Maybe<Array<ForecastOutPut>>;
};

export type AzureStorageOutput = {
  __typename?: 'AzureStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type DashBoardRespond = {
  __typename?: 'DashBoardRespond';
  TotalCheckIn?: Maybe<Scalars['Float']>;
  TotalDueOut?: Maybe<Scalars['Float']>;
  TotalCheckOut?: Maybe<Scalars['Float']>;
  TotalDueIn?: Maybe<Scalars['Float']>;
  TotalWalkIn?: Maybe<Scalars['Float']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  TotalCheckInRoom?: Maybe<Scalars['Float']>;
};

export type FrontDeskResponse = {
  __typename?: 'FrontDeskResponse';
  BookingNo?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  Code?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  BookingStatus?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  DebtorName?: Maybe<Scalars['String']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  IncidentalAmt?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  PaymentID?: Maybe<Scalars['String']>;
  BillScheduleID?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<ChargeType>;
  DepositClass?: Maybe<DepositClass>;
  PaymentType?: Maybe<PaymentType>;
  FolioID?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  DepositID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionType>;
};

export type DepositResponse = {
  __typename?: 'DepositResponse';
  DepositResult?: Maybe<Scalars['MyCustomScalar']>;
};


export type FrontDeskInfoLineResponse = {
  __typename?: 'FrontDeskInfoLineResponse';
  TotalDueIn?: Maybe<Scalars['Float']>;
  TotalCheckIn?: Maybe<Scalars['Float']>;
  TotalWalkIn?: Maybe<Scalars['Float']>;
  TotalDepartureDueOut?: Maybe<Scalars['Float']>;
  DepartureDueOut?: Maybe<Scalars['Float']>;
  TotalRegistrationDueOut?: Maybe<Scalars['Float']>;
  RegistrationDueOut?: Maybe<Scalars['Float']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  TotalGuest?: Maybe<Scalars['Float']>;
  DepositSum?: Maybe<Scalars['Float']>;
  TotalDepositRoom?: Maybe<Scalars['Float']>;
  TotalUnAssignedRoomRegistration?: Maybe<Scalars['Float']>;
};

export type PaymentFoilioResponse = {
  __typename?: 'PaymentFoilioResponse';
  RegistrationID?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  ChargeType?: Maybe<Scalars['String']>;
  LedgerType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  Type?: Maybe<Scalars['String']>;
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<FieldError>>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: UserResponse;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type RoleUser = {
  __typename?: 'roleUser';
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type UserRoleId = {
  __typename?: 'userRoleID';
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

export type Item = {
  __typename?: 'item';
  checkoutId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  qrCodeUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Errors = {
  __typename?: 'Errors';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  debug?: Maybe<Scalars['String']>;
};

export type CheckOut = {
  __typename?: 'CheckOut';
  item?: Maybe<Item>;
  code?: Maybe<Scalars['String']>;
  error?: Maybe<Errors>;
  PaySessionNo?: Maybe<Scalars['Float']>;
};

export type InterestResponse = {
  __typename?: 'InterestResponse';
  TotalTrxAmt: Scalars['Float'];
  TotalInterestAmt: Scalars['Float'];
};

export type InterfaceBase = {
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type InterfaceTrx = {
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type SalesChannelInput = {
  ID?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ReasonInput = {
  ID?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type GalleryInput = {
  ID: Scalars['String'];
  AccountID: Scalars['String'];
  BucketFileName?: Maybe<Scalars['String']>;
  ImageURL?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export type RoomTypeAmenitiesInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  GalleryID?: Maybe<Scalars['String']>;
  IsSelected?: Maybe<Scalars['Boolean']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LocationInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RoomStatusInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Description: RoomStatus;
  Code: Scalars['String'];
  VacantOrOccupied: Scalars['String'];
  CleanOrDirty: Scalars['String'];
  RGBColor?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type BlockRoomLogInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  BlockDate: Scalars['DateTime'];
  ReleaseDate: Scalars['DateTime'];
  BlockStatusID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsRelease?: Maybe<Scalars['Boolean']>;
  GalleryID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type HouseKeepingLogInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  HotelDate: Scalars['DateTime'];
  Status: Scalars['String'];
  CleanedBy?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
};

export type RoomDiscrepancyLogInput = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  HotelDate: Scalars['DateTime'];
  DiscrepancyReason: DiscrepancyReason;
  AttendedBy: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  IsSolved?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  siteName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type ContactPersonInput = {
  name?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContactInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportExpireDate?: Maybe<Scalars['DateTime']>;
  Nationality?: Maybe<Nationality>;
  Marital?: Maybe<Marital>;
  Gender?: Maybe<Gender>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<AddressInput>;
  ProfilePicture?: Maybe<Scalars['String']>;
  GeneralEmail?: Maybe<Scalars['String']>;
  ReservationEmail?: Maybe<Scalars['String']>;
  BusinessEmail?: Maybe<Scalars['String']>;
  InvoiceEmail?: Maybe<Scalars['String']>;
  OwnerEmail?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
  NotificationMobileNo?: Maybe<Scalars['String']>;
  WebUrl?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  VehicleNo?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
};

export type SegmentInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DebtorTypeInput = {
  HotelID?: Maybe<Scalars['String']>;
  DebtorType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ControlAccount?: Maybe<Scalars['String']>;
  DepositAccount?: Maybe<Scalars['String']>;
  AdvanceAccount?: Maybe<Scalars['String']>;
  AccrualAccount?: Maybe<Scalars['String']>;
  RevenueAccount?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export type ContractRateInput = {
  ID?: Maybe<Scalars['String']>;
  ContractID: Scalars['String'];
  RatePolicyID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export type ContractAllotmentInput = {
  ID?: Maybe<Scalars['String']>;
  ContractID?: Maybe<Scalars['String']>;
  RoomTypeID: Scalars['String'];
  AllotDate?: Maybe<Scalars['DateTime']>;
  InitialAllot?: Maybe<Scalars['Float']>;
  Adjust?: Maybe<Scalars['Float']>;
  SysAdjust?: Maybe<Scalars['Float']>;
  PickUp?: Maybe<Scalars['Float']>;
  SysRelease?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export type ContractInput = {
  ID?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  ContractNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  RoomUpgrade?: Maybe<Scalars['Boolean']>;
  Elastic?: Maybe<Scalars['Boolean']>;
  Allotment?: Maybe<Scalars['Boolean']>;
  CutOffDays?: Maybe<Scalars['Float']>;
  PaymentTerms?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export type DebtorContactInput = {
  ID: Scalars['String'];
  DebtorID: Scalars['String'];
  ContactID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export type DebtorProfileInput = {
  ID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export type DebtorInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ContactID?: Maybe<Scalars['String']>;
  DebtorProfileID?: Maybe<Scalars['String']>;
  DebtorTypeID?: Maybe<Scalars['String']>;
  DebtorContacts?: Maybe<Scalars['String']>;
  DebtorCategory?: Maybe<DebtorCategory>;
  Name: Scalars['String'];
  DebtorAccount?: Maybe<Scalars['String']>;
  DebtorAccountRemark?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CreditTerm?: Maybe<Scalars['Float']>;
  CreditLimit?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  Segment?: Maybe<Scalars['String']>;
  SalesChannel?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export type AdvancePaymentInput = {
  ID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ReversedAdvancePaymentID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  AdvancePaymentDate?: Maybe<Scalars['DateTime']>;
  PaymentClass?: Maybe<PaymentClass>;
  PaymentType?: Maybe<PaymentType>;
  DebtorAccount?: Maybe<Scalars['String']>;
  PaySessionNo?: Maybe<Scalars['Float']>;
  CheckOutID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  PaymentStatus?: Maybe<PaymentStatus>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
};

export type BookingInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  ContactID?: Maybe<Scalars['String']>;
  GroupName?: Maybe<Scalars['String']>;
  SalesChannelID?: Maybe<Scalars['String']>;
  Source?: Maybe<Source>;
  SegmentID?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  BillingInstruction?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  BookingStatus?: Maybe<BookingStatus>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type GuestProfileInput = {
  ID: Scalars['String'];
  ContactID: Scalars['String'];
  Company?: Maybe<Scalars['String']>;
  IsVIP?: Maybe<Scalars['Boolean']>;
  Login?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  VehicleNo?: Maybe<Scalars['String']>;
  NewsLetter?: Maybe<Scalars['Boolean']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  IdPicture?: Maybe<Scalars['String']>;
  PassportPicture?: Maybe<Scalars['String']>;
};

export type BillScheduleInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
  ReversedBillScheduleID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  ChargeType: ChargeType;
  TransactionType: TransactionType;
  TransactionID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RoomLedgerInput = {
  ID: Scalars['String'];
  FolioID: Scalars['String'];
  RegistrationID: Scalars['String'];
  ReversedRoomLedgerID?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Reason: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  ChargeType: ChargeType;
  TransactionType: TransactionType;
  TransactionID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TaxLedgerInput = {
  ID?: Maybe<Scalars['String']>;
  FolioID: Scalars['String'];
  RegistrationID: Scalars['String'];
  ReversedTaxLedgerID: Scalars['String'];
  Reason: Scalars['String'];
  TaxType: TaxType;
  TrxDate: Scalars['DateTime'];
  TaxAmount?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DepositLedgerInput = {
  ID: Scalars['String'];
  FolioID: Scalars['String'];
  RegistrationID: Scalars['String'];
  DepositClass: DepositClass;
  DepositNo: Scalars['String'];
  PaymentType: PaymentType;
  TrxDate: Scalars['DateTime'];
  ReferenceNo: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type BillLedgerInput = {
  ID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  ReversedBillLedgerID?: Maybe<Scalars['String']>;
  IncidentalChargeID: Scalars['String'];
  Reason: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  Qty?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  IsDelivered?: Maybe<Scalars['Boolean']>;
  Signature?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  IsChargeToRoom?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type OnlineItemInput = {
  FolioID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
};

export type FolioInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  FolioType: FolioType;
  RefID?: Maybe<Scalars['String']>;
  InvoiceNo?: Maybe<Scalars['String']>;
  FolioNo: Scalars['String'];
  FolioDate: Scalars['DateTime'];
  FolioAmount: Scalars['Float'];
  ClosedDate?: Maybe<Scalars['DateTime']>;
  ClosedBy?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PaymentInput = {
  ID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  ReceiptNo?: Maybe<Scalars['String']>;
  PaymentDate: Scalars['DateTime'];
  PaymentClass: PaymentClass;
  PaymentType: PaymentType;
  DebtorAccount?: Maybe<Scalars['String']>;
  PaySessionNo?: Maybe<Scalars['Float']>;
  CheckOutID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  PaymentStatus: PaymentStatus;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
};

export type RegistrationInput = {
  ID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RoomTypeID: Scalars['String'];
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  PromoCode?: Maybe<Scalars['String']>;
  RoomRate?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  SharerNo?: Maybe<Scalars['Float']>;
  NoOfAdult?: Maybe<Scalars['Float']>;
  NoOfChild?: Maybe<Scalars['Float']>;
  NoOfInfant?: Maybe<Scalars['Float']>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  Signature?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  RegistrationStatus?: Maybe<BookingStatus>;
  UserID?: Maybe<Scalars['String']>;
  Price?: Maybe<Scalars['Float']>;
  AvailabilityID?: Maybe<Scalars['String']>;
  NoOfRoom?: Maybe<Scalars['Float']>;
  BookedRoom?: Maybe<Scalars['Float']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  contactInput?: Maybe<Array<ContactInput>>;
};

export type EditRegInput = {
  BookingID?: Maybe<Scalars['String']>;
  OldRoomTypeID?: Maybe<Scalars['String']>;
  OldRatePolicyID?: Maybe<Scalars['String']>;
  OldNoOfRoom?: Maybe<Scalars['Float']>;
  OldPromoCode?: Maybe<Scalars['String']>;
  OldDiscountAmount?: Maybe<Scalars['Float']>;
  OldRoomRate?: Maybe<Scalars['Float']>;
  NewRoomTypeID?: Maybe<Scalars['String']>;
  NewRatePolicyID?: Maybe<Scalars['String']>;
  NewNoOfRoom?: Maybe<Scalars['Float']>;
  NewPromoCode?: Maybe<Scalars['String']>;
  NewDiscountAmount?: Maybe<Scalars['Float']>;
  NewRoomRate?: Maybe<Scalars['Float']>;
  Mode?: Maybe<Scalars['String']>;
};

export type RoomInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  LocationID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  RoomNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  RoomSize: Scalars['Float'];
  DisplaySequence?: Maybe<Scalars['Float']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AvailabilityInput = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  BookedRoom?: Maybe<Scalars['Float']>;
  BlockedRoom?: Maybe<Scalars['Float']>;
  AllottedRoom?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RoomTypeInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  RoomSize?: Maybe<Scalars['Float']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  PropertyType?: Maybe<PropertyType>;
  MeasureType?: Maybe<MeasureType>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RoomTypeGalleryInput = {
  ID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  GalleryID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PricingInput = {
  ID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  Standard?: Maybe<Scalars['Float']>;
  Ceiling?: Maybe<Scalars['Float']>;
  Floor?: Maybe<Scalars['Float']>;
  PeakStandard?: Maybe<Scalars['Float']>;
  PeakCeiling?: Maybe<Scalars['Float']>;
  PeakFloor?: Maybe<Scalars['Float']>;
  SuperPeakStandard?: Maybe<Scalars['Float']>;
  SuperPeakCeiling?: Maybe<Scalars['Float']>;
  SuperPeakFloor?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RatePolicyInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Category: RateCategory;
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  PublishOnline?: Maybe<Scalars['Boolean']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PackagesInput = {
  ID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  GalleryID?: Maybe<Scalars['String']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Boolean']>;
  ServiceCharge?: Maybe<Scalars['Boolean']>;
  ComputationRules?: Maybe<ComputationRules>;
  IncludeRate?: Maybe<Scalars['Boolean']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type IncidentalChargeInput = {
  ID?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Boolean']>;
  ServiceCharge?: Maybe<Scalars['Boolean']>;
  IsRoomRevenue?: Maybe<Scalars['Boolean']>;
  IsRoomService?: Maybe<Scalars['Boolean']>;
  IsFnB?: Maybe<Scalars['Boolean']>;
  IsEShop?: Maybe<Scalars['Boolean']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  GalleryID?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};


export type DepartmentInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AllowReason?: Maybe<Scalars['Boolean']>;
  AllowSalesChannel?: Maybe<Scalars['Boolean']>;
  AllowIncidental?: Maybe<Scalars['Boolean']>;
  AllowGuestApp?: Maybe<Scalars['Boolean']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ServiceRequestInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DepartmentID: Scalars['String'];
  RoomID: Scalars['String'];
  RequestBy: Scalars['String'];
  Title: Scalars['String'];
  Description: Scalars['String'];
  EstimatedCompletion?: Maybe<Scalars['DateTime']>;
  GalleryID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  Status: ServiceRequestStatus;
  GuestID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ArSpecificationInput = {
  Age1?: Maybe<Scalars['Float']>;
  Age2?: Maybe<Scalars['Float']>;
  Age3?: Maybe<Scalars['Float']>;
  Age4?: Maybe<Scalars['Float']>;
  Reminder1?: Maybe<Scalars['Float']>;
  Reminder2?: Maybe<Scalars['Float']>;
  Reminder3?: Maybe<Scalars['Float']>;
  Reminder4?: Maybe<Scalars['Float']>;
  InterestTrx?: Maybe<Scalars['String']>;
  InterestAccount?: Maybe<Scalars['String']>;
  FinalReminder?: Maybe<Scalars['Float']>;
};

export type ChannelManagerInput = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Pass: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PaymentGatewayInput = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Client?: Maybe<Scalars['String']>;
  Secret?: Maybe<Scalars['String']>;
  Store?: Maybe<Scalars['String']>;
  PrivateKey?: Maybe<Scalars['String']>;
  PublicKey?: Maybe<Scalars['String']>;
  Environment?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type FacilityInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type FacilityGalleryInput = {
  ID?: Maybe<Scalars['String']>;
  FacilityID: Scalars['String'];
  GalleryID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NewsInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NewsGalleryInput = {
  ID?: Maybe<Scalars['String']>;
  NewsID: Scalars['String'];
  GalleryID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DocumentNumberingInput = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  Booking?: Maybe<Scalars['Float']>;
  Incidental?: Maybe<Scalars['Float']>;
  Deposit?: Maybe<Scalars['Float']>;
  Receipt?: Maybe<Scalars['Float']>;
  Folio?: Maybe<Scalars['Float']>;
  CreditNote?: Maybe<Scalars['Float']>;
  DebitNote?: Maybe<Scalars['Float']>;
  Refund?: Maybe<Scalars['Float']>;
  PaySession?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PromotionInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DiscountType: DiscountType;
  DiscountValue?: Maybe<Scalars['Float']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PromotionDetailInput = {
  ID?: Maybe<Scalars['String']>;
  PromotionID?: Maybe<Scalars['String']>;
  RoomTypeID: Scalars['String'];
  RatePolicyID?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SeasonCalendarInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  SeasonType: SeasonType;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LateCheckOutChargeInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Name: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmailCampignInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  IsDraft?: Maybe<Scalars['Boolean']>;
  IsSent?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type HotelGalleryInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  GalleryID: Scalars['String'];
  IsDefault?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type HotelInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelName?: Maybe<Scalars['String']>;
  HotelAddress?: Maybe<AddressInput>;
  SSTRegNo?: Maybe<Scalars['String']>;
  TTxRegNo?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  Logo?: Maybe<Scalars['String']>;
  HotelType?: Maybe<PropertyType>;
  ContactID?: Maybe<Scalars['String']>;
  CheckInTime?: Maybe<Scalars['DateTime']>;
  CheckOutTime?: Maybe<Scalars['DateTime']>;
  Currency?: Maybe<Scalars['String']>;
  NAProcessTime?: Maybe<Scalars['DateTime']>;
  IsPostPaid?: Maybe<Scalars['Boolean']>;
  Tax?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  IsInclusive?: Maybe<Scalars['Boolean']>;
  TTx?: Maybe<Scalars['Float']>;
  HTx?: Maybe<Scalars['Float']>;
  Levy?: Maybe<Scalars['Float']>;
  CheckInPrompt?: Maybe<Scalars['Boolean']>;
  SecurityDeposit?: Maybe<Scalars['Float']>;
  KeycardDeposit?: Maybe<Scalars['Float']>;
  CheckInRoomStatusID?: Maybe<Scalars['String']>;
  CancelCheckInRoomStatusID?: Maybe<Scalars['String']>;
  TransferRoomStatusID?: Maybe<Scalars['String']>;
  CheckOutRoomStatusID?: Maybe<Scalars['String']>;
  CancelCheckOutRoomStatusID?: Maybe<Scalars['String']>;
  BlockRoomReleaseStatusID?: Maybe<Scalars['String']>;
  CeilingPercentage?: Maybe<Scalars['Float']>;
  FloorPercentage?: Maybe<Scalars['Float']>;
  DayUseID?: Maybe<Scalars['String']>;
  HouseUseID?: Maybe<Scalars['String']>;
  ComplimentaryID?: Maybe<Scalars['String']>;
  FloorPriceKickInDays?: Maybe<Scalars['Float']>;
  AllowCancellationDays?: Maybe<Scalars['Float']>;
  OnlineTnC?: Maybe<Scalars['String']>;
  RegCardTnC?: Maybe<Scalars['String']>;
  OnlineBookingURL?: Maybe<Scalars['String']>;
  AboutHotelGalleryID?: Maybe<Scalars['String']>;
  AboutHotel?: Maybe<Scalars['String']>;
  ARSpecification?: Maybe<ArSpecificationInput>;
  ChannelManagerID?: Maybe<Scalars['String']>;
  PaymentGatewayID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type HotelRatingInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  TravelAs?: Maybe<Scalars['String']>;
  Cleanliness?: Maybe<Scalars['Float']>;
  Facilities?: Maybe<Scalars['Float']>;
  Location?: Maybe<Scalars['Float']>;
  ComfortQuality?: Maybe<Scalars['Float']>;
  Service?: Maybe<Scalars['Float']>;
  MoneyValue?: Maybe<Scalars['Float']>;
  Comments?: Maybe<Scalars['String']>;
  ReviewDate: Scalars['DateTime'];
  ReviewCount?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AmenitiesInput = {
  ID: Scalars['String'];
  GalleryID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type BankDetailsInput = {
  Name?: Maybe<Scalars['String']>;
  AcctHolder?: Maybe<Scalars['String']>;
  AcctNo?: Maybe<Scalars['String']>;
  Branch?: Maybe<Scalars['String']>;
};

export type SharedGalleryInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  GalleryID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CreateAuditInput = {
  createdBy?: Maybe<Scalars['String']>;
};

export type UpdateAuditInput = {
  modBy?: Maybe<Scalars['String']>;
};

export type BaseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type PlatformHeritageTaxInput = {
  ID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformTourismTaxInput = {
  ID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformLevyInput = {
  ID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformServiceChargeInput = {
  ID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformTaxInput = {
  TaxScheme?: Maybe<Scalars['String']>;
  TaxCode?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type HotelTaxPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  TaxType?: Maybe<TaxType>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TaxInput = {
  HotelID?: Maybe<Scalars['String']>;
  TaxIdentifier: TaxIdentifier;
  Tax?: Maybe<Scalars['Boolean']>;
  ServiceCharge?: Maybe<Scalars['Boolean']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
};

export enum TaxIdentifier {
  Room = 'Room',
  Incidental = 'Incidental'
}

export type AtestInput = {
  Address?: Maybe<Array<AddressInput>>;
};

export type DocumentTemplateInput = {
  ID?: Maybe<Scalars['String']>;
  RegCard?: Maybe<Scalars['String']>;
  Folio?: Maybe<Scalars['String']>;
  Receipt?: Maybe<Scalars['String']>;
  Invoice?: Maybe<Scalars['String']>;
};

export type EmailTemplateInput = {
  ID?: Maybe<Scalars['String']>;
  Welcome?: Maybe<Scalars['String']>;
  ThankYou?: Maybe<Scalars['String']>;
  Prepaid?: Maybe<Scalars['String']>;
  CancelBooking?: Maybe<Scalars['String']>;
  PostPaid?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
};

export type TaxEffectiveDateInput = {
  ID: Scalars['String'];
  PlatformID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type MeasurementInput = {
  ID: Scalars['String'];
  Type: Scalars['String'];
  Measurement: Scalars['String'];
  Size?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PackageRedemptionInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  GuestName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type CoreLedgerInput = {
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export type DebitNoteEntityInput = {
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export type CreditNoteEntityInput = {
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export type RefundEntityInput = {
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Commission: Scalars['Float'];
  bankDetails: BankDetailsInput;
};

export type InvoiceEntityInput = {
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export type OfficialReceiptEntityInput = {
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Commission: Scalars['Float'];
  bankDetails: BankDetailsInput;
};

export type ReminderEntityInput = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID: Scalars['String'];
  LedgerID: Scalars['String'];
  ReminderNo: Scalars['String'];
  BalanceAmt: Scalars['Float'];
  ReminderDate: Scalars['DateTime'];
  ReminderSeq: Scalars['Float'];
};

export type InterestEntityInput = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID: Scalars['String'];
  LedgerID: Scalars['String'];
  OverDueDays: Scalars['Float'];
  OverDueAmt: Scalars['Float'];
  InterestDate: Scalars['DateTime'];
  InterestRate: Scalars['DateTime'];
  InteresAmt: Scalars['Float'];
  InterestAmt: Scalars['Float'];
  TrxStatus?: Maybe<ArTransactionStatus>;
  IsWaived: Scalars['Boolean'];
};

export type CoreTrxLedgerInput = {
  TransactionID: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  TrxAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
  TrxName?: Maybe<Scalars['String']>;
};

export type InvoiceTrxInput = {
  TransactionID: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  TrxAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
  TrxName?: Maybe<Scalars['String']>;
};

export type DebitNoteTrxInput = {
  TransactionID: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  TrxAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
  TrxName?: Maybe<Scalars['String']>;
};

export type CreditNoteTrxInput = {
  TransactionID: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  TrxAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
  TrxName?: Maybe<Scalars['String']>;
};

export type DebitAllocationInput = {
  CreditID: Scalars['String'];
  AllocationAmount: Scalars['Float'];
  CreditRefTable?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};

export type CreditAllocationInput = {
  DebitID?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
  DebitRefTable?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};

export type DepositInput = {
  FolioType: FolioType;
  FolioAmount: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  DepositClass: DepositClass;
  PaymentType: PaymentType;
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
};

export type RefundDepositInput = {
  DepositID: Scalars['String'];
  FolioID: Scalars['String'];
  RefundAmount: Scalars['Float'];
  ForfeitureAmount?: Maybe<Scalars['Float']>;
  Remarks: Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
};

export type FrontDeskPaymentInput = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  PaymentType?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<ChargeType>;
  PaymentStatus?: Maybe<PaymentStatus>;
  CheckOutID?: Maybe<Scalars['String']>;
  PaymentClass?: Maybe<PaymentClass>;
  TransactionType?: Maybe<TransactionType>;
  DebtorAccount?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  BillLedgerID?: Maybe<Scalars['String']>;
  RoomLedgerID?: Maybe<Scalars['String']>;
  BillScheduleID?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  LedgerType?: Maybe<Scalars['String']>;
  LedgerTypeID?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ModuleName?: Maybe<Scalars['String']>;
};

export type RoomUpgradeInput = {
  RegistrationIDs?: Maybe<Array<Scalars['String']>>;
  HotelID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  OldRoomID?: Maybe<Scalars['String']>;
  PromoCode?: Maybe<Scalars['String']>;
  FreeUpgrade?: Maybe<Scalars['Boolean']>;
};

export type PaymentFolioInput = {
  RegistrationID?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  ChargeType?: Maybe<Scalars['String']>;
  LedgerType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  Type?: Maybe<Scalars['String']>;
  IsSelected?: Maybe<Scalars['Boolean']>;
};

export type UserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  address?: Maybe<AddressInput>;
  superUser?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
};

export type RoleUserInput = {
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type RoleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
};

export type PermRoleAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  permId: Scalars['String'];
  roleId: Scalars['String'];
};

export type EntRoleUsrAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  hotelID: Scalars['String'];
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type ListEntRoleUsrAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  hotelID: Scalars['String'];
  roleUserIDs: Array<RoleUserInput>;
};

export type Query = {
  __typename?: 'Query';
  DebitNoteDetail: DebitNoteEntity;
  DebitNoteListings: Array<DebitNoteEntity>;
  CreditNoteDetail: CreditNoteEntity;
  CreditNoteListings: Array<DebitNoteEntity>;
  RefundDetail: RefundEntity;
  RefundListings: Array<RefundEntity>;
  InvoiceDetail: InvoiceEntity;
  InvoiceListings: Array<InvoiceEntity>;
  OfficialReceiptDetail: OfficialReceiptEntity;
  OfficialReceiptListings: Array<OfficialReceiptEntity>;
  ReminderListing: ReminderEntity;
  ReminderListings: Array<ReminderEntity>;
  InterestListing: InterestEntity;
  InterestListings: Array<ReminderEntity>;
  DebtorListing: Array<DebtorEntity>;
  DebtorDetail: DebtorEntity;
  InvoiceInfo: ArSummaryInfo;
  DebitNoteInfo: ArSummaryInfo;
  CreditNoteInfo: ArSummaryInfo;
  OfficialReceiptInfo: ArSummaryInfo;
  RefundInfo: ArSummaryInfo;
  ARAccountSummary: ArAccountSummary;
  ARTotalOutstanding: ArAccountOsLastPayment;
  ARTotalDue: Scalars['Float'];
  ARAgingAnalysis: ArAgingAnalysisRespose;
  DebtorTypeListing: Array<DebtorTypeEntity>;
  BookingListing: Array<BookingEntity>;
  BookingDetails: BookingEntity;
  AdvancePaymentListing: Array<AdvancePaymentEntity>;
  RoomServiceBookingListing: Array<BookingEntity>;
  RoomNumberForFoodListing: Array<BookingEntity>;
  AvailabilityListing: HotelAvailabilityOutput;
  PackagePriceListing: Array<PackagePriceOutput>;
  ChangeOfDateCheckAvailability: Scalars['Boolean'];
  BIBillLedgerListing: Array<BillLedgerEntity>;
  BITransactionCancellation: Array<BillLedgerEntity>;
  BIDepositLedgerListing: Array<BookingEntity>;
  BIHotelListing: HotelEntity;
  BIAnalysis: Array<BookingEntity>;
  CountItem: RegAndBooking;
  BIRoomRateListing: Array<BookingEntity>;
  BIUsersLisiting: UserEntity;
  BIInHouse: Array<RegistrationEntity>;
  BIPaymentListing: Array<PaymentEntity>;
  BIRegistrationListing: Array<BookingEntity>;
  BIHotelDetails: HotelEntity;
  BIRoomListing: Array<RoomEntity>;
  BIServiceRequestListing: Array<ServiceRequestEntity>;
  BIForeignListing: Array<BookingEntity>;
  BISecurityKeyCardDeposit: Array<DepositLedgerEntity>;
  BIRoomDiscrepancyLog: Array<RoomDiscrepancyLogEntity>;
  BIRoomStatus: Array<RoomEntity>;
  BIBookingStatus: Array<BookingEntity>;
  BIFolioListing: Array<FolioEntity>;
  BITestListing: Array<FolioOutput>;
  BITransactionLedger: Array<BookingEntity>;
  BIPackage: Array<BillScheduleEntity>;
  ManagerReport: ManagerReportOutput;
  ManagerReportDepartment: ManagerReportOutput;
  CorporateGovernmentListing: Array<DebtorEntity>;
  GovernmentListing: Array<DebtorEntity>;
  CorporateBookingListing: Array<BookingEntity>;
  CorporateGovernmentDetail: DebtorEntity;
  CorporateContractListing: Array<ContractEntity>;
  ContractDetails: ContractEntity;
  CorporateInUseListing: Scalars['Boolean'];
  CoporateInUseListing: Scalars['Boolean'];
  InHouseListing: DashBoardRespond;
  DashBoardListing: Array<BookingEntity>;
  AdvancePaymentFullListing: Array<AdvancePaymentEntity>;
  PaymentListing: Array<PaymentEntity>;
  RoomServicesListing: Array<BillLedgerEntity>;
  PromotionListing: Array<PromotionEntity>;
  PromotionDetailsInUseListing: Scalars['Boolean'];
  PromotionDetailListing: Array<PromotionDetailEntity>;
  EmailMarketingListing: Array<EmailCampignEntity>;
  EmailMarketingDetail: Array<EmailCampignEntity>;
  PromotionInUseListing: Scalars['Boolean'];
  DocumentTemplateList: DocumentTemplateEntity;
  FrontDeskListing: Array<BookingEntity>;
  AssignRoomFrontDeskListing: Array<BookingEntity>;
  GuestProfile: GuestProfileEntity;
  BookingProfile: BookingEntity;
  SelectFolio: FolioEntity;
  DepositListingByBooking: Array<DepositLedgerEntity>;
  UnutilizedDepositListing?: Maybe<DepositResponse>;
  UtilizedDepositListing?: Maybe<DepositResponse>;
  FrontDeskPaymentListing: Array<FrontDeskResponse>;
  BillingByRegistration: Array<PaymentFoilioResponse>;
  IsRoomAvailable: Scalars['Boolean'];
  FrontDeskIncidentalChargeListing: Array<BillLedgerEntity>;
  FrontDeskServiceRequestListing: Array<ServiceRequestEntity>;
  ActiveBookingListing: Array<BookingEntity>;
  PrincipalGuestListing: Array<RegistrationEntity>;
  FolioListingByRegistration: Array<FolioEntity>;
  SelectedRegistration: RegistrationEntity;
  ReceiptListing: Array<PaymentFoilioResponse>;
  PaymentDetail: PaymentEntity;
  AdvancePaymentDetail: AdvancePaymentEntity;
  FrontDeskInfoLines: FrontDeskInfoLineResponse;
  ContactPagination: Array<ContactEntity>;
  RegistrationListing: Array<RegistrationEntity>;
  CurrentTaxDetails: CurrentTaxDetailsOutput;
  HotelsByCity: Array<HotelEntity>;
  HotelPricesByDateRange: HotelEntity;
  loggedInGuestProfile: GuestProfileEntity;
  GuestBookingListing: Array<BookingEntity>;
  DisCountByPromoCode: Scalars['Float'];
  GuestRoomServiceListing: Array<BillLedgerEntity>;
  GuestProfileListing: Array<BookingEntity>;
  GuestProfileListingByID: Array<RegistrationEntity>;
  SelectedGuestListing: Array<GuestProfileEntity>;
  GuestHistoryListing: Array<RegistrationEntity>;
  GuestProfileHistoryListing: Array<RegistrationEntity>;
  GuestProfileListbyRoom: Array<GuestProfileEntity>;
  RoomServiceFolioListing: Array<FolioEntity>;
  PaymentRoomServiceDetail: PaymentEntity;
  BillLedgerFullListing: Array<BillScheduleEntity>;
  PaymentBillLedgerListing: Array<BillLedgerEntity>;
  PaymentRegistrationListing: RegistrationEntity;
  RoomServiceListing: Array<BillLedgerEntity>;
  HousekeepingListing: Array<RoomEntity>;
  TodayBookingListing: Array<BookingEntity>;
  HousekeepingDetail: RoomEntity;
  HousekeepingServiceRequestListing: Array<ServiceRequestEntity>;
  HousekeepingRoomStatusListing: Array<RoomStatusEntity>;
  BillLedgerListing: Array<BillLedgerEntity>;
  HousekeepingIncidentalChargeListing: Array<IncidentalChargeEntity>;
  ServiceRequestDetail: Array<ServiceRequestEntity>;
  ServiceRequestFullListing: Array<ServiceRequestEntity>;
  ServiceRequestListing: Array<ServiceRequestEntity>;
  SrLocationListing: Array<LocationEntity>;
  DepartmentListing: Array<DepartmentEntity>;
  DepartmentInUseListing: Scalars['Boolean'];
  IncidentalDepartmentListing: Array<DepartmentEntity>;
  HotelListing: Array<HotelEntity>;
  HotelDetails: HotelEntity;
  IncidentalChargeListing: Array<IncidentalChargeEntity>;
  IncidentalChargeInUseListing: Scalars['Boolean'];
  LateCheckOutChargeListing: Array<LateCheckOutChargeEntity>;
  LocationListing: Array<LocationEntity>;
  LocationInUseListing: Scalars['Boolean'];
  RoomInUseListing: Scalars['Boolean'];
  MeasurementListing: Array<MeasurementEntity>;
  PlatformAmenitiesListing: Array<PlatformAmenitiesEntity>;
  PlatformDepartmentListing: Array<PlatformDepartmentEntity>;
  RatePolicyListing: Array<RatePolicyEntity>;
  RatePolicyDetails: RatePolicyEntity;
  RatePolicyInUseListing: Scalars['Boolean'];
  ReasonListing: Array<ReasonEntity>;
  CancelReasonListing: Array<ReasonEntity>;
  ReaonInUseListing: Scalars['Boolean'];
  RoomListing: Array<RoomEntity>;
  BlockRoomLogListing: Scalars['Boolean'];
  HotelRoomNumberList: Array<RoomEntity>;
  RoomStatusListing: Array<RoomStatusEntity>;
  RoomTypeAmenitiesListing: Array<RoomTypeAmenitiesEntity>;
  RoomTypeListing: Array<RoomTypeEntity>;
  RegistrationRoomTypeListing: Scalars['Boolean'];
  SalesChannelListing: Array<SalesChannelEntity>;
  HotelSalesChannelListing: Array<SalesChannelEntity>;
  SeasonCalendarListing: Array<SeasonCalendarEntity>;
  SegmentListing: Array<SegmentEntity>;
  SegmentInUseListing: Scalars['Boolean'];
  TaxEffectiveDateListing: Array<TaxEffectiveDateEntity>;
  PlatformHeritageTaxListing: PlatformHeritageTaxEntity;
  TaxInUseListing: Scalars['Boolean'];
  ComputeTaxbyHotel: TaxOutput;
  ComputeTaxArrByHotel: Array<TaxOutput>;
  ComputeTaxbyTaxType: HotelTaxOutput;
  GetLevyTax: CurrentTaxOutput;
  GetServiceChargeTax: CurrentTaxOutput;
  GetCurrentTourismTax: CurrentTaxOutput;
  GetPlatformTax: CurrentTaxOutput;
  GetCurrentGovTax: CurrentTaxOutput;
  TaxPolicyListing: Array<HotelTaxPolicyEntity>;
  TravelAgentListing: Array<DebtorEntity>;
  AgentInUseLisiting: Scalars['Boolean'];
  TravelAgentDetail: DebtorEntity;
  PMSTravelAgentDetail: DebtorEntity;
  TravelAgentContractListing: Array<ContractEntity>;
  TravelAgBookingListing: Array<BookingEntity>;
  TravelAgentInUseListing: Scalars['Boolean'];
  TravelAgentInUse: Scalars['Boolean'];
  TAAllotmentRoomTypeListing: Array<ContractAllotmentEntity>;
  AllotmentListing: Array<ContractAllotmentEntity>;
  loggedInDebtorProfile: DebtorProfileEntity;
  DebtorAnalysis: Array<ContractEntity>;
  TravelAgentRoomRevenue: Array<BookingEntity>;
  EntRoleUsrAsgList: Array<EntRoleUsrAsgEntity>;
  getRole: Array<RoleEntity>;
  userRoleIDs: Array<UserRoleId>;
  getContractPermission: Scalars['JSON'];
  getRolePermission: Scalars['JSON'];
  getUsersRoleContract: Array<UserEntity>;
  Check: Scalars['Boolean'];
  UserEntities: Array<Scalars['String']>;
  testPermission: Scalars['String'];
  loggedInUserProfile: UserEntity;
  activeUser: Scalars['Float'];
  getUsersByAccount: Array<UserEntity>;
  getUser: UserEntity;
  getUsersbyIds: Array<UserEntity>;
  updateLastestAccessHotel: UserEntity;
  unLockUser: Scalars['Boolean'];
  isLoginPermitted: UserEntity;
  isUserValid: Scalars['Boolean'];
  getUsersBySoftware: Array<UserEntity>;
  getSuperUsers: Array<UserEntity>;
  InterstTotal: InterestResponse;
};


export type QueryDebitNoteDetailArgs = {
  ID: Scalars['String'];
};


export type QueryDebitNoteListingsArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCreditNoteDetailArgs = {
  ID: Scalars['String'];
};


export type QueryCreditNoteListingsArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRefundDetailArgs = {
  ID: Scalars['String'];
};


export type QueryRefundListingsArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryInvoiceDetailArgs = {
  ID: Scalars['String'];
};


export type QueryInvoiceListingsArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryOfficialReceiptDetailArgs = {
  ID: Scalars['String'];
};


export type QueryOfficialReceiptListingsArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryReminderListingArgs = {
  ID: Scalars['String'];
};


export type QueryInterestListingArgs = {
  ID: Scalars['String'];
};


export type QueryDebtorListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryDebtorDetailArgs = {
  ID: Scalars['String'];
};


export type QueryInvoiceInfoArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryDebitNoteInfoArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryCreditNoteInfoArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryOfficialReceiptInfoArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryRefundInfoArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryArAccountSummaryArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryArTotalOutstandingArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryArTotalDueArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryArAgingAnalysisArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryDebtorTypeListingArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryBookingListingArgs = {
  DebtorProfileID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  DebtorType: Scalars['String'];
};


export type QueryBookingDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryAdvancePaymentListingArgs = {
  BookingID: Scalars['String'];
};


export type QueryRoomServiceBookingListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRoomNumberForFoodListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryAvailabilityListingArgs = {
  TravelAgentID?: Maybe<Scalars['String']>;
  RoomTypeIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryPackagePriceListingArgs = {
  DebtorID?: Maybe<Scalars['String']>;
  DebtorType?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  IsOnline?: Maybe<Scalars['Boolean']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryChangeOfDateCheckAvailabilityArgs = {
  Reinstate?: Maybe<Scalars['Boolean']>;
  DepartureDate: Scalars['DateTime'];
  ArrivalDate: Scalars['DateTime'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryBiBillLedgerListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTransactionCancellationArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiDepositLedgerListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiHotelListingArgs = {
  ID: Scalars['String'];
};


export type QueryBiAnalysisArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryCountItemArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiRoomRateListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  GuestID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryBiUsersLisitingArgs = {
  ID: Scalars['String'];
};


export type QueryBiInHouseArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiPaymentListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiRegistrationListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiHotelDetailsArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiRoomListingArgs = {
  RoomTypeID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryBiServiceRequestListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiForeignListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiSecurityKeyCardDepositArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiRoomDiscrepancyLogArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiRoomStatusArgs = {
  LocationID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryBiBookingStatusArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiFolioListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTestListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTransactionLedgerArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiPackageArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryManagerReportArgs = {
  EffectiveDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryManagerReportDepartmentArgs = {
  EffectiveDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryCorporateGovernmentListingArgs = {
  DebtorCategory?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryGovernmentListingArgs = {
  DebtorCategory?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryCorporateBookingListingArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCorporateGovernmentDetailArgs = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCorporateContractListingArgs = {
  DebtorID: Scalars['String'];
};


export type QueryContractDetailsArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryCorporateInUseListingArgs = {
  DebtorID: Scalars['String'];
};


export type QueryCoporateInUseListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryInHouseListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryDashBoardListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryAdvancePaymentFullListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryPaymentListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRoomServicesListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryPromotionListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryPromotionDetailsInUseListingArgs = {
  PromoCode: Scalars['String'];
  RatePolicyID: Scalars['String'];
};


export type QueryPromotionDetailListingArgs = {
  PromotionID: Scalars['String'];
};


export type QueryEmailMarketingListingArgs = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryEmailMarketingDetailArgs = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryPromotionInUseListingArgs = {
  Date: Scalars['DateTime'];
  PromoCode: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryDocumentTemplateListArgs = {
  ID: Scalars['String'];
};


export type QueryFrontDeskListingArgs = {
  GuestID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryAssignRoomFrontDeskListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryGuestProfileArgs = {
  GuestID: Scalars['String'];
};


export type QueryBookingProfileArgs = {
  ID: Scalars['String'];
};


export type QuerySelectFolioArgs = {
  ID: Scalars['String'];
};


export type QueryDepositListingByBookingArgs = {
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryUnutilizedDepositListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryUtilizedDepositListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryFrontDeskPaymentListingArgs = {
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type QueryBillingByRegistrationArgs = {
  RegistrationID: Array<Scalars['String']>;
  BookingID: Scalars['String'];
};


export type QueryIsRoomAvailableArgs = {
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
};


export type QueryFrontDeskIncidentalChargeListingArgs = {
  RegistrationID: Scalars['String'];
};


export type QueryFrontDeskServiceRequestListingArgs = {
  GuestID: Scalars['String'];
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
};


export type QueryActiveBookingListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryPrincipalGuestListingArgs = {
  BookingID: Scalars['String'];
};


export type QueryFolioListingByRegistrationArgs = {
  AccountID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
};


export type QuerySelectedRegistrationArgs = {
  ID: Scalars['String'];
};


export type QueryReceiptListingArgs = {
  PaymentClass: PaymentClass;
  ReceiptNo: Scalars['String'];
};


export type QueryPaymentDetailArgs = {
  OrderID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Array<Scalars['String']>>;
};


export type QueryAdvancePaymentDetailArgs = {
  OrderID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
};


export type QueryFrontDeskInfoLinesArgs = {
  HotelID: Scalars['String'];
};


export type QueryContactPaginationArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};


export type QueryRegistrationListingArgs = {
  RoomID: Scalars['String'];
};


export type QueryCurrentTaxDetailsArgs = {
  HotelID: Scalars['String'];
};


export type QueryHotelsByCityArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  DebtorProfileID?: Maybe<Scalars['String']>;
  NoOfRooms?: Maybe<Scalars['Float']>;
  CheckOut?: Maybe<Scalars['DateTime']>;
  CheckIn?: Maybe<Scalars['DateTime']>;
  City: Scalars['String'];
};


export type QueryHotelPricesByDateRangeArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  CheckOut?: Maybe<Scalars['DateTime']>;
  CheckIn?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryGuestBookingListingArgs = {
  GuestID: Scalars['String'];
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryDisCountByPromoCodeArgs = {
  BaseAmount: Scalars['Float'];
  RoomTypeID: Scalars['String'];
  PromoCode: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGuestRoomServiceListingArgs = {
  ServiceType?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
};


export type QueryGuestProfileListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryGuestProfileListingByIdArgs = {
  RegistrationIDs: Array<Scalars['String']>;
};


export type QuerySelectedGuestListingArgs = {
  GuestID: Scalars['String'];
};


export type QueryGuestHistoryListingArgs = {
  GuestID: Scalars['String'];
};


export type QueryGuestProfileHistoryListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryGuestProfileListbyRoomArgs = {
  BookingID: Scalars['String'];
  RoomID: Scalars['String'];
};


export type QueryRoomServiceFolioListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryPaymentRoomServiceDetailArgs = {
  RegistrationID: Scalars['String'];
  OrderID: Scalars['String'];
};


export type QueryBillLedgerFullListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryPaymentBillLedgerListingArgs = {
  FolioID: Scalars['String'];
};


export type QueryPaymentRegistrationListingArgs = {
  RegistrationID: Scalars['String'];
};


export type QueryRoomServiceListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryHousekeepingListingArgs = {
  LocationID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryTodayBookingListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryHousekeepingDetailArgs = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryHousekeepingServiceRequestListingArgs = {
  RoomID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryHousekeepingRoomStatusListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryBillLedgerListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
};


export type QueryHousekeepingIncidentalChargeListingArgs = {
  DepartmentID?: Maybe<Scalars['String']>;
};


export type QueryServiceRequestDetailArgs = {
  HotelID: Scalars['String'];
};


export type QueryServiceRequestFullListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryServiceRequestListingArgs = {
  DepartmentID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QuerySrLocationListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryDepartmentListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryDepartmentInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryIncidentalDepartmentListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryHotelListingArgs = {
  AccountID: Scalars['String'];
};


export type QueryHotelDetailsArgs = {
  OnlineBookingURL?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryIncidentalChargeListingArgs = {
  DepartmentID?: Maybe<Scalars['String']>;
};


export type QueryIncidentalChargeInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryLateCheckOutChargeListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryLocationListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryLocationInUseListingArgs = {
  LocationID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRoomInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryRatePolicyListingArgs = {
  DebtorID?: Maybe<Scalars['String']>;
  DebtorType?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryRatePolicyDetailsArgs = {
  HotelID: Scalars['String'];
  RatePolicyID: Scalars['String'];
};


export type QueryRatePolicyInUseListingArgs = {
  DebtorCategory: Scalars['String'];
  RatePolicyID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryReasonListingArgs = {
  DepartmentID?: Maybe<Scalars['String']>;
};


export type QueryCancelReasonListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryReaonInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryRoomListingArgs = {
  RoomTypeID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryBlockRoomLogListingArgs = {
  RoomID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryHotelRoomNumberListArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryRoomStatusListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRoomTypeListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryRegistrationRoomTypeListingArgs = {
  RoomTypeID: Scalars['String'];
};


export type QuerySalesChannelListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  DepartmentID: Scalars['String'];
};


export type QueryHotelSalesChannelListingArgs = {
  HotelID: Scalars['String'];
};


export type QuerySeasonCalendarListingArgs = {
  CalendarDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QuerySegmentListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QuerySegmentInUseListingArgs = {
  SegmentID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryTaxInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryComputeTaxbyHotelArgs = {
  input: TaxInput;
};


export type QueryComputeTaxArrByHotelArgs = {
  TaxInput: Array<TaxInput>;
};


export type QueryComputeTaxbyTaxTypeArgs = {
  HotelID: Scalars['String'];
  input: TaxType;
};


export type QueryGetLevyTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryGetServiceChargeTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryGetCurrentTourismTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryGetPlatformTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryGetCurrentGovTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryTaxPolicyListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryTravelAgentListingArgs = {
  DebtorCategory: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryAgentInUseLisitingArgs = {
  ID: Scalars['String'];
};


export type QueryTravelAgentDetailArgs = {
  ID: Scalars['String'];
};


export type QueryPmsTravelAgentDetailArgs = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryTravelAgentContractListingArgs = {
  DebtorID: Scalars['String'];
};


export type QueryTravelAgBookingListingArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryTravelAgentInUseListingArgs = {
  DebtorID: Scalars['String'];
};


export type QueryTravelAgentInUseArgs = {
  ID: Scalars['String'];
};


export type QueryTaAllotmentRoomTypeListingArgs = {
  ContractID: Scalars['String'];
};


export type QueryAllotmentListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  RoomTypeID: Scalars['String'];
  ContractID: Scalars['String'];
};


export type QueryDebtorAnalysisArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
};


export type QueryTravelAgentRoomRevenueArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorType: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryEntRoleUsrAsgListArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryUserRoleIDsArgs = {
  contractID: Scalars['String'];
};


export type QueryGetUsersRoleContractArgs = {
  contractID: Scalars['String'];
  roleID: Scalars['String'];
};


export type QueryCheckArgs = {
  permIds: Array<Scalars['Float']>;
  userID: Scalars['String'];
};


export type QueryUserEntitiesArgs = {
  userID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
};


export type QueryTestPermissionArgs = {
  conPerm: HotelPermission;
};


export type QueryGetUsersByAccountArgs = {
  status?: Maybe<CommonStatus>;
  superUser?: Maybe<Scalars['Boolean']>;
  LatetestUser?: Maybe<Scalars['Boolean']>;
  accountID: Scalars['String'];
};


export type QueryGetUserArgs = {
  ID: Scalars['String'];
};


export type QueryGetUsersbyIdsArgs = {
  userIDs: Array<Scalars['String']>;
};


export type QueryUpdateLastestAccessHotelArgs = {
  hotelID: Scalars['String'];
  userIDs: Scalars['String'];
};


export type QueryUnLockUserArgs = {
  password: Scalars['String'];
  accountID: Scalars['String'];
  userIDs: Scalars['String'];
};


export type QueryIsLoginPermittedArgs = {
  deviceIP: Scalars['String'];
};


export type QueryIsUserValidArgs = {
  userID: Scalars['String'];
};


/** These permissions are mostly applicable for HMS */
export enum HotelPermission {
  HomeDashBoardDueInView = 'HOME_DASH_BOARD_DUE_IN____VIEW',
  HomeDashBoardDueInRoute = 'HOME_DASH_BOARD_DUE_IN____ROUTE',
  HomeDashBoardInHouseView = 'HOME_DASH_BOARD_IN_HOUSE____VIEW',
  HomeDashBoardInHouseRoute = 'HOME_DASH_BOARD_IN_HOUSE____ROUTE',
  HomeDashBoardDueOutView = 'HOME_DASH_BOARD_DUE_OUT____VIEW',
  HomeDashBoardDueOutRoute = 'HOME_DASH_BOARD_DUE_OUT____ROUTE',
  HomeDashBoardCurrentOccupancyView = 'HOME_DASH_BOARD_CURRENT_OCCUPANCY____VIEW',
  HomeDashBoardProjectedOccupancyView = 'HOME_DASH_BOARD_PROJECTED_OCCUPANCY____VIEW',
  HomeDashBoardRevenueView = 'HOME_DASH_BOARD_REVENUE____VIEW',
  HomeDashBoardCollectionView = 'HOME_DASH_BOARD_COLLECTION____VIEW',
  HomeDashBoardServiceRequestView = 'HOME_DASH_BOARD_SERVICE_REQUEST____VIEW',
  BookingAvailabilityView = 'BOOKING_AVAILABILITY_______VIEW',
  BookingIgnoreCreate = 'BOOKING_IGNORE_______CREATE',
  BookingIgnoreView = 'BOOKING_IGNORE_______VIEW',
  BookingBookingInfoEdit = 'BOOKING_BOOKING_INFO_______EDIT',
  BookingRoomInfoEdit = 'BOOKING_ROOM_INFO_______EDIT',
  BookingSpecialRequestEdit = 'BOOKING_SPECIAL_REQUEST_______EDIT',
  BookingAdvancePaymentView = 'BOOKING_ADVANCE_PAYMENT_______VIEW',
  BookingAdvancePaymentStatementView = 'BOOKING_ADVANCE_PAYMENT_STATEMENT____VIEW',
  BookingAdvancePaymentCreate = 'BOOKING_ADVANCE_PAYMENT_______CREATE',
  BookingAdvancePaymentEdit = 'BOOKING_ADVANCE_PAYMENT_______EDIT',
  BookingBillingInstructionsEdit = 'BOOKING_BILLING_INSTRUCTIONS_______EDIT',
  BookingConfirmationLetterEdit = 'BOOKING_CONFIRMATION_LETTER_______EDIT',
  BookingCancelBookingEdit = 'BOOKING_CANCEL_BOOKING_______EDIT',
  FrontDeskDueInInfoView = 'FRONT_DESK_DUE_IN_INFO____VIEW',
  FrontDeskWalkInInfoView = 'FRONT_DESK_WALK_IN_INFO____VIEW',
  FrontDeskDueOutInfoView = 'FRONT_DESK_DUE_OUT_INFO____VIEW',
  FrontDeskRoomAssignmentInfoView = 'FRONT_DESK_ROOM_ASSIGNMENT_INFO____VIEW',
  FrontDeskInhouseGuestInfoView = 'FRONT_DESK_INHOUSE_GUEST_INFO____VIEW',
  FrontDeskDepositInfoView = 'FRONT_DESK_DEPOSIT_INFO____VIEW',
  FrontDeskServiceRequestInfoView = 'FRONT_DESK_SERVICE_REQUEST_INFO____VIEW',
  FrontDeskDueInListView = 'FRONT_DESK_DUE_IN_LIST____VIEW',
  FrontDeskDueInListCheckInView = 'FRONT_DESK_DUE_IN_LIST_CHECK_IN_VIEW',
  FrontDeskDueInListChangeRoomView = 'FRONT_DESK_DUE_IN_LIST_CHANGE_ROOM_VIEW',
  FrontDeskDueInListAssignRoomEdit = 'FRONT_DESK_DUE_IN_LIST_ASSIGN_ROOM_EDIT',
  FrontDeskDueInListAddGuestView = 'FRONT_DESK_DUE_IN_LIST_ADD_GUEST_VIEW',
  FrontDeskDueInListBookingInfoEdit = 'FRONT_DESK_DUE_IN_LIST_BOOKING_INFO_EDIT',
  FrontDeskDueInListIncidentalChargesView = 'FRONT_DESK_DUE_IN_LIST_INCIDENTAL_CHARGES_VIEW',
  FrontDeskDueInListIncidentalChargesCreate = 'FRONT_DESK_DUE_IN_LIST_INCIDENTAL_CHARGES_CREATE',
  FrontDeskDueInListIncidentalChargesVoid = 'FRONT_DESK_DUE_IN_LIST_INCIDENTAL_CHARGES_VOID',
  FrontDeskDueInListDepositView = 'FRONT_DESK_DUE_IN_LIST_DEPOSIT_VIEW',
  FrontDeskDueInListDepositCreate = 'FRONT_DESK_DUE_IN_LIST_DEPOSIT_CREATE',
  FrontDeskDueInListDepositRefund = 'FRONT_DESK_DUE_IN_LIST_DEPOSIT_REFUND',
  FrontDeskDueInListPaymentView = 'FRONT_DESK_DUE_IN_LIST_PAYMENT_VIEW',
  FrontDeskDueInListPaymentPerform = 'FRONT_DESK_DUE_IN_LIST_PAYMENT_PERFORM',
  FrontDeskWalkInListView = 'FRONT_DESK_WALK_IN_LIST____VIEW',
  FrontDeskWalkInListCreate = 'FRONT_DESK_WALK_IN_LIST____CREATE',
  FrontDeskWalkInListAddGuestCreate = 'FRONT_DESK_WALK_IN_LIST_ADD_GUEST_CREATE',
  FrontDeskWalkInListServiceRequestView = 'FRONT_DESK_WALK_IN_LIST_SERVICE_REQUEST_VIEW',
  FrontDeskWalkInListServiceRequestCreate = 'FRONT_DESK_WALK_IN_LIST_SERVICE_REQUEST_CREATE',
  FrontDeskWalkInListServiceRequestEdit = 'FRONT_DESK_WALK_IN_LIST_SERVICE_REQUEST_EDIT',
  FrontDeskWalkInListDepositView = 'FRONT_DESK_WALK_IN_LIST_DEPOSIT_VIEW',
  FrontDeskWalkInListDepositCreate = 'FRONT_DESK_WALK_IN_LIST_DEPOSIT_CREATE',
  FrontDeskWalkInListDepositRefund = 'FRONT_DESK_WALK_IN_LIST_DEPOSIT_REFUND',
  FrontDeskWalkInListIncidentalChargesView = 'FRONT_DESK_WALK_IN_LIST_INCIDENTAL_CHARGES_VIEW',
  FrontDeskWalkInListIncidentalChargesCreate = 'FRONT_DESK_WALK_IN_LIST_INCIDENTAL_CHARGES_CREATE',
  FrontDeskWalkInListIncidentalChargesVoid = 'FRONT_DESK_WALK_IN_LIST_INCIDENTAL_CHARGES_VOID',
  FrontDeskWalkInListPaymentView = 'FRONT_DESK_WALK_IN_LIST_PAYMENT_VIEW',
  FrontDeskWalkInListPaymentPerform = 'FRONT_DESK_WALK_IN_LIST_PAYMENT_PERFORM',
  FrontDeskDueOutListDepositView = 'FRONT_DESK_DUE_OUT_LIST_DEPOSIT_VIEW',
  FrontDeskDueOutListIncidentalChargesView = 'FRONT_DESK_DUE_OUT_LIST_INCIDENTAL_CHARGES_VIEW',
  FrontDeskDueOutListIncidentalChargesCreate = 'FRONT_DESK_DUE_OUT_LIST_INCIDENTAL_CHARGES_CREATE',
  FrontDeskDueOutListIncidentalChargesVoid = 'FRONT_DESK_DUE_OUT_LIST_INCIDENTAL_CHARGES_VOID',
  FrontDeskDueOutListCheckOutView = 'FRONT_DESK_DUE_OUT_LIST_CHECK_OUT_VIEW',
  FrontDeskDueOutListCheckOutPerform = 'FRONT_DESK_DUE_OUT_LIST_CHECK_OUT_PERFORM',
  FrontDeskDueOutListFolioHistoryView = 'FRONT_DESK_DUE_OUT_LIST_FOLIO_HISTORY_VIEW',
  FrontDeskRoomAssignmentListView = 'FRONT_DESK_ROOM_ASSIGNMENT_LIST____VIEW',
  FrontDeskRoomAssignmentListAssignRoomEdit = 'FRONT_DESK_ROOM_ASSIGNMENT_LIST_ASSIGN_ROOM_EDIT',
  FrontDeskInhouseGuestListView = 'FRONT_DESK_INHOUSE_GUEST_LIST____VIEW',
  FrontDeskInhouseGuestListAddGuestView = 'FRONT_DESK_INHOUSE_GUEST_LIST_ADD_GUEST_VIEW',
  FrontDeskInhouseGuestListServiceRequestView = 'FRONT_DESK_INHOUSE_GUEST_LIST_SERVICE_REQUEST_VIEW',
  FrontDeskInhouseGuestListServiceRequestCreate = 'FRONT_DESK_INHOUSE_GUEST_LIST_SERVICE_REQUEST_CREATE',
  FrontDeskInhouseGuestListServiceRequestEdit = 'FRONT_DESK_INHOUSE_GUEST_LIST_SERVICE_REQUEST_EDIT',
  FrontDeskInhouseGuestListIncidentalChargesView = 'FRONT_DESK_INHOUSE_GUEST_LIST_INCIDENTAL_CHARGES_VIEW',
  FrontDeskInhouseGuestListIncidentalChargesCreate = 'FRONT_DESK_INHOUSE_GUEST_LIST_INCIDENTAL_CHARGES_CREATE',
  FrontDeskInhouseGuestListIncidentalChargesVoid = 'FRONT_DESK_INHOUSE_GUEST_LIST_INCIDENTAL_CHARGES_VOID',
  FrontDeskInhouseGuestListPaymentView = 'FRONT_DESK_INHOUSE_GUEST_LIST_PAYMENT_VIEW',
  FrontDeskInhouseGuestListPaymentPerform = 'FRONT_DESK_INHOUSE_GUEST_LIST_PAYMENT_PERFORM',
  FrontDeskInhouseGuestListEarlyCheckoutView = 'FRONT_DESK_INHOUSE_GUEST_LIST_EARLY_CHECKOUT_VIEW',
  FrontDeskInhouseGuestListLateCheckoutView = 'FRONT_DESK_INHOUSE_GUEST_LIST_LATE_CHECKOUT_VIEW',
  FrontDeskInhouseGuestListRoomTransferView = 'FRONT_DESK_INHOUSE_GUEST_LIST_ROOM_TRANSFER_VIEW',
  FrontDeskInhouseGuestListRoomUpgradeView = 'FRONT_DESK_INHOUSE_GUEST_LIST_ROOM_UPGRADE_VIEW',
  FrontDeskInhouseGuestListExtendStayView = 'FRONT_DESK_INHOUSE_GUEST_LIST_EXTEND_STAY_VIEW',
  FrontDeskDepositListView = 'FRONT_DESK_DEPOSIT_LIST____VIEW',
  FrontDeskDepositListCreate = 'FRONT_DESK_DEPOSIT_LIST____CREATE',
  FrontDeskDepositListRefund = 'FRONT_DESK_DEPOSIT_LIST____REFUND',
  FrontDeskServiceRequestRoomRevenueView = 'FRONT_DESK_SERVICE_REQUEST_ROOM_REVENUE____VIEW',
  FrontDeskServiceRequestRoomRevenueCreate = 'FRONT_DESK_SERVICE_REQUEST_ROOM_REVENUE____CREATE',
  FrontDeskServiceRequestRoomRevenueEdit = 'FRONT_DESK_SERVICE_REQUEST_ROOM_REVENUE____EDIT',
  FrontDeskServiceRequestHouseKeepingView = 'FRONT_DESK_SERVICE_REQUEST_HOUSE_KEEPING____VIEW',
  FrontDeskServiceRequestHouseKeepingCreate = 'FRONT_DESK_SERVICE_REQUEST_HOUSE_KEEPING____CREATE',
  FrontDeskServiceRequestHouseKeepingEdit = 'FRONT_DESK_SERVICE_REQUEST_HOUSE_KEEPING____EDIT',
  FrontDeskServiceRequestSalesMarketingView = 'FRONT_DESK_SERVICE_REQUEST_SALES_MARKETING____VIEW',
  FrontDeskServiceRequestSalesMarketingCreate = 'FRONT_DESK_SERVICE_REQUEST_SALES_MARKETING____CREATE',
  FrontDeskServiceRequestSalesMarketingEdit = 'FRONT_DESK_SERVICE_REQUEST_SALES_MARKETING____EDIT',
  FrontDeskServiceRequestMaintenanceView = 'FRONT_DESK_SERVICE_REQUEST_MAINTENANCE____VIEW',
  FrontDeskServiceRequestMaintenanceCreate = 'FRONT_DESK_SERVICE_REQUEST_MAINTENANCE____CREATE',
  FrontDeskServiceRequestMaintenanceEdit = 'FRONT_DESK_SERVICE_REQUEST_MAINTENANCE____EDIT',
  HouseKeepingRoomManagementView = 'HOUSE_KEEPING_ROOM_MANAGEMENT_______VIEW',
  HouseKeepingRoomManagementBlockRoomEdit = 'HOUSE_KEEPING_ROOM_MANAGEMENT_BLOCK_ROOM____EDIT',
  HouseKeepingRoomManagementRoomStatusEdit = 'HOUSE_KEEPING_ROOM_MANAGEMENT_ROOM_STATUS____EDIT',
  HouseKeepingRoomManagementIncidentalChargesView = 'HOUSE_KEEPING_ROOM_MANAGEMENT_INCIDENTAL_CHARGES____VIEW',
  HouseKeepingRoomManagementIncidentalChargesCreate = 'HOUSE_KEEPING_ROOM_MANAGEMENT_INCIDENTAL_CHARGES____CREATE',
  HouseKeepingRoomManagementIncidentalChargesVoid = 'HOUSE_KEEPING_ROOM_MANAGEMENT_INCIDENTAL_CHARGES____VOID',
  HouseKeepingRoomManagementServiceRequestView = 'HOUSE_KEEPING_ROOM_MANAGEMENT_SERVICE_REQUEST____VIEW',
  HouseKeepingRoomManagementServiceRequestCreate = 'HOUSE_KEEPING_ROOM_MANAGEMENT_SERVICE_REQUEST____CREATE',
  HouseKeepingRoomManagementServiceRequestEdit = 'HOUSE_KEEPING_ROOM_MANAGEMENT_SERVICE_REQUEST____EDIT',
  HouseKeepingMaidManagementMaidDutyView = 'HOUSE_KEEPING_MAID_MANAGEMENT_MAID_DUTY____VIEW',
  HouseKeepingMaidManagementMaidDutyCreate = 'HOUSE_KEEPING_MAID_MANAGEMENT_MAID_DUTY____CREATE',
  HouseKeepingMaidManagementMaidAssignmentView = 'HOUSE_KEEPING_MAID_MANAGEMENT_MAID_ASSIGNMENT____VIEW',
  HouseKeepingMaidManagementMaidAssignmentCreate = 'HOUSE_KEEPING_MAID_MANAGEMENT_MAID_ASSIGNMENT____CREATE',
  HouseKeepingMaidManagementMaidAssignmentEdit = 'HOUSE_KEEPING_MAID_MANAGEMENT_MAID_ASSIGNMENT____EDIT',
  RoomServiceIgnoreView = 'ROOM_SERVICE_IGNORE_______VIEW',
  RoomServiceIgnoreCreate = 'ROOM_SERVICE_IGNORE_______CREATE',
  RoomServiceIgnoreEdit = 'ROOM_SERVICE_IGNORE_______EDIT',
  ServiceRequestRoomRevenueView = 'SERVICE_REQUEST_ROOM_REVENUE_______VIEW',
  ServiceRequestRoomRevenueCreate = 'SERVICE_REQUEST_ROOM_REVENUE_______CREATE',
  ServiceRequestRoomRevenueEdit = 'SERVICE_REQUEST_ROOM_REVENUE_______EDIT',
  ServiceRequestHouseKeepingView = 'SERVICE_REQUEST_HOUSE_KEEPING_______VIEW',
  ServiceRequestHouseKeepingCreate = 'SERVICE_REQUEST_HOUSE_KEEPING_______CREATE',
  ServiceRequestHouseKeepingEdit = 'SERVICE_REQUEST_HOUSE_KEEPING_______EDIT',
  ServiceRequestSalesMarketingView = 'SERVICE_REQUEST_SALES_MARKETING_______VIEW',
  ServiceRequestSalesMarketingCreate = 'SERVICE_REQUEST_SALES_MARKETING_______CREATE',
  ServiceRequestSalesMarketingEdit = 'SERVICE_REQUEST_SALES_MARKETING_______EDIT',
  ServiceRequestMaintenanceView = 'SERVICE_REQUEST_MAINTENANCE_______VIEW',
  ServiceRequestMaintenanceCreate = 'SERVICE_REQUEST_MAINTENANCE_______CREATE',
  ServiceRequestMaintenanceEdit = 'SERVICE_REQUEST_MAINTENANCE_______EDIT',
  PackagesRedemptionIgnoreView = 'PACKAGES_REDEMPTION_IGNORE_______VIEW',
  CorpGovtPrivateCorporationView = 'CORP_GOVT_PRIVATE_CORPORATION_______VIEW',
  CorpGovtPrivateCorporationCreate = 'CORP_GOVT_PRIVATE_CORPORATION_______CREATE',
  CorpGovtPrivateCorporationCompanyInfoEdit = 'CORP_GOVT_PRIVATE_CORPORATION_COMPANY_INFO____EDIT',
  CorpGovtPrivateCorporationContractsView = 'CORP_GOVT_PRIVATE_CORPORATION_CONTRACTS____VIEW',
  CorpGovtPrivateCorporationContractsEdit = 'CORP_GOVT_PRIVATE_CORPORATION_CONTRACTS____EDIT',
  CorpGovtPrivateCorporationBookingRecordsView = 'CORP_GOVT_PRIVATE_CORPORATION_BOOKING_RECORDS____VIEW',
  CorpGovtPrivateCorporationAnalysisView = 'CORP_GOVT_PRIVATE_CORPORATION_ANALYSIS____VIEW',
  CorpGovtGovtBodiesCompanyInfoEdit = 'CORP_GOVT_GOVT_BODIES_COMPANY_INFO____EDIT',
  CorpGovtGovtBodiesContractsView = 'CORP_GOVT_GOVT_BODIES_CONTRACTS____VIEW',
  CorpGovtGovtBodiesContractsCreate = 'CORP_GOVT_GOVT_BODIES_CONTRACTS____CREATE',
  CorpGovtGovtBodiesBookingRecordsView = 'CORP_GOVT_GOVT_BODIES_BOOKING_RECORDS____VIEW',
  CorpGovtGovtBodiesAnalysisView = 'CORP_GOVT_GOVT_BODIES_ANALYSIS____VIEW',
  CorpGovtRatePolicyView = 'CORP_GOVT_RATE_POLICY_______VIEW',
  CorpGovtRatePolicyCreate = 'CORP_GOVT_RATE_POLICY_______CREATE',
  CorpGovtRatePolicyEdit = 'CORP_GOVT_RATE_POLICY_______EDIT',
  TravelAgentAgentListingView = 'TRAVEL_AGENT_AGENT_LISTING_______VIEW',
  TravelAgentAgentListingCreate = 'TRAVEL_AGENT_AGENT_LISTING_______CREATE',
  TravelAgentAgentListingCompanyInfoEdit = 'TRAVEL_AGENT_AGENT_LISTING_COMPANY_INFO____EDIT',
  TravelAgentAgentListingContractsView = 'TRAVEL_AGENT_AGENT_LISTING_CONTRACTS____VIEW',
  TravelAgentAgentListingContractsCreate = 'TRAVEL_AGENT_AGENT_LISTING_CONTRACTS____CREATE',
  TravelAgentAgentListingBookingRecordsView = 'TRAVEL_AGENT_AGENT_LISTING_BOOKING_RECORDS____VIEW',
  TravelAgentAgentListingAnalysisView = 'TRAVEL_AGENT_AGENT_LISTING_ANALYSIS____VIEW',
  TravelAgentRatePolicyView = 'TRAVEL_AGENT_RATE_POLICY_______VIEW',
  TravelAgentRatePolicyEdit = 'TRAVEL_AGENT_RATE_POLICY_______EDIT',
  TravelAgentRatePolicyCreate = 'TRAVEL_AGENT_RATE_POLICY_______CREATE',
  GuestProfileIgnoreView = 'GUEST_PROFILE_IGNORE_______VIEW',
  GuestProfileIgnoreEdit = 'GUEST_PROFILE_IGNORE_______EDIT',
  DigitalMarketingPromotionsView = 'DIGITAL_MARKETING_PROMOTIONS_______VIEW',
  DigitalMarketingPromotionsCreate = 'DIGITAL_MARKETING_PROMOTIONS_______CREATE',
  DigitalMarketingPromotionsEdit = 'DIGITAL_MARKETING_PROMOTIONS_______EDIT',
  DigitalMarketingEmailMarketingView = 'DIGITAL_MARKETING_EMAIL_MARKETING_______VIEW',
  DigitalMarketingEmailMarketingCreate = 'DIGITAL_MARKETING_EMAIL_MARKETING_______CREATE',
  DigitalMarketingEmailMarketingEdit = 'DIGITAL_MARKETING_EMAIL_MARKETING_______EDIT',
  BusinessInsightAnalyticsView = 'BUSINESS_INSIGHT_ANALYTICS_______VIEW',
  BusinessInsightDigitalMarketingCashierSummaryView = 'BUSINESS_INSIGHT_DIGITAL_MARKETING_CASHIER_SUMMARY____VIEW',
  BusinessInsightDigitalMarketingTransactionLedgerView = 'BUSINESS_INSIGHT_DIGITAL_MARKETING_TRANSACTION_LEDGER____VIEW',
  BusinessInsightDigitalMarketingBookingStatusView = 'BUSINESS_INSIGHT_DIGITAL_MARKETING_BOOKING_STATUS____VIEW',
  BusinessInsightDigitalMarketingManagerReportView = 'BUSINESS_INSIGHT_DIGITAL_MARKETING_MANAGER_REPORT____VIEW',
  BusinessInsightDigitalMarketingPackageListingView = 'BUSINESS_INSIGHT_DIGITAL_MARKETING_PACKAGE_LISTING____VIEW',
  BusinessInsightDigitalMarketingRoomStatusView = 'BUSINESS_INSIGHT_DIGITAL_MARKETING_ROOM_STATUS____VIEW',
  BusinessInsightDigitalMarketingInhouseGuestView = 'BUSINESS_INSIGHT_DIGITAL_MARKETING_INHOUSE_GUEST____VIEW',
  BusinessInsightDigitalMarketingSecurityDepositView = 'BUSINESS_INSIGHT_DIGITAL_MARKETING_SECURITY_DEPOSIT____VIEW',
  BusinessInsightDigitalMarketingRoomDiscrepancyView = 'BUSINESS_INSIGHT_DIGITAL_MARKETING_ROOM_DISCREPANCY____VIEW',
  BusinessInsightDigitalMarketingTourismTaxView = 'BUSINESS_INSIGHT_DIGITAL_MARKETING_TOURISM_TAX____VIEW',
  BusinessInsightDigitalMarketingTransactionCancellationView = 'BUSINESS_INSIGHT_DIGITAL_MARKETING_TRANSACTION_CANCELLATION____VIEW',
  BusinessInsightDigitalMarketingServiceRequestView = 'BUSINESS_INSIGHT_DIGITAL_MARKETING_SERVICE_REQUEST____VIEW',
  BusinessInsightDigitalDocumentRegistrationCardView = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_REGISTRATION_CARD____VIEW',
  BusinessInsightDigitalDocumentFolioView = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_FOLIO____VIEW',
  BusinessInsightDigitalDocumentReceiptView = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_RECEIPT____VIEW',
  HotelSettingsHotelView = 'HOTEL_SETTINGS_HOTEL_______VIEW',
  HotelSettingsHotelCreate = 'HOTEL_SETTINGS_HOTEL_______CREATE',
  HotelSettingsHotelHotelSetupProfileEdit = 'HOTEL_SETTINGS_HOTEL_HOTEL_SETUP_PROFILE_EDIT',
  HotelSettingsHotelHotelSetupAboutEdit = 'HOTEL_SETTINGS_HOTEL_HOTEL_SETUP_ABOUT_EDIT',
  HotelSettingsHotelHotelSetupFacilitiesView = 'HOTEL_SETTINGS_HOTEL_HOTEL_SETUP_FACILITIES_VIEW',
  HotelSettingsHotelHotelSetupFacilitiesCreate = 'HOTEL_SETTINGS_HOTEL_HOTEL_SETUP_FACILITIES_CREATE',
  HotelSettingsHotelHotelSetupFacilitiesEdit = 'HOTEL_SETTINGS_HOTEL_HOTEL_SETUP_FACILITIES_EDIT',
  HotelSettingsHotelDepartmentView = 'HOTEL_SETTINGS_HOTEL_DEPARTMENT____VIEW',
  HotelSettingsHotelDepartmentCreate = 'HOTEL_SETTINGS_HOTEL_DEPARTMENT____CREATE',
  HotelSettingsHotelDepartmentDelete = 'HOTEL_SETTINGS_HOTEL_DEPARTMENT____DELETE',
  HotelSettingsHotelDepartmentEdit = 'HOTEL_SETTINGS_HOTEL_DEPARTMENT____EDIT',
  HotelSettingsHotelDepartmentIncidentalChargesView = 'HOTEL_SETTINGS_HOTEL_DEPARTMENT_INCIDENTAL_CHARGES_VIEW',
  HotelSettingsHotelDepartmentIncidentalChargesCreate = 'HOTEL_SETTINGS_HOTEL_DEPARTMENT_INCIDENTAL_CHARGES_CREATE',
  HotelSettingsHotelDepartmentIncidentalChargesEdit = 'HOTEL_SETTINGS_HOTEL_DEPARTMENT_INCIDENTAL_CHARGES_EDIT',
  HotelSettingsHotelDepartmentMaidView = 'HOTEL_SETTINGS_HOTEL_DEPARTMENT_MAID_VIEW',
  HotelSettingsHotelDepartmentMaidCreate = 'HOTEL_SETTINGS_HOTEL_DEPARTMENT_MAID_CREATE',
  HotelSettingsHotelLocationView = 'HOTEL_SETTINGS_HOTEL_LOCATION____VIEW',
  HotelSettingsHotelLocationEdit = 'HOTEL_SETTINGS_HOTEL_LOCATION____EDIT',
  HotelSettingsHotelLocationCreate = 'HOTEL_SETTINGS_HOTEL_LOCATION____CREATE',
  HotelSettingsHotelRoomTypeView = 'HOTEL_SETTINGS_HOTEL_ROOM_TYPE____VIEW',
  HotelSettingsHotelRoomTypeCreate = 'HOTEL_SETTINGS_HOTEL_ROOM_TYPE____CREATE',
  HotelSettingsHotelRoomTypeEdit = 'HOTEL_SETTINGS_HOTEL_ROOM_TYPE____EDIT',
  HotelSettingsHotelRoomView = 'HOTEL_SETTINGS_HOTEL_ROOM____VIEW',
  HotelSettingsHotelRoomCreate = 'HOTEL_SETTINGS_HOTEL_ROOM____CREATE',
  HotelSettingsHotelRoomEdit = 'HOTEL_SETTINGS_HOTEL_ROOM____EDIT',
  HotelSettingsHotelRoomStatusView = 'HOTEL_SETTINGS_HOTEL_ROOM_STATUS____VIEW',
  HotelSettingsHotelRoomStatusEdit = 'HOTEL_SETTINGS_HOTEL_ROOM_STATUS____EDIT',
  HotelSettingsHotelPeakSeasonCalendarView = 'HOTEL_SETTINGS_HOTEL_PEAK_SEASON_CALENDAR____VIEW',
  HotelSettingsHotelPeakSeasonCalendarEdit = 'HOTEL_SETTINGS_HOTEL_PEAK_SEASON_CALENDAR____EDIT',
  HotelSettingsHotelRatePolicyView = 'HOTEL_SETTINGS_HOTEL_RATE_POLICY____VIEW',
  HotelSettingsHotelRatePolicyCreate = 'HOTEL_SETTINGS_HOTEL_RATE_POLICY____CREATE',
  HotelSettingsHotelRatePolicyEdit = 'HOTEL_SETTINGS_HOTEL_RATE_POLICY____EDIT',
  HotelSettingsHotelStandardPolicyGuidelineView = 'HOTEL_SETTINGS_HOTEL_STANDARD_POLICY_GUIDELINE____VIEW',
  HotelSettingsHotelStandardPolicyGuidelineHotelOperationView = 'HOTEL_SETTINGS_HOTEL_STANDARD_POLICY_GUIDELINE_HOTEL_OPERATION_VIEW',
  HotelSettingsHotelStandardPolicyGuidelineHotelOperationEdit = 'HOTEL_SETTINGS_HOTEL_STANDARD_POLICY_GUIDELINE_HOTEL_OPERATION_EDIT',
  HotelSettingsHotelStandardPolicyGuidelineSecurityDepositView = 'HOTEL_SETTINGS_HOTEL_STANDARD_POLICY_GUIDELINE_SECURITY_DEPOSIT_VIEW',
  HotelSettingsHotelStandardPolicyGuidelineSecurityDepositEdit = 'HOTEL_SETTINGS_HOTEL_STANDARD_POLICY_GUIDELINE_SECURITY_DEPOSIT_EDIT',
  HotelSettingsHotelStandardPolicyGuidelineRoomStatusView = 'HOTEL_SETTINGS_HOTEL_STANDARD_POLICY_GUIDELINE_ROOM_STATUS_VIEW',
  HotelSettingsHotelStandardPolicyGuidelineRoomStatusEdit = 'HOTEL_SETTINGS_HOTEL_STANDARD_POLICY_GUIDELINE_ROOM_STATUS_EDIT',
  HotelSettingsHotelStandardPolicyGuidelineCellingFloorPricingView = 'HOTEL_SETTINGS_HOTEL_STANDARD_POLICY_GUIDELINE_CELLING_FLOOR_PRICING_VIEW',
  HotelSettingsHotelStandardPolicyGuidelineCellingFloorPricingEdit = 'HOTEL_SETTINGS_HOTEL_STANDARD_POLICY_GUIDELINE_CELLING_FLOOR_PRICING_EDIT',
  HotelSettingsHotelStandardPolicyGuidelineTaxServiceChargeView = 'HOTEL_SETTINGS_HOTEL_STANDARD_POLICY_GUIDELINE_TAX_SERVICE_CHARGE_VIEW',
  HotelSettingsStandardPolicyGuidelineTaxServiceChargeTaxView = 'HOTEL_SETTINGS_STANDARD_POLICY_GUIDELINE_TAX_SERVICE_CHARGE_TAX_VIEW',
  HotelSettingsStandardPolicyGuidelineTaxServiceChargeTaxEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_GUIDELINE_TAX_SERVICE_CHARGE_TAX_EDIT',
  HotelSettingsStandardPolicyGuidelineTaxServiceChargeServiceChargeView = 'HOTEL_SETTINGS_STANDARD_POLICY_GUIDELINE_TAX_SERVICE_CHARGE_SERVICE_CHARGE_VIEW',
  HotelSettingsStandardPolicyGuidelineTaxServiceChargeServiceChargeCreate = 'HOTEL_SETTINGS_STANDARD_POLICY_GUIDELINE_TAX_SERVICE_CHARGE_SERVICE_CHARGE_CREATE',
  HotelSettingsStandardPolicyGuidelineTaxServiceChargeServiceChargeEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_GUIDELINE_TAX_SERVICE_CHARGE_SERVICE_CHARGE_EDIT',
  HotelSettingsStandardPolicyGuidelineTaxServiceChargeLevyHeritageView = 'HOTEL_SETTINGS_STANDARD_POLICY_GUIDELINE_TAX_SERVICE_CHARGE_LEVY_HERITAGE_VIEW',
  HotelSettingsStandardPolicyGuidelineTaxServiceChargeLevyHeritageCreate = 'HOTEL_SETTINGS_STANDARD_POLICY_GUIDELINE_TAX_SERVICE_CHARGE_LEVY_HERITAGE_CREATE',
  HotelSettingsStandardPolicyGuidelineTaxServiceChargeLevyHeritageEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_GUIDELINE_TAX_SERVICE_CHARGE_LEVY_HERITAGE_EDIT',
  HotelSettingsStandardPolicyGuidelineTaxServiceChargeTourismView = 'HOTEL_SETTINGS_STANDARD_POLICY_GUIDELINE_TAX_SERVICE_CHARGE_TOURISM_VIEW',
  HotelSettingsStandardPolicyGuidelineTaxServiceChargeTourismEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_GUIDELINE_TAX_SERVICE_CHARGE_TOURISM_EDIT',
  HotelSettingsStandardPolicyGuidelineTaxServiceChargeTourismCreate = 'HOTEL_SETTINGS_STANDARD_POLICY_GUIDELINE_TAX_SERVICE_CHARGE_TOURISM_CREATE',
  HotelSettingsHotelLateCheckoutChargesView = 'HOTEL_SETTINGS_HOTEL_LATE_CHECKOUT_CHARGES____VIEW',
  HotelSettingsHotelLateCheckoutChargesEdit = 'HOTEL_SETTINGS_HOTEL_LATE_CHECKOUT_CHARGES____EDIT',
  HotelSettingsHotelSegmentView = 'HOTEL_SETTINGS_HOTEL_SEGMENT____VIEW',
  HotelSettingsHotelSegmentEdit = 'HOTEL_SETTINGS_HOTEL_SEGMENT____EDIT',
  HotelSettingsHotelSegmentCreate = 'HOTEL_SETTINGS_HOTEL_SEGMENT____CREATE',
  HotelSettingsHotelGlInterfaceChargesView = 'HOTEL_SETTINGS_HOTEL_GL_INTERFACE_CHARGES_VIEW',
  HotelSettingsHotelGlInterfaceChargesEdit = 'HOTEL_SETTINGS_HOTEL_GL_INTERFACE_CHARGES_EDIT',
  HotelSettingsHotelGlInterfacePaymentEdit = 'HOTEL_SETTINGS_HOTEL_GL_INTERFACE_PAYMENT_EDIT',
  HotelSettingsHotelGlInterfacePaymentView = 'HOTEL_SETTINGS_HOTEL_GL_INTERFACE_PAYMENT_VIEW',
  HotelSettingsHotelGlInterfaceRefundEdit = 'HOTEL_SETTINGS_HOTEL_GL_INTERFACE_REFUND_EDIT',
  HotelSettingsHotelGlInterfaceRefundView = 'HOTEL_SETTINGS_HOTEL_GL_INTERFACE_REFUND_VIEW',
  HotelSettingsHotelGlInterfaceDepositView = 'HOTEL_SETTINGS_HOTEL_GL_INTERFACE_DEPOSIT_VIEW',
  HotelSettingsHotelGlInterfaceDepositEdit = 'HOTEL_SETTINGS_HOTEL_GL_INTERFACE_DEPOSIT_EDIT',
  GlInterfaceIgnoreView = 'GL_INTERFACE_IGNORE_______VIEW',
  SystemAdminIgnoreView = 'SYSTEM_ADMIN_IGNORE_______VIEW',
  SystemAdminUsersCreate = 'SYSTEM_ADMIN_USERS_______CREATE',
  SystemAdminUsersEdit = 'SYSTEM_ADMIN_USERS_______EDIT',
  SystemAdminUsersView = 'SYSTEM_ADMIN_USERS_______VIEW'
}

export type Mutation = {
  __typename?: 'Mutation';
  DebitNoteInsert: DebitNoteEntity;
  DebitNoteUpdate: Scalars['Boolean'];
  DebitNoteCancel: Scalars['Boolean'];
  AllocateDebitNote: Scalars['Boolean'];
  DebitNoteDelete: Scalars['Boolean'];
  CreditNoteInsert: CreditNoteEntity;
  CreditNoteUpdate: Scalars['Boolean'];
  CreditNoteCancel: Scalars['Boolean'];
  AllocateCreditNote: Scalars['Boolean'];
  RefundInsert: RefundEntity;
  RefundUpdate: Scalars['Boolean'];
  RefundCancel: Scalars['Boolean'];
  AllocateRefund: Scalars['Boolean'];
  InvoiceInsert: InvoiceEntity;
  InvoiceUpdate: Scalars['Boolean'];
  InvoiceCancel: Scalars['Boolean'];
  AllocateInvoice: Scalars['Boolean'];
  OfficialReceiptInsert: OfficialReceiptEntity;
  OfficialReceiptUpdate: Scalars['Boolean'];
  OfficialReceiptCancel: Scalars['Boolean'];
  AllocateOfficialReceipt: Scalars['Boolean'];
  ReminderInsert: ReminderEntity;
  ReminderUpdate: Scalars['Boolean'];
  ReminderDelete: Scalars['Boolean'];
  asyncInterestInsert: InterestEntity;
  InterestUpdate: Scalars['Boolean'];
  InterestDelete: Scalars['Boolean'];
  DebtorTypeInsert: DebtorTypeEntity;
  DebtorTypeUpdate: Scalars['Boolean'];
  DebtorTypeDelete: Scalars['Boolean'];
  BookingInsert: Scalars['String'];
  BookingRoomTypeUpdate: Scalars['Boolean'];
  BookingUpdate: Scalars['Boolean'];
  CancelBooking: Scalars['Boolean'];
  AdvancePaymentInsert: AdvancePaymentEntity;
  AdvancePaymentRefund: Scalars['Boolean'];
  AdvancePaymentUpdate: Scalars['Boolean'];
  ReinstateBooking: Scalars['Boolean'];
  ManualNightAudit: Scalars['Boolean'];
  AvailabilityUpdate: Scalars['Boolean'];
  UploadImage: Array<Scalars['String']>;
  DeleteImage: Scalars['Boolean'];
  BISharedGAlleryInsert: SharedGalleryEntity;
  CorporateInsert: DebtorEntity;
  GovermentTestingUpdate: Scalars['Boolean'];
  CorporateUpdate: Scalars['Boolean'];
  CorporateContractUpdate: Scalars['Boolean'];
  CorporateContractInsert: ContractEntity;
  CorporateDelete: Scalars['Boolean'];
  PromotionUpdate: Scalars['Boolean'];
  PromotionInsert: PromotionEntity;
  PromotionActiveUpdate: Scalars['Boolean'];
  PromotionDetailUpdate: Scalars['Boolean'];
  PromotionDetailInsert: PromotionDetailEntity;
  EmailCampignUpdate: Scalars['Boolean'];
  EmailCampignInsert: EmailCampignEntity;
  EmailCampaign: Scalars['Boolean'];
  PromotionDelete: Scalars['Boolean'];
  EmailDelete: Scalars['Boolean'];
  AssignRoom: Scalars['Boolean'];
  CheckIn: Scalars['Boolean'];
  TransferRoom: Scalars['Boolean'];
  GuestInsert: Scalars['Boolean'];
  DepositInsert: Scalars['Boolean'];
  RefundDeposit: Scalars['Boolean'];
  CloseFolio: Scalars['Boolean'];
  VoidLedger: Scalars['Boolean'];
  VoidBillSchedule: Scalars['Boolean'];
  RegistrationSignatureUpdate: RegistrationEntity;
  VoidBillLedger: Scalars['Boolean'];
  RefundPayment?: Maybe<CheckOut>;
  FrontDeskPaymentInsert: PaymentEntity;
  CheckOut: Scalars['Boolean'];
  WalkIngBookingInsert: Scalars['String'];
  LateCheckOutInsert: Scalars['String'];
  ExtendStayInsert: Scalars['String'];
  RoomUpGradeInsert: Scalars['String'];
  SplitPayment: Scalars['Boolean'];
  TransferPayment: Scalars['Boolean'];
  RegistrationSign: Scalars['Boolean'];
  PaymentUpdate: Scalars['Boolean'];
  GuestRegister: Scalars['Boolean'];
  GuestLogin: GuestProfileEntity;
  GuestChangePassword: GuestProfileEntity;
  GuestForgotPassword: Scalars['Boolean'];
  GuestResetPassword: Scalars['Boolean'];
  OnlineBookingInsert: Scalars['String'];
  GuestRoomServiceInsert: Scalars['String'];
  HotelRatingInsert: HotelRatingEntity;
  GuestUpdate: Scalars['Boolean'];
  GuestProfileInsert: RegistrationEntity;
  GuestContactUpdate: Scalars['Boolean'];
  GuestProfileDelete: Scalars['Boolean'];
  BillLedgerUpdate: Scalars['Boolean'];
  BillLedgerInsert: Scalars['Boolean'];
  FolioInsert: Scalars['Boolean'];
  RoomServicePaymentUpdate: Scalars['Boolean'];
  OnlinePaymentRoomServices: OnlineItemEntity;
  BlockRoomLogInsert: BlockRoomLogEntity;
  ReleaseRoomLogUpdate: Scalars['Boolean'];
  BlockRoomLogUpdate: Scalars['Boolean'];
  HouseKeepingLogInsert: Scalars['Boolean'];
  HousekeepingServiceRequestInsert: ServiceRequestEntity;
  HousekeepingServiceRequestUpdate: Scalars['Boolean'];
  HousekeepingBillLedgerInsert: BillLedgerEntity;
  HousekeepingBillLedgerUpdate: Scalars['Boolean'];
  DepartmentInsert: Scalars['Boolean'];
  DepartmentUpdate: Scalars['Boolean'];
  IncidentalChargesUpdate: Scalars['Boolean'];
  DepartmentDelete: Scalars['Boolean'];
  HotelUpdate: Scalars['Boolean'];
  HotelInsert: HotelEntity;
  HotelContactUpdate: Scalars['Boolean'];
  HotelContactInsert: ContactEntity;
  HotelFacilityUpdate: Scalars['Boolean'];
  HotelFacilityInsert: FacilityEntity;
  HotelNewsUpdate: Scalars['Boolean'];
  HotelNewsInsert: NewsEntity;
  IncidentalChargeInsert: Scalars['Boolean'];
  IncidentalChargeUpdate: Scalars['Boolean'];
  IncidentalChargeDelete: Scalars['Boolean'];
  LateCheckOutChargeInsert: Scalars['Boolean'];
  LocationInsert: LocationEntity;
  LocationUpdate: Scalars['Boolean'];
  LocationDelete: Scalars['Boolean'];
  RatePolicyInsert: RatePolicyEntity;
  RatePolicyUpdate: Scalars['Boolean'];
  PackageInsert: Scalars['Boolean'];
  PackageUpdate: Scalars['Boolean'];
  RatePolicyDelete: Scalars['Boolean'];
  ReasonInsert: Scalars['Boolean'];
  ReasonUpdate: Scalars['Boolean'];
  ReasonDelete: Scalars['Boolean'];
  RoomInsert: RoomEntity;
  RoomUpdate: Scalars['Boolean'];
  RoomDelete: Scalars['Boolean'];
  RoomStatusInsert: Scalars['Boolean'];
  RoomTypeUpdate: Scalars['Boolean'];
  RoomTypeInsert: RoomTypeEntity;
  RoomTypeDelete: Scalars['Boolean'];
  SalesChannelInsert: Scalars['Boolean'];
  SalesChannelUpdate: Scalars['Boolean'];
  SalesChannelDelete: Scalars['Boolean'];
  SeasonCalendarUpdate: Scalars['Boolean'];
  SegmentUpdate: Scalars['Boolean'];
  SegmentInsert: SegmentEntity;
  SegmentDelete: Scalars['Boolean'];
  HotelTaxPolicyInsert: Scalars['Boolean'];
  HotelTaxPolicyUpdate: Scalars['Boolean'];
  HotelTaxPolicyDelete: Scalars['Boolean'];
  TravelAgentInsert: DebtorEntity;
  TravelAgentMainUpdate: Scalars['Boolean'];
  TravelAgentUpdate: Scalars['Boolean'];
  TravelAgentContractUpdate: Scalars['Boolean'];
  TravelAgentContractInsert: ContractEntity;
  UpdateAllotment: Scalars['Boolean'];
  TravelAgentDelete: Scalars['Boolean'];
  DebtorLogin: DebtorProfileEntity;
  DebtorChangePassword: DebtorProfileEntity;
  DebtorForgotPassword: Scalars['Boolean'];
  AgentDelete: Scalars['Boolean'];
  DebtorResetPassword: Scalars['Boolean'];
  CreateRolePermission: Scalars['Boolean'];
  UpdateRolePermission: Scalars['Boolean'];
  DeleteRolePermission: Scalars['Boolean'];
  CreateEntityRoleUser: Scalars['Boolean'];
  CreateListEntityRoleUser: Scalars['Boolean'];
  NotificationInsert: Scalars['Boolean'];
  OnlinePayment?: Maybe<CheckOut>;
  PackageRedemptionInsert: PackageRedemptionEntity;
  createUser: UserEntity;
  createPassword: Scalars['Boolean'];
  updateUser: UserEntity;
  activateUser: Scalars['Boolean'];
  login: LoginResponse;
  changePassword: UserEntity;
  logout: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  totpSecret: Scalars['String'];
  totpValidate: Scalars['Boolean'];
  createSuperUser: UserEntity;
  InsertInterestAmt: Scalars['Boolean'];
  UpdateInterestAmt: Scalars['Float'];
  testUpdateQB: Scalars['Float'];
};


export type MutationDebitNoteInsertArgs = {
  ChargeItemInput: Array<DebitNoteTrxInput>;
  input: DebitNoteEntityInput;
};


export type MutationDebitNoteUpdateArgs = {
  ChargeItemInput: Array<DebitNoteTrxInput>;
  input: DebitNoteEntityInput;
  ID: Scalars['String'];
};


export type MutationDebitNoteCancelArgs = {
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationAllocateDebitNoteArgs = {
  allocInput: Array<DebitAllocationInput>;
  DebitNoteID: Scalars['String'];
};


export type MutationDebitNoteDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationCreditNoteInsertArgs = {
  ChargeItemInput: Array<CreditNoteTrxInput>;
  input: CreditNoteEntityInput;
};


export type MutationCreditNoteUpdateArgs = {
  ChargeItemInput: Array<DebitNoteTrxInput>;
  input: CreditNoteEntityInput;
  ID: Scalars['String'];
};


export type MutationCreditNoteCancelArgs = {
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationAllocateCreditNoteArgs = {
  allocInput: Array<CreditAllocationInput>;
  CreditNoteID: Scalars['String'];
};


export type MutationRefundInsertArgs = {
  input: RefundEntityInput;
};


export type MutationRefundUpdateArgs = {
  input: RefundEntityInput;
  ID: Scalars['String'];
};


export type MutationRefundCancelArgs = {
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationAllocateRefundArgs = {
  allocInput: Array<CreditAllocationInput>;
  RefundID: Scalars['String'];
};


export type MutationInvoiceInsertArgs = {
  ChargeItemInput: Array<InvoiceTrxInput>;
  input: InvoiceEntityInput;
};


export type MutationInvoiceUpdateArgs = {
  ChargeItemInput: Array<InvoiceTrxInput>;
  input: InvoiceEntityInput;
  ID: Scalars['String'];
};


export type MutationInvoiceCancelArgs = {
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationAllocateInvoiceArgs = {
  allocInput: Array<DebitAllocationInput>;
  InvoiceID: Scalars['String'];
};


export type MutationOfficialReceiptInsertArgs = {
  input: OfficialReceiptEntityInput;
};


export type MutationOfficialReceiptUpdateArgs = {
  input: OfficialReceiptEntityInput;
  ID: Scalars['String'];
};


export type MutationOfficialReceiptCancelArgs = {
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationAllocateOfficialReceiptArgs = {
  allocInput: Array<CreditAllocationInput>;
  ReceiptID: Scalars['String'];
};


export type MutationReminderInsertArgs = {
  input: ReminderEntityInput;
};


export type MutationReminderUpdateArgs = {
  input: ReminderEntityInput;
  ID: Scalars['String'];
};


export type MutationReminderDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationAsyncInterestInsertArgs = {
  input: InterestEntityInput;
};


export type MutationInterestUpdateArgs = {
  input: InterestEntityInput;
  ID: Scalars['String'];
};


export type MutationInterestDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationDebtorTypeInsertArgs = {
  input: DebtorTypeInput;
};


export type MutationDebtorTypeUpdateArgs = {
  input: DebtorTypeInput;
  ID: Scalars['String'];
};


export type MutationDebtorTypeDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationBookingInsertArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  RegistrationInput: Array<RegistrationInput>;
  ContactInput: ContactInput;
  BookingInput: BookingInput;
};


export type MutationBookingRoomTypeUpdateArgs = {
  EditRegInput: EditRegInput;
};


export type MutationBookingUpdateArgs = {
  ContactInput?: Maybe<ContactInput>;
  BookingInput: BookingInput;
};


export type MutationCancelBookingArgs = {
  ReasonID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationAdvancePaymentInsertArgs = {
  AdvancePaymentInput: AdvancePaymentInput;
};


export type MutationAdvancePaymentRefundArgs = {
  AdvancePaymentInput: Array<AdvancePaymentInput>;
};


export type MutationAdvancePaymentUpdateArgs = {
  CheckOutID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
};


export type MutationReinstateBookingArgs = {
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
};


export type MutationManualNightAuditArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type MutationAvailabilityUpdateArgs = {
  TravelAgentID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type MutationUploadImageArgs = {
  FileInput: Array<Scalars['Upload']>;
};


export type MutationDeleteImageArgs = {
  OldGalleryID: Array<Scalars['String']>;
};


export type MutationBiSharedGAlleryInsertArgs = {
  AttachmentInput: Array<Scalars['Upload']>;
  HotelID: Scalars['String'];
};


export type MutationCorporateInsertArgs = {
  DebtorContactInput: Array<ContactInput>;
  ContactInput: ContactInput;
  DebtorInput: DebtorInput;
};


export type MutationGovermentTestingUpdateArgs = {
  DebtorInput: DebtorInput;
};


export type MutationCorporateUpdateArgs = {
  DebtorContactInput?: Maybe<Array<ContactInput>>;
  ContactInput: ContactInput;
  DebtorInput: DebtorInput;
};


export type MutationCorporateContractUpdateArgs = {
  RatePolicyIDs?: Maybe<Array<Scalars['String']>>;
  ContractInput: ContractInput;
};


export type MutationCorporateContractInsertArgs = {
  RatePolicyIDs: Array<Scalars['String']>;
  ContractInput: ContractInput;
};


export type MutationCorporateDeleteArgs = {
  DebtorID: Scalars['String'];
};


export type MutationPromotionUpdateArgs = {
  PromotionDetailInput: Array<PromotionDetailInput>;
  PromotionInput: PromotionInput;
};


export type MutationPromotionInsertArgs = {
  PromotionDetailInput: Array<PromotionDetailInput>;
  PromotionInput: PromotionInput;
};


export type MutationPromotionActiveUpdateArgs = {
  PromotionInput: PromotionInput;
};


export type MutationPromotionDetailUpdateArgs = {
  PromotionDetailInput: PromotionDetailInput;
};


export type MutationPromotionDetailInsertArgs = {
  PromotionDetailInput: PromotionDetailInput;
};


export type MutationEmailCampignUpdateArgs = {
  EmailCampignInput: EmailCampignInput;
};


export type MutationEmailCampignInsertArgs = {
  EmailCampignInput: EmailCampignInput;
};


export type MutationEmailCampaignArgs = {
  id: Scalars['String'];
  email: Scalars['String'];
};


export type MutationPromotionDeleteArgs = {
  Date: Scalars['DateTime'];
  PromoCode: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationEmailDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationAssignRoomArgs = {
  RoomID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationCheckInArgs = {
  GuestAppCheckIn?: Maybe<Scalars['Boolean']>;
  ContactInput?: Maybe<Array<ContactInput>>;
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationTransferRoomArgs = {
  NewRoomID: Scalars['String'];
  OldRoomID: Scalars['String'];
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationGuestInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
  ContactInput: Array<ContactInput>;
};


export type MutationDepositInsertArgs = {
  input: DepositInput;
};


export type MutationRefundDepositArgs = {
  input: RefundDepositInput;
};


export type MutationCloseFolioArgs = {
  PaymentID: Scalars['String'];
  RegistrationID: Array<Scalars['String']>;
  FolioInput: FolioInput;
};


export type MutationVoidLedgerArgs = {
  ChargeType?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  RegistrationID: Scalars['String'];
  ID: Scalars['String'];
  LedgerType: Scalars['String'];
};


export type MutationVoidBillScheduleArgs = {
  input: BillScheduleInput;
};


export type MutationRegistrationSignatureUpdateArgs = {
  AttachmentInput: Array<Scalars['Upload']>;
  RegistrationID: Scalars['String'];
};


export type MutationVoidBillLedgerArgs = {
  input: BillLedgerInput;
};


export type MutationRefundPaymentArgs = {
  ReceiptNo: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationFrontDeskPaymentInsertArgs = {
  TotalPaymentAmount: Scalars['Float'];
  input: Array<FrontDeskPaymentInput>;
};


export type MutationCheckOutArgs = {
  RoomID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']>;
};


export type MutationWalkIngBookingInsertArgs = {
  RegistrationInput: RegistrationInput;
  ContactInput: ContactInput;
  BookingInput: BookingInput;
};


export type MutationLateCheckOutInsertArgs = {
  hour: Scalars['Float'];
  input: FrontDeskPaymentInput;
};


export type MutationExtendStayInsertArgs = {
  RoomTypeID: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  DepartureDate: Scalars['DateTime'];
  input: FrontDeskPaymentInput;
};


export type MutationRoomUpGradeInsertArgs = {
  RoomUpgradeInput: RoomUpgradeInput;
  PaymentInput: FrontDeskPaymentInput;
};


export type MutationSplitPaymentArgs = {
  SplitBy: Scalars['Float'];
  RecordToSplit: Array<PaymentFolioInput>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationTransferPaymentArgs = {
  RecordIDs: Array<Scalars['String']>;
  NewRegistrationID: Scalars['String'];
};


export type MutationRegistrationSignArgs = {
  AttachmentInput: Array<Scalars['Upload']>;
  ID: Scalars['String'];
};


export type MutationPaymentUpdateArgs = {
  CheckOutID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
};


export type MutationGuestRegisterArgs = {
  MobileNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
};


export type MutationGuestLoginArgs = {
  Password: Scalars['String'];
  Email: Scalars['String'];
};


export type MutationGuestChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationGuestForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationGuestResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationOnlineBookingInsertArgs = {
  AppName?: Maybe<Scalars['String']>;
  RegistrationInput: Array<RegistrationInput>;
  BookingInput: BookingInput;
};


export type MutationGuestRoomServiceInsertArgs = {
  AccountID?: Maybe<Scalars['String']>;
  BookingID: Scalars['String'];
  PaymentMode: Scalars['String'];
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationHotelRatingInsertArgs = {
  input: HotelRatingInput;
};


export type MutationGuestUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  GuestProfileInput: GuestProfileInput;
  ContactInput: ContactInput;
};


export type MutationGuestProfileInsertArgs = {
  ContactInput: ContactInput;
  RegistrationID: Scalars['String'];
};


export type MutationGuestContactUpdateArgs = {
  VehicleNo?: Maybe<Scalars['String']>;
  ContactInput: ContactInput;
};


export type MutationGuestProfileDeleteArgs = {
  BookingID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
};


export type MutationBillLedgerUpdateArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  BillLedgerInput: BillLedgerInput;
};


export type MutationBillLedgerInsertArgs = {
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationFolioInsertArgs = {
  PaymentInput: PaymentInput;
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationRoomServicePaymentUpdateArgs = {
  PaymentType?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  CheckOutID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type MutationOnlinePaymentRoomServicesArgs = {
  PaymentInput: PaymentInput;
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationBlockRoomLogInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  BlockRoomLogInput: BlockRoomLogInput;
};


export type MutationReleaseRoomLogUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  BlockRoomLogInput: BlockRoomLogInput;
};


export type MutationBlockRoomLogUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  BlockRoomLogInput: BlockRoomLogInput;
};


export type MutationHouseKeepingLogInsertArgs = {
  HouseKeepingLogInput: HouseKeepingLogInput;
};


export type MutationHousekeepingServiceRequestInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ServiceRequestInput: ServiceRequestInput;
};


export type MutationHousekeepingServiceRequestUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ServiceRequestInput: ServiceRequestInput;
};


export type MutationHousekeepingBillLedgerInsertArgs = {
  BillLedgerInput: BillLedgerInput;
};


export type MutationHousekeepingBillLedgerUpdateArgs = {
  BillLedgerInput: BillLedgerInput;
};


export type MutationDepartmentInsertArgs = {
  DepartmentInput: DepartmentInput;
};


export type MutationDepartmentUpdateArgs = {
  DepartmentInput: DepartmentInput;
};


export type MutationIncidentalChargesUpdateArgs = {
  status: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationDepartmentDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationHotelUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  Type?: Maybe<Scalars['String']>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  HotelInput: HotelInput;
};


export type MutationHotelInsertArgs = {
  Type?: Maybe<Scalars['String']>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  HotelInput: HotelInput;
};


export type MutationHotelContactUpdateArgs = {
  ContactInput: ContactInput;
};


export type MutationHotelContactInsertArgs = {
  HotelID: Scalars['String'];
  ContactInput: ContactInput;
};


export type MutationHotelFacilityUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  FacilityInput: FacilityInput;
};


export type MutationHotelFacilityInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  FacilityInput: FacilityInput;
};


export type MutationHotelNewsUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  NewsInput: NewsInput;
};


export type MutationHotelNewsInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  NewsInput: NewsInput;
};


export type MutationIncidentalChargeInsertArgs = {
  IncidentalChargeInput: IncidentalChargeInput;
};


export type MutationIncidentalChargeUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  IncidentalChargeInput: IncidentalChargeInput;
};


export type MutationIncidentalChargeDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationLateCheckOutChargeInsertArgs = {
  LateCheckOutChargeInput: Array<LateCheckOutChargeInput>;
};


export type MutationLocationInsertArgs = {
  LocationInput: LocationInput;
};


export type MutationLocationUpdateArgs = {
  LocationInput: LocationInput;
};


export type MutationLocationDeleteArgs = {
  Description: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationRatePolicyInsertArgs = {
  PackagesInput: Array<PackagesInput>;
  PricingInput: Array<PricingInput>;
  RatePolicyInput: RatePolicyInput;
};


export type MutationRatePolicyUpdateArgs = {
  PricingInput?: Maybe<Array<PricingInput>>;
  RatePolicyInput: RatePolicyInput;
};


export type MutationPackageInsertArgs = {
  PackagesInput: Array<PackagesInput>;
};


export type MutationPackageUpdateArgs = {
  PackagesInput: PackagesInput;
};


export type MutationRatePolicyDeleteArgs = {
  DebtorCategory: Scalars['String'];
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationReasonInsertArgs = {
  ReasonInput: ReasonInput;
};


export type MutationReasonUpdateArgs = {
  ReasonInput: ReasonInput;
};


export type MutationReasonDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationRoomInsertArgs = {
  RoomInput: RoomInput;
};


export type MutationRoomUpdateArgs = {
  RoomInput: RoomInput;
};


export type MutationRoomDeleteArgs = {
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationRoomStatusInsertArgs = {
  RoomStatusInput: Array<RoomStatusInput>;
};


export type MutationRoomTypeUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  RoomTypeAmenitiesInput?: Maybe<Array<RoomTypeAmenitiesInput>>;
  RoomTypeInput: RoomTypeInput;
};


export type MutationRoomTypeInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  RoomTypeAmenitiesInput: Array<RoomTypeAmenitiesInput>;
  RoomTypeInput: RoomTypeInput;
};


export type MutationRoomTypeDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationSalesChannelInsertArgs = {
  SalesChannelInput: SalesChannelInput;
};


export type MutationSalesChannelUpdateArgs = {
  SalesChannelInput: SalesChannelInput;
};


export type MutationSalesChannelDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationSeasonCalendarUpdateArgs = {
  HotelID: Scalars['String'];
  CalendarDate: Scalars['DateTime'];
  SeasonCalendarInput: Array<SeasonCalendarInput>;
};


export type MutationSegmentUpdateArgs = {
  SegmentInput: SegmentInput;
};


export type MutationSegmentInsertArgs = {
  SegmentInput: SegmentInput;
};


export type MutationSegmentDeleteArgs = {
  Description: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationHotelTaxPolicyInsertArgs = {
  HotelTaxPolicyInput: HotelTaxPolicyInput;
};


export type MutationHotelTaxPolicyUpdateArgs = {
  HotelTaxPolicyInput: HotelTaxPolicyInput;
};


export type MutationHotelTaxPolicyDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationTravelAgentInsertArgs = {
  DebtorContactInput: Array<ContactInput>;
  ContactInput: ContactInput;
  DebtorInput: DebtorInput;
};


export type MutationTravelAgentMainUpdateArgs = {
  DebtorInput: DebtorInput;
};


export type MutationTravelAgentUpdateArgs = {
  DebtorContactInput?: Maybe<Array<ContactInput>>;
  ContactInput: ContactInput;
  DebtorInput: DebtorInput;
};


export type MutationTravelAgentContractUpdateArgs = {
  HotelID: Scalars['String'];
  RoomTypeList?: Maybe<Array<ContractAllotmentInput>>;
  RatePolicyIDs?: Maybe<Array<Scalars['String']>>;
  ContractInput: ContractInput;
};


export type MutationTravelAgentContractInsertArgs = {
  HotelID: Scalars['String'];
  RoomTypeList: Array<ContractAllotmentInput>;
  RatePolicyIDs: Array<Scalars['String']>;
  ContractInput: ContractInput;
};


export type MutationUpdateAllotmentArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  AllotmentInput: Array<ContractAllotmentInput>;
};


export type MutationTravelAgentDeleteArgs = {
  DebtorID: Scalars['String'];
};


export type MutationDebtorLoginArgs = {
  Password: Scalars['String'];
  Email: Scalars['String'];
};


export type MutationDebtorChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationDebtorForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationAgentDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationDebtorResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationCreateRolePermissionArgs = {
  permissionArr: Array<HotelPermission>;
  input: RoleInput;
};


export type MutationUpdateRolePermissionArgs = {
  permissionArr: Array<HotelPermission>;
  input: RoleInput;
};


export type MutationDeleteRolePermissionArgs = {
  roleID: Scalars['String'];
};


export type MutationCreateEntityRoleUserArgs = {
  input: EntRoleUsrAsgInput;
};


export type MutationCreateListEntityRoleUserArgs = {
  roleUserIDs: Array<RoleUserInput>;
  contractID: Scalars['String'];
};


export type MutationOnlinePaymentArgs = {
  ReceiptNo: Scalars['String'];
  redirectUrl: Scalars['String'];
  PayAmount: Scalars['Float'];
  Detail: Scalars['String'];
  Title: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationPackageRedemptionInsertArgs = {
  Input: PackageRedemptionInput;
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationCreatePasswordArgs = {
  softwareCode: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
  confirmPassword: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  input: UserInput;
};


export type MutationActivateUserArgs = {
  token: Scalars['String'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  accountID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationTotpValidateArgs = {
  token: Scalars['String'];
};


export type MutationCreateSuperUserArgs = {
  accountID: Scalars['String'];
  input: UserInput;
};


export type MutationUpdateInterestAmtArgs = {
  InterestRate: Scalars['Float'];
};

export type Subscription = {
  __typename?: 'Subscription';
  NewNotification: NotificationEntity;
};


export type SubscriptionNewNotificationArgs = {
  HotelID: Scalars['String'];
  registrationToken: Scalars['String'];
};

export type LoggedInUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInUserProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'email' | 'contactNo' | 'department' | 'accountID' | 'jobTitle'>
  ) }
);

export type LoginMutationVariables = Exact<{
  password: Scalars['String'];
  loginId: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'accessToken'>
    & { user: (
      { __typename?: 'UserResponse' }
      & Pick<UserResponse, 'ID' | 'name' | 'email' | 'contactNo' | 'department' | 'accountID' | 'jobTitle'>
    ) }
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { changePassword: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'name'>
  ) }
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type DebtorListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type DebtorListingQuery = (
  { __typename?: 'Query' }
  & { DebtorListing: Array<(
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'HotelID' | 'ContactID' | 'DebtorTypeID' | 'DebtorCategory' | 'Name' | 'DebtorAccount' | 'CompanyRegNo' | 'GSTNo' | 'Currency' | 'CreditTerm' | 'CreditLimit' | 'Remarks' | 'Segment' | 'Login' | 'Password' | 'ARTotalDue' | 'ARTotalOutstanding'>
  )> }
);

export type DebtorDetailQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DebtorDetailQuery = (
  { __typename?: 'Query' }
  & { DebtorDetail: (
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'HotelID' | 'ContactID' | 'DebtorTypeID' | 'DebtorCategory' | 'Name' | 'DebtorAccount' | 'CompanyRegNo' | 'GSTNo' | 'Currency' | 'CreditTerm' | 'CreditLimit' | 'Remarks' | 'Segment' | 'Login' | 'Password' | 'ARTotalDue' | 'ARTotalOutstanding'>
  ) }
);

export type DebtorInfoLineQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type DebtorInfoLineQuery = (
  { __typename?: 'Query' }
  & { ARTotalOutstanding: (
    { __typename?: 'ARAccountOSLastPayment' }
    & Pick<ArAccountOsLastPayment, 'LastPaymentDT' | 'TotalOstandingAmount'>
  ) }
);

export type LedgerDocumentInfoLineQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type LedgerDocumentInfoLineQuery = (
  { __typename?: 'Query' }
  & { InvoiceInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ), DebitNoteInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ), CreditNoteInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ), OfficialReceiptInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ), RefundInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ) }
);

export type InvoiceListingsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type InvoiceListingsQuery = (
  { __typename?: 'Query' }
  & { InvoiceListings: Array<(
    { __typename?: 'InvoiceEntity' }
    & Pick<InvoiceEntity, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate'>
    & { LedgerTrx: Array<(
      { __typename?: 'InvoiceTrxEntity' }
      & Pick<InvoiceTrxEntity, 'TransactionID' | 'TrxName' | 'TrxAmount' | 'ReferenceNo' | 'UnitPrice' | 'Quantity' | 'DiscountedAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'Remarks'>
    )> }
  )> }
);

export type InvoiceDetailQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type InvoiceDetailQuery = (
  { __typename?: 'Query' }
  & { InvoiceDetail: (
    { __typename?: 'InvoiceEntity' }
    & Pick<InvoiceEntity, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate'>
    & { LedgerTrx: Array<(
      { __typename?: 'InvoiceTrxEntity' }
      & Pick<InvoiceTrxEntity, 'TransactionID' | 'TrxName' | 'TrxAmount' | 'ReferenceNo' | 'UnitPrice' | 'Quantity' | 'DiscountedAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'Remarks'>
    )>, Allocation: Array<(
      { __typename?: 'AllocationEntity' }
      & Pick<AllocationEntity, 'ID' | 'HotelID' | 'DebtorID' | 'AccountID' | 'DebitRefTable' | 'DebitID' | 'CreditRefTable' | 'CreditID' | 'AllocationAmount'>
    )> }
  ) }
);

export type InvoiceInsertMutationVariables = Exact<{
  input: InvoiceEntityInput;
  ChargeItemInput: Array<InvoiceTrxInput> | InvoiceTrxInput;
}>;


export type InvoiceInsertMutation = (
  { __typename?: 'Mutation' }
  & { InvoiceInsert: (
    { __typename?: 'InvoiceEntity' }
    & Pick<InvoiceEntity, 'ID'>
  ) }
);

export type InvoiceUpdateMutationVariables = Exact<{
  input: InvoiceEntityInput;
  ChargeItemInput: Array<InvoiceTrxInput> | InvoiceTrxInput;
  ID: Scalars['String'];
}>;


export type InvoiceUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'InvoiceUpdate'>
);

export type InvoiceCancelMutationVariables = Exact<{
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
}>;


export type InvoiceCancelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'InvoiceCancel'>
);

export type AllocateInvoiceMutationVariables = Exact<{
  allocInput: Array<DebitAllocationInput> | DebitAllocationInput;
  InvoiceID: Scalars['String'];
}>;


export type AllocateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AllocateInvoice'>
);

export type InvoiceInfoQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type InvoiceInfoQuery = (
  { __typename?: 'Query' }
  & { InvoiceInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ) }
);

export type DebitNoteListingsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type DebitNoteListingsQuery = (
  { __typename?: 'Query' }
  & { DebitNoteListings: Array<(
    { __typename?: 'DebitNoteEntity' }
    & Pick<DebitNoteEntity, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate'>
    & { LedgerTrx: Array<(
      { __typename?: 'DebitNoteTrxEntity' }
      & Pick<DebitNoteTrxEntity, 'TransactionID' | 'TrxName' | 'TrxAmount' | 'ReferenceNo' | 'UnitPrice' | 'Quantity' | 'DiscountedAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'Remarks'>
    )> }
  )> }
);

export type DebitNoteDetailQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DebitNoteDetailQuery = (
  { __typename?: 'Query' }
  & { DebitNoteDetail: (
    { __typename?: 'DebitNoteEntity' }
    & Pick<DebitNoteEntity, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate'>
    & { LedgerTrx: Array<(
      { __typename?: 'DebitNoteTrxEntity' }
      & Pick<DebitNoteTrxEntity, 'TransactionID' | 'TrxName' | 'TrxAmount' | 'ReferenceNo' | 'UnitPrice' | 'Quantity' | 'DiscountedAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'Remarks'>
    )>, Allocation: Array<(
      { __typename?: 'AllocationEntity' }
      & Pick<AllocationEntity, 'ID' | 'HotelID' | 'DebtorID' | 'AccountID' | 'DebitRefTable' | 'DebitID' | 'CreditRefTable' | 'CreditID' | 'AllocationAmount'>
    )> }
  ) }
);

export type DebitNoteInsertMutationVariables = Exact<{
  input: DebitNoteEntityInput;
  ChargeItemInput: Array<DebitNoteTrxInput> | DebitNoteTrxInput;
}>;


export type DebitNoteInsertMutation = (
  { __typename?: 'Mutation' }
  & { DebitNoteInsert: (
    { __typename?: 'DebitNoteEntity' }
    & Pick<DebitNoteEntity, 'ID'>
  ) }
);

export type DebitNoteUpdateMutationVariables = Exact<{
  input: DebitNoteEntityInput;
  ChargeItemInput: Array<DebitNoteTrxInput> | DebitNoteTrxInput;
  ID: Scalars['String'];
}>;


export type DebitNoteUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DebitNoteUpdate'>
);

export type DebitNoteCancelMutationVariables = Exact<{
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
}>;


export type DebitNoteCancelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DebitNoteCancel'>
);

export type AllocateDebitNoteMutationVariables = Exact<{
  allocInput: Array<DebitAllocationInput> | DebitAllocationInput;
  DebitNoteID: Scalars['String'];
}>;


export type AllocateDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AllocateDebitNote'>
);

export type DebitNoteInfoQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type DebitNoteInfoQuery = (
  { __typename?: 'Query' }
  & { DebitNoteInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ) }
);

export type CreditNoteListingsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type CreditNoteListingsQuery = (
  { __typename?: 'Query' }
  & { CreditNoteListings: Array<(
    { __typename?: 'DebitNoteEntity' }
    & Pick<DebitNoteEntity, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate'>
    & { LedgerTrx: Array<(
      { __typename?: 'DebitNoteTrxEntity' }
      & Pick<DebitNoteTrxEntity, 'TransactionID' | 'TrxName' | 'TrxAmount' | 'ReferenceNo' | 'UnitPrice' | 'Quantity' | 'DiscountedAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'Remarks'>
    )> }
  )> }
);

export type CreditNoteDetailQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type CreditNoteDetailQuery = (
  { __typename?: 'Query' }
  & { CreditNoteDetail: (
    { __typename?: 'CreditNoteEntity' }
    & Pick<CreditNoteEntity, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate'>
    & { LedgerTrx: Array<(
      { __typename?: 'CreditNoteTrxEntity' }
      & Pick<CreditNoteTrxEntity, 'TransactionID' | 'TrxName' | 'TrxAmount' | 'ReferenceNo' | 'UnitPrice' | 'Quantity' | 'DiscountedAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'Remarks'>
    )>, Allocation: Array<(
      { __typename?: 'AllocationEntity' }
      & Pick<AllocationEntity, 'ID' | 'HotelID' | 'DebtorID' | 'AccountID' | 'DebitRefTable' | 'DebitID' | 'CreditRefTable' | 'CreditID' | 'AllocationAmount'>
    )> }
  ) }
);

export type CreditNoteInsertMutationVariables = Exact<{
  input: CreditNoteEntityInput;
  ChargeItemInput: Array<CreditNoteTrxInput> | CreditNoteTrxInput;
}>;


export type CreditNoteInsertMutation = (
  { __typename?: 'Mutation' }
  & { CreditNoteInsert: (
    { __typename?: 'CreditNoteEntity' }
    & Pick<CreditNoteEntity, 'ID'>
  ) }
);

export type CreditNoteUpdateMutationVariables = Exact<{
  input: CreditNoteEntityInput;
  ChargeItemInput: Array<DebitNoteTrxInput> | DebitNoteTrxInput;
  ID: Scalars['String'];
}>;


export type CreditNoteUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreditNoteUpdate'>
);

export type CreditNoteCancelMutationVariables = Exact<{
  Reason: Scalars['String'];
  ReasonCode: Scalars['String'];
  ID: Scalars['String'];
}>;


export type CreditNoteCancelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreditNoteCancel'>
);

export type AllocateCreditNoteMutationVariables = Exact<{
  allocInput: Array<CreditAllocationInput> | CreditAllocationInput;
  CreditNoteID: Scalars['String'];
}>;


export type AllocateCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AllocateCreditNote'>
);

export type CreditNoteInfoQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type CreditNoteInfoQuery = (
  { __typename?: 'Query' }
  & { CreditNoteInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ) }
);

export type OfficialReceiptListingsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type OfficialReceiptListingsQuery = (
  { __typename?: 'Query' }
  & { OfficialReceiptListings: Array<(
    { __typename?: 'OfficialReceiptEntity' }
    & Pick<OfficialReceiptEntity, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate' | 'Commission'>
  )> }
);

export type OfficialReceiptDetailQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type OfficialReceiptDetailQuery = (
  { __typename?: 'Query' }
  & { OfficialReceiptDetail: (
    { __typename?: 'OfficialReceiptEntity' }
    & Pick<OfficialReceiptEntity, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate' | 'Commission'>
    & { Allocation: Array<(
      { __typename?: 'AllocationEntity' }
      & Pick<AllocationEntity, 'ID' | 'HotelID' | 'DebtorID' | 'AccountID' | 'DebitRefTable' | 'DebitID' | 'CreditRefTable' | 'CreditID' | 'AllocationAmount'>
    )> }
  ) }
);

export type OfficialReceiptInsertMutationVariables = Exact<{
  input: OfficialReceiptEntityInput;
}>;


export type OfficialReceiptInsertMutation = (
  { __typename?: 'Mutation' }
  & { OfficialReceiptInsert: (
    { __typename?: 'OfficialReceiptEntity' }
    & Pick<OfficialReceiptEntity, 'ID'>
  ) }
);

export type OfficialReceiptUpdateMutationVariables = Exact<{
  input: OfficialReceiptEntityInput;
  ID: Scalars['String'];
}>;


export type OfficialReceiptUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OfficialReceiptUpdate'>
);

export type OfficialReceiptCancelMutationVariables = Exact<{
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
}>;


export type OfficialReceiptCancelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OfficialReceiptCancel'>
);

export type AllocateOfficialReceiptMutationVariables = Exact<{
  allocInput: Array<CreditAllocationInput> | CreditAllocationInput;
  ReceiptID: Scalars['String'];
}>;


export type AllocateOfficialReceiptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AllocateOfficialReceipt'>
);

export type OfficialReceiptInfoQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type OfficialReceiptInfoQuery = (
  { __typename?: 'Query' }
  & { OfficialReceiptInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ) }
);

export type RefundListingsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type RefundListingsQuery = (
  { __typename?: 'Query' }
  & { RefundListings: Array<(
    { __typename?: 'RefundEntity' }
    & Pick<RefundEntity, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate' | 'Commission'>
  )> }
);

export type RefundDetailQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type RefundDetailQuery = (
  { __typename?: 'Query' }
  & { RefundDetail: (
    { __typename?: 'RefundEntity' }
    & Pick<RefundEntity, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate' | 'Commission'>
    & { Allocation: Array<(
      { __typename?: 'AllocationEntity' }
      & Pick<AllocationEntity, 'ID' | 'HotelID' | 'DebtorID' | 'AccountID' | 'DebitRefTable' | 'DebitID' | 'CreditRefTable' | 'CreditID' | 'AllocationAmount'>
    )> }
  ) }
);

export type RefundInsertMutationVariables = Exact<{
  input: RefundEntityInput;
}>;


export type RefundInsertMutation = (
  { __typename?: 'Mutation' }
  & { RefundInsert: (
    { __typename?: 'RefundEntity' }
    & Pick<RefundEntity, 'ID'>
  ) }
);

export type RefundUpdateMutationVariables = Exact<{
  input: RefundEntityInput;
  ID: Scalars['String'];
}>;


export type RefundUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RefundUpdate'>
);

export type RefundCancelMutationVariables = Exact<{
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
}>;


export type RefundCancelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RefundCancel'>
);

export type AllocateRefundMutationVariables = Exact<{
  allocInput: Array<CreditAllocationInput> | CreditAllocationInput;
  RefundID: Scalars['String'];
}>;


export type AllocateRefundMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AllocateRefund'>
);

export type RefundInfoQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type RefundInfoQuery = (
  { __typename?: 'Query' }
  & { RefundInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ) }
);

export type IncidentalChargeListingQueryVariables = Exact<{
  DepartmentID: Scalars['String'];
}>;


export type IncidentalChargeListingQuery = (
  { __typename?: 'Query' }
  & { IncidentalChargeListing: Array<(
    { __typename?: 'IncidentalChargeEntity' }
    & Pick<IncidentalChargeEntity, 'ID' | 'IsActive' | 'DepartmentID' | 'Description' | 'Amount' | 'Tax' | 'ServiceCharge' | 'IsRoomRevenue' | 'GalleryID'>
  )> }
);

export type DebtorTypeListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type DebtorTypeListingQuery = (
  { __typename?: 'Query' }
  & { DebtorTypeListing: Array<(
    { __typename?: 'DebtorTypeEntity' }
    & Pick<DebtorTypeEntity, 'ID' | 'DebtorType' | 'Description' | 'CreatedDT'>
  )> }
);

export type DebtorTypeUpdateMutationVariables = Exact<{
  input: DebtorTypeInput;
  ID: Scalars['String'];
}>;


export type DebtorTypeUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DebtorTypeUpdate'>
);

export type DebtorTypeInsertMutationVariables = Exact<{
  input: DebtorTypeInput;
}>;


export type DebtorTypeInsertMutation = (
  { __typename?: 'Mutation' }
  & { DebtorTypeInsert: (
    { __typename?: 'DebtorTypeEntity' }
    & Pick<DebtorTypeEntity, 'ID' | 'DebtorType' | 'Description' | 'CreatedDT'>
  ) }
);

export type DebtorTypeDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DebtorTypeDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DebtorTypeDelete'>
);

export type HotelListingQueryVariables = Exact<{
  AccountID: Scalars['String'];
}>;


export type HotelListingQuery = (
  { __typename?: 'Query' }
  & { HotelListing: Array<(
    { __typename?: 'HotelEntity' }
    & Pick<HotelEntity, 'ID' | 'HotelName' | 'CompanyRegNo'>
    & { HotelAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'state' | 'postCode' | 'city' | 'country'>
    )>, Debtor?: Maybe<Array<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'ID' | 'IsActive' | 'DebtorCategory' | 'Name' | 'DebtorAccount' | 'CompanyRegNo' | 'GSTNo' | 'Currency' | 'CreditTerm' | 'CreditLimit' | 'Remarks'>
    )>> }
  )> }
);

export type HotelDetailsQueryVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
  OnlineBookingURL?: Maybe<Scalars['String']>;
}>;


export type HotelDetailsQuery = (
  { __typename?: 'Query' }
  & { HotelDetails: (
    { __typename?: 'HotelEntity' }
    & Pick<HotelEntity, 'ID' | 'HotelName'>
  ) }
);


export const LoggedInUserProfileDocument = gql`
    query loggedInUserProfile {
  loggedInUserProfile {
    ID
    name
    email
    contactNo
    department
    accountID
    jobTitle
  }
}
    `;

/**
 * __useLoggedInUserProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
      }
export function useLoggedInUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
        }
export type LoggedInUserProfileQueryHookResult = ReturnType<typeof useLoggedInUserProfileQuery>;
export type LoggedInUserProfileLazyQueryHookResult = ReturnType<typeof useLoggedInUserProfileLazyQuery>;
export type LoggedInUserProfileQueryResult = ApolloReactCommon.QueryResult<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>;
export const LoginDocument = gql`
    mutation login($password: String!, $loginId: String!) {
  login(password: $password, loginId: $loginId) {
    accessToken
    user {
      ID
      name
      email
      contactNo
      department
      accountID
      jobTitle
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      password: // value for 'password'
 *      loginId: // value for 'loginId'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($newPassword: String!, $currentPassword: String!) {
  changePassword(newPassword: $newPassword, currentPassword: $currentPassword) {
    name
  }
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $token: String!) {
  resetPassword(password: $password, token: $token)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const DebtorListingDocument = gql`
    query DebtorListing($HotelID: String!) {
  DebtorListing(HotelID: $HotelID) {
    ID
    IsActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    HotelID
    ContactID
    DebtorTypeID
    DebtorCategory
    Name
    DebtorAccount
    CompanyRegNo
    GSTNo
    Currency
    CreditTerm
    CreditLimit
    Remarks
    Segment
    Login
    Password
    ARTotalDue
    ARTotalOutstanding
  }
}
    `;

/**
 * __useDebtorListingQuery__
 *
 * To run a query within a React component, call `useDebtorListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useDebtorListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorListingQuery, DebtorListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorListingQuery, DebtorListingQueryVariables>(DebtorListingDocument, baseOptions);
      }
export function useDebtorListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorListingQuery, DebtorListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorListingQuery, DebtorListingQueryVariables>(DebtorListingDocument, baseOptions);
        }
export type DebtorListingQueryHookResult = ReturnType<typeof useDebtorListingQuery>;
export type DebtorListingLazyQueryHookResult = ReturnType<typeof useDebtorListingLazyQuery>;
export type DebtorListingQueryResult = ApolloReactCommon.QueryResult<DebtorListingQuery, DebtorListingQueryVariables>;
export const DebtorDetailDocument = gql`
    query DebtorDetail($ID: String!) {
  DebtorDetail(ID: $ID) {
    ID
    IsActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    HotelID
    ContactID
    DebtorTypeID
    DebtorCategory
    Name
    DebtorAccount
    CompanyRegNo
    GSTNo
    Currency
    CreditTerm
    CreditLimit
    Remarks
    Segment
    Login
    Password
    ARTotalDue
    ARTotalOutstanding
  }
}
    `;

/**
 * __useDebtorDetailQuery__
 *
 * To run a query within a React component, call `useDebtorDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorDetailQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDebtorDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorDetailQuery, DebtorDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorDetailQuery, DebtorDetailQueryVariables>(DebtorDetailDocument, baseOptions);
      }
export function useDebtorDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorDetailQuery, DebtorDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorDetailQuery, DebtorDetailQueryVariables>(DebtorDetailDocument, baseOptions);
        }
export type DebtorDetailQueryHookResult = ReturnType<typeof useDebtorDetailQuery>;
export type DebtorDetailLazyQueryHookResult = ReturnType<typeof useDebtorDetailLazyQuery>;
export type DebtorDetailQueryResult = ApolloReactCommon.QueryResult<DebtorDetailQuery, DebtorDetailQueryVariables>;
export const DebtorInfoLineDocument = gql`
    query DebtorInfoLine($HotelID: String!, $DebtorID: String!) {
  ARTotalOutstanding(HotelID: $HotelID, DebtorID: $DebtorID) {
    LastPaymentDT
    TotalOstandingAmount
  }
}
    `;

/**
 * __useDebtorInfoLineQuery__
 *
 * To run a query within a React component, call `useDebtorInfoLineQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorInfoLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorInfoLineQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useDebtorInfoLineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorInfoLineQuery, DebtorInfoLineQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorInfoLineQuery, DebtorInfoLineQueryVariables>(DebtorInfoLineDocument, baseOptions);
      }
export function useDebtorInfoLineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorInfoLineQuery, DebtorInfoLineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorInfoLineQuery, DebtorInfoLineQueryVariables>(DebtorInfoLineDocument, baseOptions);
        }
export type DebtorInfoLineQueryHookResult = ReturnType<typeof useDebtorInfoLineQuery>;
export type DebtorInfoLineLazyQueryHookResult = ReturnType<typeof useDebtorInfoLineLazyQuery>;
export type DebtorInfoLineQueryResult = ApolloReactCommon.QueryResult<DebtorInfoLineQuery, DebtorInfoLineQueryVariables>;
export const LedgerDocumentInfoLineDocument = gql`
    query LedgerDocumentInfoLine($HotelID: String!, $DebtorID: String!) {
  InvoiceInfo(HotelID: $HotelID, DebtorID: $DebtorID) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
  DebitNoteInfo(HotelID: $HotelID, DebtorID: $DebtorID) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
  CreditNoteInfo(HotelID: $HotelID, DebtorID: $DebtorID) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
  OfficialReceiptInfo(HotelID: $HotelID, DebtorID: $DebtorID) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
  RefundInfo(HotelID: $HotelID, DebtorID: $DebtorID) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
}
    `;

/**
 * __useLedgerDocumentInfoLineQuery__
 *
 * To run a query within a React component, call `useLedgerDocumentInfoLineQuery` and pass it any options that fit your needs.
 * When your component renders, `useLedgerDocumentInfoLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLedgerDocumentInfoLineQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useLedgerDocumentInfoLineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LedgerDocumentInfoLineQuery, LedgerDocumentInfoLineQueryVariables>) {
        return ApolloReactHooks.useQuery<LedgerDocumentInfoLineQuery, LedgerDocumentInfoLineQueryVariables>(LedgerDocumentInfoLineDocument, baseOptions);
      }
export function useLedgerDocumentInfoLineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LedgerDocumentInfoLineQuery, LedgerDocumentInfoLineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LedgerDocumentInfoLineQuery, LedgerDocumentInfoLineQueryVariables>(LedgerDocumentInfoLineDocument, baseOptions);
        }
export type LedgerDocumentInfoLineQueryHookResult = ReturnType<typeof useLedgerDocumentInfoLineQuery>;
export type LedgerDocumentInfoLineLazyQueryHookResult = ReturnType<typeof useLedgerDocumentInfoLineLazyQuery>;
export type LedgerDocumentInfoLineQueryResult = ApolloReactCommon.QueryResult<LedgerDocumentInfoLineQuery, LedgerDocumentInfoLineQueryVariables>;
export const InvoiceListingsDocument = gql`
    query InvoiceListings($HotelID: String!, $DebtorID: String!) {
  InvoiceListings(HotelID: $HotelID, DebtorID: $DebtorID) {
    ID
    DocumentNo
    ReferenceNo
    Description
    DocumentDate
    Amount
    Status
    HotelID
    AccountID
    DebtorID
    Attachment
    Currency
    OutstandingAmount
    CurrencyRate
    Reason
    VoidDate
    PostedDate
    LedgerTrx {
      TransactionID
      TrxName
      TrxAmount
      ReferenceNo
      UnitPrice
      Quantity
      DiscountedAmount
      BaseAmount
      TaxAmount
      ServiceCharge
      Remarks
    }
  }
}
    `;

/**
 * __useInvoiceListingsQuery__
 *
 * To run a query within a React component, call `useInvoiceListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceListingsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useInvoiceListingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InvoiceListingsQuery, InvoiceListingsQueryVariables>) {
        return ApolloReactHooks.useQuery<InvoiceListingsQuery, InvoiceListingsQueryVariables>(InvoiceListingsDocument, baseOptions);
      }
export function useInvoiceListingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InvoiceListingsQuery, InvoiceListingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InvoiceListingsQuery, InvoiceListingsQueryVariables>(InvoiceListingsDocument, baseOptions);
        }
export type InvoiceListingsQueryHookResult = ReturnType<typeof useInvoiceListingsQuery>;
export type InvoiceListingsLazyQueryHookResult = ReturnType<typeof useInvoiceListingsLazyQuery>;
export type InvoiceListingsQueryResult = ApolloReactCommon.QueryResult<InvoiceListingsQuery, InvoiceListingsQueryVariables>;
export const InvoiceDetailDocument = gql`
    query InvoiceDetail($ID: String!) {
  InvoiceDetail(ID: $ID) {
    ID
    DocumentNo
    ReferenceNo
    Description
    DocumentDate
    Amount
    Status
    HotelID
    AccountID
    DebtorID
    Attachment
    Currency
    OutstandingAmount
    CurrencyRate
    Reason
    VoidDate
    PostedDate
    LedgerTrx {
      TransactionID
      TrxName
      TrxAmount
      ReferenceNo
      UnitPrice
      Quantity
      DiscountedAmount
      BaseAmount
      TaxAmount
      ServiceCharge
      Remarks
    }
    Allocation {
      ID
      HotelID
      DebtorID
      AccountID
      DebitRefTable
      DebitID
      CreditRefTable
      CreditID
      AllocationAmount
    }
  }
}
    `;

/**
 * __useInvoiceDetailQuery__
 *
 * To run a query within a React component, call `useInvoiceDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceDetailQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useInvoiceDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InvoiceDetailQuery, InvoiceDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<InvoiceDetailQuery, InvoiceDetailQueryVariables>(InvoiceDetailDocument, baseOptions);
      }
export function useInvoiceDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InvoiceDetailQuery, InvoiceDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InvoiceDetailQuery, InvoiceDetailQueryVariables>(InvoiceDetailDocument, baseOptions);
        }
export type InvoiceDetailQueryHookResult = ReturnType<typeof useInvoiceDetailQuery>;
export type InvoiceDetailLazyQueryHookResult = ReturnType<typeof useInvoiceDetailLazyQuery>;
export type InvoiceDetailQueryResult = ApolloReactCommon.QueryResult<InvoiceDetailQuery, InvoiceDetailQueryVariables>;
export const InvoiceInsertDocument = gql`
    mutation InvoiceInsert($input: InvoiceEntityInput!, $ChargeItemInput: [InvoiceTrxInput!]!) {
  InvoiceInsert(input: $input, ChargeItemInput: $ChargeItemInput) {
    ID
  }
}
    `;
export type InvoiceInsertMutationFn = ApolloReactCommon.MutationFunction<InvoiceInsertMutation, InvoiceInsertMutationVariables>;

/**
 * __useInvoiceInsertMutation__
 *
 * To run a mutation, you first call `useInvoiceInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceInsertMutation, { data, loading, error }] = useInvoiceInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ChargeItemInput: // value for 'ChargeItemInput'
 *   },
 * });
 */
export function useInvoiceInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InvoiceInsertMutation, InvoiceInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<InvoiceInsertMutation, InvoiceInsertMutationVariables>(InvoiceInsertDocument, baseOptions);
      }
export type InvoiceInsertMutationHookResult = ReturnType<typeof useInvoiceInsertMutation>;
export type InvoiceInsertMutationResult = ApolloReactCommon.MutationResult<InvoiceInsertMutation>;
export type InvoiceInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<InvoiceInsertMutation, InvoiceInsertMutationVariables>;
export const InvoiceUpdateDocument = gql`
    mutation InvoiceUpdate($input: InvoiceEntityInput!, $ChargeItemInput: [InvoiceTrxInput!]!, $ID: String!) {
  InvoiceUpdate(input: $input, ChargeItemInput: $ChargeItemInput, ID: $ID)
}
    `;
export type InvoiceUpdateMutationFn = ApolloReactCommon.MutationFunction<InvoiceUpdateMutation, InvoiceUpdateMutationVariables>;

/**
 * __useInvoiceUpdateMutation__
 *
 * To run a mutation, you first call `useInvoiceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceUpdateMutation, { data, loading, error }] = useInvoiceUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ChargeItemInput: // value for 'ChargeItemInput'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useInvoiceUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InvoiceUpdateMutation, InvoiceUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<InvoiceUpdateMutation, InvoiceUpdateMutationVariables>(InvoiceUpdateDocument, baseOptions);
      }
export type InvoiceUpdateMutationHookResult = ReturnType<typeof useInvoiceUpdateMutation>;
export type InvoiceUpdateMutationResult = ApolloReactCommon.MutationResult<InvoiceUpdateMutation>;
export type InvoiceUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<InvoiceUpdateMutation, InvoiceUpdateMutationVariables>;
export const InvoiceCancelDocument = gql`
    mutation InvoiceCancel($ReasonCode: String!, $Reason: String!, $ID: String!) {
  InvoiceCancel(ReasonCode: $ReasonCode, Reason: $Reason, ID: $ID)
}
    `;
export type InvoiceCancelMutationFn = ApolloReactCommon.MutationFunction<InvoiceCancelMutation, InvoiceCancelMutationVariables>;

/**
 * __useInvoiceCancelMutation__
 *
 * To run a mutation, you first call `useInvoiceCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceCancelMutation, { data, loading, error }] = useInvoiceCancelMutation({
 *   variables: {
 *      ReasonCode: // value for 'ReasonCode'
 *      Reason: // value for 'Reason'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useInvoiceCancelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InvoiceCancelMutation, InvoiceCancelMutationVariables>) {
        return ApolloReactHooks.useMutation<InvoiceCancelMutation, InvoiceCancelMutationVariables>(InvoiceCancelDocument, baseOptions);
      }
export type InvoiceCancelMutationHookResult = ReturnType<typeof useInvoiceCancelMutation>;
export type InvoiceCancelMutationResult = ApolloReactCommon.MutationResult<InvoiceCancelMutation>;
export type InvoiceCancelMutationOptions = ApolloReactCommon.BaseMutationOptions<InvoiceCancelMutation, InvoiceCancelMutationVariables>;
export const AllocateInvoiceDocument = gql`
    mutation AllocateInvoice($allocInput: [DebitAllocationInput!]!, $InvoiceID: String!) {
  AllocateInvoice(allocInput: $allocInput, InvoiceID: $InvoiceID)
}
    `;
export type AllocateInvoiceMutationFn = ApolloReactCommon.MutationFunction<AllocateInvoiceMutation, AllocateInvoiceMutationVariables>;

/**
 * __useAllocateInvoiceMutation__
 *
 * To run a mutation, you first call `useAllocateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAllocateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [allocateInvoiceMutation, { data, loading, error }] = useAllocateInvoiceMutation({
 *   variables: {
 *      allocInput: // value for 'allocInput'
 *      InvoiceID: // value for 'InvoiceID'
 *   },
 * });
 */
export function useAllocateInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AllocateInvoiceMutation, AllocateInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<AllocateInvoiceMutation, AllocateInvoiceMutationVariables>(AllocateInvoiceDocument, baseOptions);
      }
export type AllocateInvoiceMutationHookResult = ReturnType<typeof useAllocateInvoiceMutation>;
export type AllocateInvoiceMutationResult = ApolloReactCommon.MutationResult<AllocateInvoiceMutation>;
export type AllocateInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<AllocateInvoiceMutation, AllocateInvoiceMutationVariables>;
export const InvoiceInfoDocument = gql`
    query InvoiceInfo($HotelID: String!, $DebtorID: String!) {
  InvoiceInfo(HotelID: $HotelID, DebtorID: $DebtorID) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
}
    `;

/**
 * __useInvoiceInfoQuery__
 *
 * To run a query within a React component, call `useInvoiceInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceInfoQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useInvoiceInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InvoiceInfoQuery, InvoiceInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<InvoiceInfoQuery, InvoiceInfoQueryVariables>(InvoiceInfoDocument, baseOptions);
      }
export function useInvoiceInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InvoiceInfoQuery, InvoiceInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InvoiceInfoQuery, InvoiceInfoQueryVariables>(InvoiceInfoDocument, baseOptions);
        }
export type InvoiceInfoQueryHookResult = ReturnType<typeof useInvoiceInfoQuery>;
export type InvoiceInfoLazyQueryHookResult = ReturnType<typeof useInvoiceInfoLazyQuery>;
export type InvoiceInfoQueryResult = ApolloReactCommon.QueryResult<InvoiceInfoQuery, InvoiceInfoQueryVariables>;
export const DebitNoteListingsDocument = gql`
    query DebitNoteListings($HotelID: String!, $DebtorID: String!) {
  DebitNoteListings(HotelID: $HotelID, DebtorID: $DebtorID) {
    ID
    DocumentNo
    ReferenceNo
    Description
    DocumentDate
    Amount
    Status
    HotelID
    AccountID
    DebtorID
    Attachment
    Currency
    OutstandingAmount
    CurrencyRate
    Reason
    VoidDate
    PostedDate
    LedgerTrx {
      TransactionID
      TrxName
      TrxAmount
      ReferenceNo
      UnitPrice
      Quantity
      DiscountedAmount
      BaseAmount
      TaxAmount
      ServiceCharge
      Remarks
    }
  }
}
    `;

/**
 * __useDebitNoteListingsQuery__
 *
 * To run a query within a React component, call `useDebitNoteListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebitNoteListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebitNoteListingsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useDebitNoteListingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebitNoteListingsQuery, DebitNoteListingsQueryVariables>) {
        return ApolloReactHooks.useQuery<DebitNoteListingsQuery, DebitNoteListingsQueryVariables>(DebitNoteListingsDocument, baseOptions);
      }
export function useDebitNoteListingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebitNoteListingsQuery, DebitNoteListingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebitNoteListingsQuery, DebitNoteListingsQueryVariables>(DebitNoteListingsDocument, baseOptions);
        }
export type DebitNoteListingsQueryHookResult = ReturnType<typeof useDebitNoteListingsQuery>;
export type DebitNoteListingsLazyQueryHookResult = ReturnType<typeof useDebitNoteListingsLazyQuery>;
export type DebitNoteListingsQueryResult = ApolloReactCommon.QueryResult<DebitNoteListingsQuery, DebitNoteListingsQueryVariables>;
export const DebitNoteDetailDocument = gql`
    query DebitNoteDetail($ID: String!) {
  DebitNoteDetail(ID: $ID) {
    ID
    DocumentNo
    ReferenceNo
    Description
    DocumentDate
    Amount
    Status
    HotelID
    AccountID
    DebtorID
    Attachment
    Currency
    OutstandingAmount
    CurrencyRate
    Reason
    VoidDate
    PostedDate
    LedgerTrx {
      TransactionID
      TrxName
      TrxAmount
      ReferenceNo
      UnitPrice
      Quantity
      DiscountedAmount
      BaseAmount
      TaxAmount
      ServiceCharge
      Remarks
    }
    Allocation {
      ID
      HotelID
      DebtorID
      AccountID
      DebitRefTable
      DebitID
      CreditRefTable
      CreditID
      AllocationAmount
    }
  }
}
    `;

/**
 * __useDebitNoteDetailQuery__
 *
 * To run a query within a React component, call `useDebitNoteDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebitNoteDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebitNoteDetailQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDebitNoteDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebitNoteDetailQuery, DebitNoteDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<DebitNoteDetailQuery, DebitNoteDetailQueryVariables>(DebitNoteDetailDocument, baseOptions);
      }
export function useDebitNoteDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebitNoteDetailQuery, DebitNoteDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebitNoteDetailQuery, DebitNoteDetailQueryVariables>(DebitNoteDetailDocument, baseOptions);
        }
export type DebitNoteDetailQueryHookResult = ReturnType<typeof useDebitNoteDetailQuery>;
export type DebitNoteDetailLazyQueryHookResult = ReturnType<typeof useDebitNoteDetailLazyQuery>;
export type DebitNoteDetailQueryResult = ApolloReactCommon.QueryResult<DebitNoteDetailQuery, DebitNoteDetailQueryVariables>;
export const DebitNoteInsertDocument = gql`
    mutation DebitNoteInsert($input: DebitNoteEntityInput!, $ChargeItemInput: [DebitNoteTrxInput!]!) {
  DebitNoteInsert(input: $input, ChargeItemInput: $ChargeItemInput) {
    ID
  }
}
    `;
export type DebitNoteInsertMutationFn = ApolloReactCommon.MutationFunction<DebitNoteInsertMutation, DebitNoteInsertMutationVariables>;

/**
 * __useDebitNoteInsertMutation__
 *
 * To run a mutation, you first call `useDebitNoteInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDebitNoteInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [debitNoteInsertMutation, { data, loading, error }] = useDebitNoteInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ChargeItemInput: // value for 'ChargeItemInput'
 *   },
 * });
 */
export function useDebitNoteInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DebitNoteInsertMutation, DebitNoteInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<DebitNoteInsertMutation, DebitNoteInsertMutationVariables>(DebitNoteInsertDocument, baseOptions);
      }
export type DebitNoteInsertMutationHookResult = ReturnType<typeof useDebitNoteInsertMutation>;
export type DebitNoteInsertMutationResult = ApolloReactCommon.MutationResult<DebitNoteInsertMutation>;
export type DebitNoteInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<DebitNoteInsertMutation, DebitNoteInsertMutationVariables>;
export const DebitNoteUpdateDocument = gql`
    mutation DebitNoteUpdate($input: DebitNoteEntityInput!, $ChargeItemInput: [DebitNoteTrxInput!]!, $ID: String!) {
  DebitNoteUpdate(input: $input, ChargeItemInput: $ChargeItemInput, ID: $ID)
}
    `;
export type DebitNoteUpdateMutationFn = ApolloReactCommon.MutationFunction<DebitNoteUpdateMutation, DebitNoteUpdateMutationVariables>;

/**
 * __useDebitNoteUpdateMutation__
 *
 * To run a mutation, you first call `useDebitNoteUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDebitNoteUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [debitNoteUpdateMutation, { data, loading, error }] = useDebitNoteUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ChargeItemInput: // value for 'ChargeItemInput'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDebitNoteUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DebitNoteUpdateMutation, DebitNoteUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<DebitNoteUpdateMutation, DebitNoteUpdateMutationVariables>(DebitNoteUpdateDocument, baseOptions);
      }
export type DebitNoteUpdateMutationHookResult = ReturnType<typeof useDebitNoteUpdateMutation>;
export type DebitNoteUpdateMutationResult = ApolloReactCommon.MutationResult<DebitNoteUpdateMutation>;
export type DebitNoteUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<DebitNoteUpdateMutation, DebitNoteUpdateMutationVariables>;
export const DebitNoteCancelDocument = gql`
    mutation DebitNoteCancel($ReasonCode: String!, $Reason: String!, $ID: String!) {
  DebitNoteCancel(Reason: $Reason, ReasonCode: $ReasonCode, ID: $ID)
}
    `;
export type DebitNoteCancelMutationFn = ApolloReactCommon.MutationFunction<DebitNoteCancelMutation, DebitNoteCancelMutationVariables>;

/**
 * __useDebitNoteCancelMutation__
 *
 * To run a mutation, you first call `useDebitNoteCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDebitNoteCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [debitNoteCancelMutation, { data, loading, error }] = useDebitNoteCancelMutation({
 *   variables: {
 *      ReasonCode: // value for 'ReasonCode'
 *      Reason: // value for 'Reason'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDebitNoteCancelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DebitNoteCancelMutation, DebitNoteCancelMutationVariables>) {
        return ApolloReactHooks.useMutation<DebitNoteCancelMutation, DebitNoteCancelMutationVariables>(DebitNoteCancelDocument, baseOptions);
      }
export type DebitNoteCancelMutationHookResult = ReturnType<typeof useDebitNoteCancelMutation>;
export type DebitNoteCancelMutationResult = ApolloReactCommon.MutationResult<DebitNoteCancelMutation>;
export type DebitNoteCancelMutationOptions = ApolloReactCommon.BaseMutationOptions<DebitNoteCancelMutation, DebitNoteCancelMutationVariables>;
export const AllocateDebitNoteDocument = gql`
    mutation AllocateDebitNote($allocInput: [DebitAllocationInput!]!, $DebitNoteID: String!) {
  AllocateDebitNote(allocInput: $allocInput, DebitNoteID: $DebitNoteID)
}
    `;
export type AllocateDebitNoteMutationFn = ApolloReactCommon.MutationFunction<AllocateDebitNoteMutation, AllocateDebitNoteMutationVariables>;

/**
 * __useAllocateDebitNoteMutation__
 *
 * To run a mutation, you first call `useAllocateDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAllocateDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [allocateDebitNoteMutation, { data, loading, error }] = useAllocateDebitNoteMutation({
 *   variables: {
 *      allocInput: // value for 'allocInput'
 *      DebitNoteID: // value for 'DebitNoteID'
 *   },
 * });
 */
export function useAllocateDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AllocateDebitNoteMutation, AllocateDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<AllocateDebitNoteMutation, AllocateDebitNoteMutationVariables>(AllocateDebitNoteDocument, baseOptions);
      }
export type AllocateDebitNoteMutationHookResult = ReturnType<typeof useAllocateDebitNoteMutation>;
export type AllocateDebitNoteMutationResult = ApolloReactCommon.MutationResult<AllocateDebitNoteMutation>;
export type AllocateDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<AllocateDebitNoteMutation, AllocateDebitNoteMutationVariables>;
export const DebitNoteInfoDocument = gql`
    query DebitNoteInfo($HotelID: String!, $DebtorID: String!) {
  DebitNoteInfo(HotelID: $HotelID, DebtorID: $DebtorID) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
}
    `;

/**
 * __useDebitNoteInfoQuery__
 *
 * To run a query within a React component, call `useDebitNoteInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebitNoteInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebitNoteInfoQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useDebitNoteInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebitNoteInfoQuery, DebitNoteInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<DebitNoteInfoQuery, DebitNoteInfoQueryVariables>(DebitNoteInfoDocument, baseOptions);
      }
export function useDebitNoteInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebitNoteInfoQuery, DebitNoteInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebitNoteInfoQuery, DebitNoteInfoQueryVariables>(DebitNoteInfoDocument, baseOptions);
        }
export type DebitNoteInfoQueryHookResult = ReturnType<typeof useDebitNoteInfoQuery>;
export type DebitNoteInfoLazyQueryHookResult = ReturnType<typeof useDebitNoteInfoLazyQuery>;
export type DebitNoteInfoQueryResult = ApolloReactCommon.QueryResult<DebitNoteInfoQuery, DebitNoteInfoQueryVariables>;
export const CreditNoteListingsDocument = gql`
    query CreditNoteListings($HotelID: String!, $DebtorID: String!) {
  CreditNoteListings(HotelID: $HotelID, DebtorID: $DebtorID) {
    ID
    DocumentNo
    ReferenceNo
    Description
    DocumentDate
    Amount
    Status
    HotelID
    AccountID
    DebtorID
    Attachment
    Currency
    OutstandingAmount
    CurrencyRate
    Reason
    VoidDate
    PostedDate
    LedgerTrx {
      TransactionID
      TrxName
      TrxAmount
      ReferenceNo
      UnitPrice
      Quantity
      DiscountedAmount
      BaseAmount
      TaxAmount
      ServiceCharge
      Remarks
    }
  }
}
    `;

/**
 * __useCreditNoteListingsQuery__
 *
 * To run a query within a React component, call `useCreditNoteListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditNoteListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditNoteListingsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useCreditNoteListingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CreditNoteListingsQuery, CreditNoteListingsQueryVariables>) {
        return ApolloReactHooks.useQuery<CreditNoteListingsQuery, CreditNoteListingsQueryVariables>(CreditNoteListingsDocument, baseOptions);
      }
export function useCreditNoteListingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CreditNoteListingsQuery, CreditNoteListingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CreditNoteListingsQuery, CreditNoteListingsQueryVariables>(CreditNoteListingsDocument, baseOptions);
        }
export type CreditNoteListingsQueryHookResult = ReturnType<typeof useCreditNoteListingsQuery>;
export type CreditNoteListingsLazyQueryHookResult = ReturnType<typeof useCreditNoteListingsLazyQuery>;
export type CreditNoteListingsQueryResult = ApolloReactCommon.QueryResult<CreditNoteListingsQuery, CreditNoteListingsQueryVariables>;
export const CreditNoteDetailDocument = gql`
    query CreditNoteDetail($ID: String!) {
  CreditNoteDetail(ID: $ID) {
    ID
    DocumentNo
    ReferenceNo
    Description
    DocumentDate
    Amount
    Status
    HotelID
    AccountID
    DebtorID
    Attachment
    Currency
    OutstandingAmount
    CurrencyRate
    Reason
    VoidDate
    PostedDate
    LedgerTrx {
      TransactionID
      TrxName
      TrxAmount
      ReferenceNo
      UnitPrice
      Quantity
      DiscountedAmount
      BaseAmount
      TaxAmount
      ServiceCharge
      Remarks
    }
    Allocation {
      ID
      HotelID
      DebtorID
      AccountID
      DebitRefTable
      DebitID
      CreditRefTable
      CreditID
      AllocationAmount
    }
  }
}
    `;

/**
 * __useCreditNoteDetailQuery__
 *
 * To run a query within a React component, call `useCreditNoteDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditNoteDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditNoteDetailQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCreditNoteDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CreditNoteDetailQuery, CreditNoteDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<CreditNoteDetailQuery, CreditNoteDetailQueryVariables>(CreditNoteDetailDocument, baseOptions);
      }
export function useCreditNoteDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CreditNoteDetailQuery, CreditNoteDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CreditNoteDetailQuery, CreditNoteDetailQueryVariables>(CreditNoteDetailDocument, baseOptions);
        }
export type CreditNoteDetailQueryHookResult = ReturnType<typeof useCreditNoteDetailQuery>;
export type CreditNoteDetailLazyQueryHookResult = ReturnType<typeof useCreditNoteDetailLazyQuery>;
export type CreditNoteDetailQueryResult = ApolloReactCommon.QueryResult<CreditNoteDetailQuery, CreditNoteDetailQueryVariables>;
export const CreditNoteInsertDocument = gql`
    mutation CreditNoteInsert($input: CreditNoteEntityInput!, $ChargeItemInput: [CreditNoteTrxInput!]!) {
  CreditNoteInsert(input: $input, ChargeItemInput: $ChargeItemInput) {
    ID
  }
}
    `;
export type CreditNoteInsertMutationFn = ApolloReactCommon.MutationFunction<CreditNoteInsertMutation, CreditNoteInsertMutationVariables>;

/**
 * __useCreditNoteInsertMutation__
 *
 * To run a mutation, you first call `useCreditNoteInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreditNoteInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creditNoteInsertMutation, { data, loading, error }] = useCreditNoteInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ChargeItemInput: // value for 'ChargeItemInput'
 *   },
 * });
 */
export function useCreditNoteInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreditNoteInsertMutation, CreditNoteInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<CreditNoteInsertMutation, CreditNoteInsertMutationVariables>(CreditNoteInsertDocument, baseOptions);
      }
export type CreditNoteInsertMutationHookResult = ReturnType<typeof useCreditNoteInsertMutation>;
export type CreditNoteInsertMutationResult = ApolloReactCommon.MutationResult<CreditNoteInsertMutation>;
export type CreditNoteInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<CreditNoteInsertMutation, CreditNoteInsertMutationVariables>;
export const CreditNoteUpdateDocument = gql`
    mutation CreditNoteUpdate($input: CreditNoteEntityInput!, $ChargeItemInput: [DebitNoteTrxInput!]!, $ID: String!) {
  CreditNoteUpdate(input: $input, ChargeItemInput: $ChargeItemInput, ID: $ID)
}
    `;
export type CreditNoteUpdateMutationFn = ApolloReactCommon.MutationFunction<CreditNoteUpdateMutation, CreditNoteUpdateMutationVariables>;

/**
 * __useCreditNoteUpdateMutation__
 *
 * To run a mutation, you first call `useCreditNoteUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreditNoteUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creditNoteUpdateMutation, { data, loading, error }] = useCreditNoteUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ChargeItemInput: // value for 'ChargeItemInput'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCreditNoteUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreditNoteUpdateMutation, CreditNoteUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreditNoteUpdateMutation, CreditNoteUpdateMutationVariables>(CreditNoteUpdateDocument, baseOptions);
      }
export type CreditNoteUpdateMutationHookResult = ReturnType<typeof useCreditNoteUpdateMutation>;
export type CreditNoteUpdateMutationResult = ApolloReactCommon.MutationResult<CreditNoteUpdateMutation>;
export type CreditNoteUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreditNoteUpdateMutation, CreditNoteUpdateMutationVariables>;
export const CreditNoteCancelDocument = gql`
    mutation CreditNoteCancel($Reason: String!, $ReasonCode: String!, $ID: String!) {
  CreditNoteCancel(Reason: $Reason, ReasonCode: $ReasonCode, ID: $ID)
}
    `;
export type CreditNoteCancelMutationFn = ApolloReactCommon.MutationFunction<CreditNoteCancelMutation, CreditNoteCancelMutationVariables>;

/**
 * __useCreditNoteCancelMutation__
 *
 * To run a mutation, you first call `useCreditNoteCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreditNoteCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creditNoteCancelMutation, { data, loading, error }] = useCreditNoteCancelMutation({
 *   variables: {
 *      Reason: // value for 'Reason'
 *      ReasonCode: // value for 'ReasonCode'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCreditNoteCancelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreditNoteCancelMutation, CreditNoteCancelMutationVariables>) {
        return ApolloReactHooks.useMutation<CreditNoteCancelMutation, CreditNoteCancelMutationVariables>(CreditNoteCancelDocument, baseOptions);
      }
export type CreditNoteCancelMutationHookResult = ReturnType<typeof useCreditNoteCancelMutation>;
export type CreditNoteCancelMutationResult = ApolloReactCommon.MutationResult<CreditNoteCancelMutation>;
export type CreditNoteCancelMutationOptions = ApolloReactCommon.BaseMutationOptions<CreditNoteCancelMutation, CreditNoteCancelMutationVariables>;
export const AllocateCreditNoteDocument = gql`
    mutation AllocateCreditNote($allocInput: [CreditAllocationInput!]!, $CreditNoteID: String!) {
  AllocateCreditNote(allocInput: $allocInput, CreditNoteID: $CreditNoteID)
}
    `;
export type AllocateCreditNoteMutationFn = ApolloReactCommon.MutationFunction<AllocateCreditNoteMutation, AllocateCreditNoteMutationVariables>;

/**
 * __useAllocateCreditNoteMutation__
 *
 * To run a mutation, you first call `useAllocateCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAllocateCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [allocateCreditNoteMutation, { data, loading, error }] = useAllocateCreditNoteMutation({
 *   variables: {
 *      allocInput: // value for 'allocInput'
 *      CreditNoteID: // value for 'CreditNoteID'
 *   },
 * });
 */
export function useAllocateCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AllocateCreditNoteMutation, AllocateCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<AllocateCreditNoteMutation, AllocateCreditNoteMutationVariables>(AllocateCreditNoteDocument, baseOptions);
      }
export type AllocateCreditNoteMutationHookResult = ReturnType<typeof useAllocateCreditNoteMutation>;
export type AllocateCreditNoteMutationResult = ApolloReactCommon.MutationResult<AllocateCreditNoteMutation>;
export type AllocateCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<AllocateCreditNoteMutation, AllocateCreditNoteMutationVariables>;
export const CreditNoteInfoDocument = gql`
    query CreditNoteInfo($HotelID: String!, $DebtorID: String!) {
  CreditNoteInfo(HotelID: $HotelID, DebtorID: $DebtorID) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
}
    `;

/**
 * __useCreditNoteInfoQuery__
 *
 * To run a query within a React component, call `useCreditNoteInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditNoteInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditNoteInfoQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useCreditNoteInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CreditNoteInfoQuery, CreditNoteInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<CreditNoteInfoQuery, CreditNoteInfoQueryVariables>(CreditNoteInfoDocument, baseOptions);
      }
export function useCreditNoteInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CreditNoteInfoQuery, CreditNoteInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CreditNoteInfoQuery, CreditNoteInfoQueryVariables>(CreditNoteInfoDocument, baseOptions);
        }
export type CreditNoteInfoQueryHookResult = ReturnType<typeof useCreditNoteInfoQuery>;
export type CreditNoteInfoLazyQueryHookResult = ReturnType<typeof useCreditNoteInfoLazyQuery>;
export type CreditNoteInfoQueryResult = ApolloReactCommon.QueryResult<CreditNoteInfoQuery, CreditNoteInfoQueryVariables>;
export const OfficialReceiptListingsDocument = gql`
    query OfficialReceiptListings($HotelID: String!, $DebtorID: String!) {
  OfficialReceiptListings(HotelID: $HotelID, DebtorID: $DebtorID) {
    ID
    DocumentNo
    ReferenceNo
    Description
    DocumentDate
    Amount
    Status
    HotelID
    AccountID
    DebtorID
    Attachment
    Currency
    OutstandingAmount
    CurrencyRate
    Reason
    VoidDate
    PostedDate
    Commission
  }
}
    `;

/**
 * __useOfficialReceiptListingsQuery__
 *
 * To run a query within a React component, call `useOfficialReceiptListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficialReceiptListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficialReceiptListingsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useOfficialReceiptListingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OfficialReceiptListingsQuery, OfficialReceiptListingsQueryVariables>) {
        return ApolloReactHooks.useQuery<OfficialReceiptListingsQuery, OfficialReceiptListingsQueryVariables>(OfficialReceiptListingsDocument, baseOptions);
      }
export function useOfficialReceiptListingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OfficialReceiptListingsQuery, OfficialReceiptListingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OfficialReceiptListingsQuery, OfficialReceiptListingsQueryVariables>(OfficialReceiptListingsDocument, baseOptions);
        }
export type OfficialReceiptListingsQueryHookResult = ReturnType<typeof useOfficialReceiptListingsQuery>;
export type OfficialReceiptListingsLazyQueryHookResult = ReturnType<typeof useOfficialReceiptListingsLazyQuery>;
export type OfficialReceiptListingsQueryResult = ApolloReactCommon.QueryResult<OfficialReceiptListingsQuery, OfficialReceiptListingsQueryVariables>;
export const OfficialReceiptDetailDocument = gql`
    query OfficialReceiptDetail($ID: String!) {
  OfficialReceiptDetail(ID: $ID) {
    ID
    DocumentNo
    ReferenceNo
    Description
    DocumentDate
    Amount
    Status
    HotelID
    AccountID
    DebtorID
    Attachment
    Currency
    OutstandingAmount
    CurrencyRate
    Reason
    VoidDate
    PostedDate
    Commission
    Allocation {
      ID
      HotelID
      DebtorID
      AccountID
      DebitRefTable
      DebitID
      CreditRefTable
      CreditID
      AllocationAmount
    }
  }
}
    `;

/**
 * __useOfficialReceiptDetailQuery__
 *
 * To run a query within a React component, call `useOfficialReceiptDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficialReceiptDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficialReceiptDetailQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useOfficialReceiptDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OfficialReceiptDetailQuery, OfficialReceiptDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<OfficialReceiptDetailQuery, OfficialReceiptDetailQueryVariables>(OfficialReceiptDetailDocument, baseOptions);
      }
export function useOfficialReceiptDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OfficialReceiptDetailQuery, OfficialReceiptDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OfficialReceiptDetailQuery, OfficialReceiptDetailQueryVariables>(OfficialReceiptDetailDocument, baseOptions);
        }
export type OfficialReceiptDetailQueryHookResult = ReturnType<typeof useOfficialReceiptDetailQuery>;
export type OfficialReceiptDetailLazyQueryHookResult = ReturnType<typeof useOfficialReceiptDetailLazyQuery>;
export type OfficialReceiptDetailQueryResult = ApolloReactCommon.QueryResult<OfficialReceiptDetailQuery, OfficialReceiptDetailQueryVariables>;
export const OfficialReceiptInsertDocument = gql`
    mutation OfficialReceiptInsert($input: OfficialReceiptEntityInput!) {
  OfficialReceiptInsert(input: $input) {
    ID
  }
}
    `;
export type OfficialReceiptInsertMutationFn = ApolloReactCommon.MutationFunction<OfficialReceiptInsertMutation, OfficialReceiptInsertMutationVariables>;

/**
 * __useOfficialReceiptInsertMutation__
 *
 * To run a mutation, you first call `useOfficialReceiptInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfficialReceiptInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [officialReceiptInsertMutation, { data, loading, error }] = useOfficialReceiptInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfficialReceiptInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OfficialReceiptInsertMutation, OfficialReceiptInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<OfficialReceiptInsertMutation, OfficialReceiptInsertMutationVariables>(OfficialReceiptInsertDocument, baseOptions);
      }
export type OfficialReceiptInsertMutationHookResult = ReturnType<typeof useOfficialReceiptInsertMutation>;
export type OfficialReceiptInsertMutationResult = ApolloReactCommon.MutationResult<OfficialReceiptInsertMutation>;
export type OfficialReceiptInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<OfficialReceiptInsertMutation, OfficialReceiptInsertMutationVariables>;
export const OfficialReceiptUpdateDocument = gql`
    mutation OfficialReceiptUpdate($input: OfficialReceiptEntityInput!, $ID: String!) {
  OfficialReceiptUpdate(input: $input, ID: $ID)
}
    `;
export type OfficialReceiptUpdateMutationFn = ApolloReactCommon.MutationFunction<OfficialReceiptUpdateMutation, OfficialReceiptUpdateMutationVariables>;

/**
 * __useOfficialReceiptUpdateMutation__
 *
 * To run a mutation, you first call `useOfficialReceiptUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfficialReceiptUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [officialReceiptUpdateMutation, { data, loading, error }] = useOfficialReceiptUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useOfficialReceiptUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OfficialReceiptUpdateMutation, OfficialReceiptUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<OfficialReceiptUpdateMutation, OfficialReceiptUpdateMutationVariables>(OfficialReceiptUpdateDocument, baseOptions);
      }
export type OfficialReceiptUpdateMutationHookResult = ReturnType<typeof useOfficialReceiptUpdateMutation>;
export type OfficialReceiptUpdateMutationResult = ApolloReactCommon.MutationResult<OfficialReceiptUpdateMutation>;
export type OfficialReceiptUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<OfficialReceiptUpdateMutation, OfficialReceiptUpdateMutationVariables>;
export const OfficialReceiptCancelDocument = gql`
    mutation OfficialReceiptCancel($ReasonCode: String!, $Reason: String!, $ID: String!) {
  OfficialReceiptCancel(ReasonCode: $ReasonCode, Reason: $Reason, ID: $ID)
}
    `;
export type OfficialReceiptCancelMutationFn = ApolloReactCommon.MutationFunction<OfficialReceiptCancelMutation, OfficialReceiptCancelMutationVariables>;

/**
 * __useOfficialReceiptCancelMutation__
 *
 * To run a mutation, you first call `useOfficialReceiptCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfficialReceiptCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [officialReceiptCancelMutation, { data, loading, error }] = useOfficialReceiptCancelMutation({
 *   variables: {
 *      ReasonCode: // value for 'ReasonCode'
 *      Reason: // value for 'Reason'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useOfficialReceiptCancelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OfficialReceiptCancelMutation, OfficialReceiptCancelMutationVariables>) {
        return ApolloReactHooks.useMutation<OfficialReceiptCancelMutation, OfficialReceiptCancelMutationVariables>(OfficialReceiptCancelDocument, baseOptions);
      }
export type OfficialReceiptCancelMutationHookResult = ReturnType<typeof useOfficialReceiptCancelMutation>;
export type OfficialReceiptCancelMutationResult = ApolloReactCommon.MutationResult<OfficialReceiptCancelMutation>;
export type OfficialReceiptCancelMutationOptions = ApolloReactCommon.BaseMutationOptions<OfficialReceiptCancelMutation, OfficialReceiptCancelMutationVariables>;
export const AllocateOfficialReceiptDocument = gql`
    mutation AllocateOfficialReceipt($allocInput: [CreditAllocationInput!]!, $ReceiptID: String!) {
  AllocateOfficialReceipt(allocInput: $allocInput, ReceiptID: $ReceiptID)
}
    `;
export type AllocateOfficialReceiptMutationFn = ApolloReactCommon.MutationFunction<AllocateOfficialReceiptMutation, AllocateOfficialReceiptMutationVariables>;

/**
 * __useAllocateOfficialReceiptMutation__
 *
 * To run a mutation, you first call `useAllocateOfficialReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAllocateOfficialReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [allocateOfficialReceiptMutation, { data, loading, error }] = useAllocateOfficialReceiptMutation({
 *   variables: {
 *      allocInput: // value for 'allocInput'
 *      ReceiptID: // value for 'ReceiptID'
 *   },
 * });
 */
export function useAllocateOfficialReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AllocateOfficialReceiptMutation, AllocateOfficialReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<AllocateOfficialReceiptMutation, AllocateOfficialReceiptMutationVariables>(AllocateOfficialReceiptDocument, baseOptions);
      }
export type AllocateOfficialReceiptMutationHookResult = ReturnType<typeof useAllocateOfficialReceiptMutation>;
export type AllocateOfficialReceiptMutationResult = ApolloReactCommon.MutationResult<AllocateOfficialReceiptMutation>;
export type AllocateOfficialReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<AllocateOfficialReceiptMutation, AllocateOfficialReceiptMutationVariables>;
export const OfficialReceiptInfoDocument = gql`
    query OfficialReceiptInfo($HotelID: String!, $DebtorID: String!) {
  OfficialReceiptInfo(HotelID: $HotelID, DebtorID: $DebtorID) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
}
    `;

/**
 * __useOfficialReceiptInfoQuery__
 *
 * To run a query within a React component, call `useOfficialReceiptInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficialReceiptInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficialReceiptInfoQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useOfficialReceiptInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OfficialReceiptInfoQuery, OfficialReceiptInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<OfficialReceiptInfoQuery, OfficialReceiptInfoQueryVariables>(OfficialReceiptInfoDocument, baseOptions);
      }
export function useOfficialReceiptInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OfficialReceiptInfoQuery, OfficialReceiptInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OfficialReceiptInfoQuery, OfficialReceiptInfoQueryVariables>(OfficialReceiptInfoDocument, baseOptions);
        }
export type OfficialReceiptInfoQueryHookResult = ReturnType<typeof useOfficialReceiptInfoQuery>;
export type OfficialReceiptInfoLazyQueryHookResult = ReturnType<typeof useOfficialReceiptInfoLazyQuery>;
export type OfficialReceiptInfoQueryResult = ApolloReactCommon.QueryResult<OfficialReceiptInfoQuery, OfficialReceiptInfoQueryVariables>;
export const RefundListingsDocument = gql`
    query RefundListings($HotelID: String!, $DebtorID: String!) {
  RefundListings(HotelID: $HotelID, DebtorID: $DebtorID) {
    ID
    DocumentNo
    ReferenceNo
    Description
    DocumentDate
    Amount
    Status
    HotelID
    AccountID
    DebtorID
    Attachment
    Currency
    OutstandingAmount
    CurrencyRate
    Reason
    VoidDate
    PostedDate
    Commission
  }
}
    `;

/**
 * __useRefundListingsQuery__
 *
 * To run a query within a React component, call `useRefundListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefundListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefundListingsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useRefundListingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefundListingsQuery, RefundListingsQueryVariables>) {
        return ApolloReactHooks.useQuery<RefundListingsQuery, RefundListingsQueryVariables>(RefundListingsDocument, baseOptions);
      }
export function useRefundListingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefundListingsQuery, RefundListingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefundListingsQuery, RefundListingsQueryVariables>(RefundListingsDocument, baseOptions);
        }
export type RefundListingsQueryHookResult = ReturnType<typeof useRefundListingsQuery>;
export type RefundListingsLazyQueryHookResult = ReturnType<typeof useRefundListingsLazyQuery>;
export type RefundListingsQueryResult = ApolloReactCommon.QueryResult<RefundListingsQuery, RefundListingsQueryVariables>;
export const RefundDetailDocument = gql`
    query RefundDetail($ID: String!) {
  RefundDetail(ID: $ID) {
    ID
    DocumentNo
    ReferenceNo
    Description
    DocumentDate
    Amount
    Status
    HotelID
    AccountID
    DebtorID
    Attachment
    Currency
    OutstandingAmount
    CurrencyRate
    Reason
    VoidDate
    PostedDate
    Commission
    Allocation {
      ID
      HotelID
      DebtorID
      AccountID
      DebitRefTable
      DebitID
      CreditRefTable
      CreditID
      AllocationAmount
    }
  }
}
    `;

/**
 * __useRefundDetailQuery__
 *
 * To run a query within a React component, call `useRefundDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefundDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefundDetailQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useRefundDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefundDetailQuery, RefundDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<RefundDetailQuery, RefundDetailQueryVariables>(RefundDetailDocument, baseOptions);
      }
export function useRefundDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefundDetailQuery, RefundDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefundDetailQuery, RefundDetailQueryVariables>(RefundDetailDocument, baseOptions);
        }
export type RefundDetailQueryHookResult = ReturnType<typeof useRefundDetailQuery>;
export type RefundDetailLazyQueryHookResult = ReturnType<typeof useRefundDetailLazyQuery>;
export type RefundDetailQueryResult = ApolloReactCommon.QueryResult<RefundDetailQuery, RefundDetailQueryVariables>;
export const RefundInsertDocument = gql`
    mutation RefundInsert($input: RefundEntityInput!) {
  RefundInsert(input: $input) {
    ID
  }
}
    `;
export type RefundInsertMutationFn = ApolloReactCommon.MutationFunction<RefundInsertMutation, RefundInsertMutationVariables>;

/**
 * __useRefundInsertMutation__
 *
 * To run a mutation, you first call `useRefundInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundInsertMutation, { data, loading, error }] = useRefundInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefundInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefundInsertMutation, RefundInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<RefundInsertMutation, RefundInsertMutationVariables>(RefundInsertDocument, baseOptions);
      }
export type RefundInsertMutationHookResult = ReturnType<typeof useRefundInsertMutation>;
export type RefundInsertMutationResult = ApolloReactCommon.MutationResult<RefundInsertMutation>;
export type RefundInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<RefundInsertMutation, RefundInsertMutationVariables>;
export const RefundUpdateDocument = gql`
    mutation RefundUpdate($input: RefundEntityInput!, $ID: String!) {
  RefundUpdate(input: $input, ID: $ID)
}
    `;
export type RefundUpdateMutationFn = ApolloReactCommon.MutationFunction<RefundUpdateMutation, RefundUpdateMutationVariables>;

/**
 * __useRefundUpdateMutation__
 *
 * To run a mutation, you first call `useRefundUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundUpdateMutation, { data, loading, error }] = useRefundUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useRefundUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefundUpdateMutation, RefundUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<RefundUpdateMutation, RefundUpdateMutationVariables>(RefundUpdateDocument, baseOptions);
      }
export type RefundUpdateMutationHookResult = ReturnType<typeof useRefundUpdateMutation>;
export type RefundUpdateMutationResult = ApolloReactCommon.MutationResult<RefundUpdateMutation>;
export type RefundUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<RefundUpdateMutation, RefundUpdateMutationVariables>;
export const RefundCancelDocument = gql`
    mutation RefundCancel($ReasonCode: String!, $Reason: String!, $ID: String!) {
  RefundCancel(ReasonCode: $ReasonCode, Reason: $Reason, ID: $ID)
}
    `;
export type RefundCancelMutationFn = ApolloReactCommon.MutationFunction<RefundCancelMutation, RefundCancelMutationVariables>;

/**
 * __useRefundCancelMutation__
 *
 * To run a mutation, you first call `useRefundCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundCancelMutation, { data, loading, error }] = useRefundCancelMutation({
 *   variables: {
 *      ReasonCode: // value for 'ReasonCode'
 *      Reason: // value for 'Reason'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useRefundCancelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefundCancelMutation, RefundCancelMutationVariables>) {
        return ApolloReactHooks.useMutation<RefundCancelMutation, RefundCancelMutationVariables>(RefundCancelDocument, baseOptions);
      }
export type RefundCancelMutationHookResult = ReturnType<typeof useRefundCancelMutation>;
export type RefundCancelMutationResult = ApolloReactCommon.MutationResult<RefundCancelMutation>;
export type RefundCancelMutationOptions = ApolloReactCommon.BaseMutationOptions<RefundCancelMutation, RefundCancelMutationVariables>;
export const AllocateRefundDocument = gql`
    mutation AllocateRefund($allocInput: [CreditAllocationInput!]!, $RefundID: String!) {
  AllocateRefund(allocInput: $allocInput, RefundID: $RefundID)
}
    `;
export type AllocateRefundMutationFn = ApolloReactCommon.MutationFunction<AllocateRefundMutation, AllocateRefundMutationVariables>;

/**
 * __useAllocateRefundMutation__
 *
 * To run a mutation, you first call `useAllocateRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAllocateRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [allocateRefundMutation, { data, loading, error }] = useAllocateRefundMutation({
 *   variables: {
 *      allocInput: // value for 'allocInput'
 *      RefundID: // value for 'RefundID'
 *   },
 * });
 */
export function useAllocateRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AllocateRefundMutation, AllocateRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<AllocateRefundMutation, AllocateRefundMutationVariables>(AllocateRefundDocument, baseOptions);
      }
export type AllocateRefundMutationHookResult = ReturnType<typeof useAllocateRefundMutation>;
export type AllocateRefundMutationResult = ApolloReactCommon.MutationResult<AllocateRefundMutation>;
export type AllocateRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<AllocateRefundMutation, AllocateRefundMutationVariables>;
export const RefundInfoDocument = gql`
    query RefundInfo($HotelID: String!, $DebtorID: String!) {
  RefundInfo(HotelID: $HotelID, DebtorID: $DebtorID) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
}
    `;

/**
 * __useRefundInfoQuery__
 *
 * To run a query within a React component, call `useRefundInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefundInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefundInfoQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useRefundInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefundInfoQuery, RefundInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<RefundInfoQuery, RefundInfoQueryVariables>(RefundInfoDocument, baseOptions);
      }
export function useRefundInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefundInfoQuery, RefundInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefundInfoQuery, RefundInfoQueryVariables>(RefundInfoDocument, baseOptions);
        }
export type RefundInfoQueryHookResult = ReturnType<typeof useRefundInfoQuery>;
export type RefundInfoLazyQueryHookResult = ReturnType<typeof useRefundInfoLazyQuery>;
export type RefundInfoQueryResult = ApolloReactCommon.QueryResult<RefundInfoQuery, RefundInfoQueryVariables>;
export const IncidentalChargeListingDocument = gql`
    query IncidentalChargeListing($DepartmentID: String!) {
  IncidentalChargeListing(DepartmentID: $DepartmentID) {
    ID
    IsActive
    DepartmentID
    Description
    Amount
    Tax
    ServiceCharge
    IsRoomRevenue
    GalleryID
  }
}
    `;

/**
 * __useIncidentalChargeListingQuery__
 *
 * To run a query within a React component, call `useIncidentalChargeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncidentalChargeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncidentalChargeListingQuery({
 *   variables: {
 *      DepartmentID: // value for 'DepartmentID'
 *   },
 * });
 */
export function useIncidentalChargeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IncidentalChargeListingQuery, IncidentalChargeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<IncidentalChargeListingQuery, IncidentalChargeListingQueryVariables>(IncidentalChargeListingDocument, baseOptions);
      }
export function useIncidentalChargeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IncidentalChargeListingQuery, IncidentalChargeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IncidentalChargeListingQuery, IncidentalChargeListingQueryVariables>(IncidentalChargeListingDocument, baseOptions);
        }
export type IncidentalChargeListingQueryHookResult = ReturnType<typeof useIncidentalChargeListingQuery>;
export type IncidentalChargeListingLazyQueryHookResult = ReturnType<typeof useIncidentalChargeListingLazyQuery>;
export type IncidentalChargeListingQueryResult = ApolloReactCommon.QueryResult<IncidentalChargeListingQuery, IncidentalChargeListingQueryVariables>;
export const DebtorTypeListingDocument = gql`
    query DebtorTypeListing($ID: String) {
  DebtorTypeListing(ID: $ID) {
    ID
    DebtorType
    Description
    CreatedDT
  }
}
    `;

/**
 * __useDebtorTypeListingQuery__
 *
 * To run a query within a React component, call `useDebtorTypeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorTypeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorTypeListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDebtorTypeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorTypeListingQuery, DebtorTypeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorTypeListingQuery, DebtorTypeListingQueryVariables>(DebtorTypeListingDocument, baseOptions);
      }
export function useDebtorTypeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorTypeListingQuery, DebtorTypeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorTypeListingQuery, DebtorTypeListingQueryVariables>(DebtorTypeListingDocument, baseOptions);
        }
export type DebtorTypeListingQueryHookResult = ReturnType<typeof useDebtorTypeListingQuery>;
export type DebtorTypeListingLazyQueryHookResult = ReturnType<typeof useDebtorTypeListingLazyQuery>;
export type DebtorTypeListingQueryResult = ApolloReactCommon.QueryResult<DebtorTypeListingQuery, DebtorTypeListingQueryVariables>;
export const DebtorTypeUpdateDocument = gql`
    mutation DebtorTypeUpdate($input: DebtorTypeInput!, $ID: String!) {
  DebtorTypeUpdate(input: $input, ID: $ID)
}
    `;
export type DebtorTypeUpdateMutationFn = ApolloReactCommon.MutationFunction<DebtorTypeUpdateMutation, DebtorTypeUpdateMutationVariables>;

/**
 * __useDebtorTypeUpdateMutation__
 *
 * To run a mutation, you first call `useDebtorTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDebtorTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [debtorTypeUpdateMutation, { data, loading, error }] = useDebtorTypeUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDebtorTypeUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DebtorTypeUpdateMutation, DebtorTypeUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<DebtorTypeUpdateMutation, DebtorTypeUpdateMutationVariables>(DebtorTypeUpdateDocument, baseOptions);
      }
export type DebtorTypeUpdateMutationHookResult = ReturnType<typeof useDebtorTypeUpdateMutation>;
export type DebtorTypeUpdateMutationResult = ApolloReactCommon.MutationResult<DebtorTypeUpdateMutation>;
export type DebtorTypeUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<DebtorTypeUpdateMutation, DebtorTypeUpdateMutationVariables>;
export const DebtorTypeInsertDocument = gql`
    mutation DebtorTypeInsert($input: DebtorTypeInput!) {
  DebtorTypeInsert(input: $input) {
    ID
    DebtorType
    Description
    CreatedDT
  }
}
    `;
export type DebtorTypeInsertMutationFn = ApolloReactCommon.MutationFunction<DebtorTypeInsertMutation, DebtorTypeInsertMutationVariables>;

/**
 * __useDebtorTypeInsertMutation__
 *
 * To run a mutation, you first call `useDebtorTypeInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDebtorTypeInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [debtorTypeInsertMutation, { data, loading, error }] = useDebtorTypeInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDebtorTypeInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DebtorTypeInsertMutation, DebtorTypeInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<DebtorTypeInsertMutation, DebtorTypeInsertMutationVariables>(DebtorTypeInsertDocument, baseOptions);
      }
export type DebtorTypeInsertMutationHookResult = ReturnType<typeof useDebtorTypeInsertMutation>;
export type DebtorTypeInsertMutationResult = ApolloReactCommon.MutationResult<DebtorTypeInsertMutation>;
export type DebtorTypeInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<DebtorTypeInsertMutation, DebtorTypeInsertMutationVariables>;
export const DebtorTypeDeleteDocument = gql`
    mutation DebtorTypeDelete($ID: String!) {
  DebtorTypeDelete(ID: $ID)
}
    `;
export type DebtorTypeDeleteMutationFn = ApolloReactCommon.MutationFunction<DebtorTypeDeleteMutation, DebtorTypeDeleteMutationVariables>;

/**
 * __useDebtorTypeDeleteMutation__
 *
 * To run a mutation, you first call `useDebtorTypeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDebtorTypeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [debtorTypeDeleteMutation, { data, loading, error }] = useDebtorTypeDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDebtorTypeDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DebtorTypeDeleteMutation, DebtorTypeDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<DebtorTypeDeleteMutation, DebtorTypeDeleteMutationVariables>(DebtorTypeDeleteDocument, baseOptions);
      }
export type DebtorTypeDeleteMutationHookResult = ReturnType<typeof useDebtorTypeDeleteMutation>;
export type DebtorTypeDeleteMutationResult = ApolloReactCommon.MutationResult<DebtorTypeDeleteMutation>;
export type DebtorTypeDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<DebtorTypeDeleteMutation, DebtorTypeDeleteMutationVariables>;
export const HotelListingDocument = gql`
    query HotelListing($AccountID: String!) {
  HotelListing(AccountID: $AccountID) {
    ID
    HotelName
    CompanyRegNo
    HotelAddress {
      address
      state
      postCode
      city
      country
    }
    Debtor {
      ID
      IsActive
      DebtorCategory
      Name
      DebtorAccount
      CompanyRegNo
      GSTNo
      Currency
      CreditTerm
      CreditLimit
      Remarks
    }
  }
}
    `;

/**
 * __useHotelListingQuery__
 *
 * To run a query within a React component, call `useHotelListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelListingQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useHotelListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HotelListingQuery, HotelListingQueryVariables>) {
        return ApolloReactHooks.useQuery<HotelListingQuery, HotelListingQueryVariables>(HotelListingDocument, baseOptions);
      }
export function useHotelListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HotelListingQuery, HotelListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HotelListingQuery, HotelListingQueryVariables>(HotelListingDocument, baseOptions);
        }
export type HotelListingQueryHookResult = ReturnType<typeof useHotelListingQuery>;
export type HotelListingLazyQueryHookResult = ReturnType<typeof useHotelListingLazyQuery>;
export type HotelListingQueryResult = ApolloReactCommon.QueryResult<HotelListingQuery, HotelListingQueryVariables>;
export const HotelDetailsDocument = gql`
    query HotelDetails($HotelID: String, $OnlineBookingURL: String) {
  HotelDetails(HotelID: $HotelID, OnlineBookingURL: $OnlineBookingURL) {
    ID
    HotelName
  }
}
    `;

/**
 * __useHotelDetailsQuery__
 *
 * To run a query within a React component, call `useHotelDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelDetailsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      OnlineBookingURL: // value for 'OnlineBookingURL'
 *   },
 * });
 */
export function useHotelDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HotelDetailsQuery, HotelDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<HotelDetailsQuery, HotelDetailsQueryVariables>(HotelDetailsDocument, baseOptions);
      }
export function useHotelDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HotelDetailsQuery, HotelDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HotelDetailsQuery, HotelDetailsQueryVariables>(HotelDetailsDocument, baseOptions);
        }
export type HotelDetailsQueryHookResult = ReturnType<typeof useHotelDetailsQuery>;
export type HotelDetailsLazyQueryHookResult = ReturnType<typeof useHotelDetailsLazyQuery>;
export type HotelDetailsQueryResult = ApolloReactCommon.QueryResult<HotelDetailsQuery, HotelDetailsQueryVariables>;