import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers/'
// import 'assets/styles/app.scss'
import '@ifca-root/react-component/src/assets/styles/app.scss'
import Layout from 'components/Layout/Layout'
import { createBrowserHistory } from 'history'
import React, { useEffect, useState, useReducer } from 'react'
import { Router } from 'react-router-dom'
import Routes from './Router/Routes'
import AppContext from './Store/AppContext'
import { GlobalInitialState, RootReducer } from './Store/RootReducer'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '@ifca-root/react-component/src/assets/theme'
import { ApolloProvider } from '@apollo/react-hooks'
import { hotelxNodeRefreshUrl, HotelClient } from 'client'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { setAccessToken } from '../../AccessToken'
import 'assets/styles/arreact.scss'

export const history = createBrowserHistory()

const App = () => {
  const [globalState, dispatch] = useReducer(RootReducer, GlobalInitialState)

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    fetch(hotelxNodeRefreshUrl, {
      method: 'POST',
      credentials: 'include',
    }).then(async x => {
      const { accessToken } = await x.json()
      setAccessToken(accessToken)
      setLoading(false)
    })
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <AppContext.Provider value={{ globalState, dispatch }}>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={HotelClient}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Router history={history}>
              <Layout>
                <Routes />
              </Layout>
            </Router>
          </MuiPickersUtilsProvider>
        </ApolloProvider>
      </ThemeProvider>
    </AppContext.Provider>
  )
}
export default App
