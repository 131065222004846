import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink, Observable } from 'apollo-link'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import jwtDecode from 'jwt-decode'
import { getAccessToken, setAccessToken } from './AccessToken'
import { createUploadLink } from 'apollo-upload-client'
import { ApolloClient as UploadApolloClient } from 'apollo-client'
import { InMemoryCache as UploadInMemoryCache } from 'apollo-cache-inmemory'
const cache = new InMemoryCache({})

const hotelxNodeUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000/hotelx'
    : 'https://hotelxapi.hotelx.asia/hotelx'
export const hotelxNodeRefreshUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000/refresh_token_hotel'
    : 'https://hotelxapi.hotelx.asia/refresh_token_hotel'

//Getting access Token and passing it in request headers
const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle: any
      Promise.resolve(operation)
        .then(operation => {
          const accessToken = getAccessToken()
          if (accessToken) {
            operation.setContext({
              headers: {
                authorization: `bearer ${accessToken}`,
              },
            })
          } //accessToken is defined
        }) //then operation ends here
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          }) //handle ends here
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)

// const apolloCache = new UploadInMemoryCache({
//   addTypename: false,
// })
const uploadLink = createUploadLink({
  uri: hotelxNodeUrl,
  credentials: 'include',
})

// export const Uploadclient = new UploadApolloClient({
//   cache: apolloCache,
//   link: ApolloLink.from([requestLink, uploadLink]),
// })

export const HotelClient = new ApolloClient({
  link: ApolloLink.from([
    new TokenRefreshLink({
      accessTokenField: 'accessToken',
      isTokenValidOrUndefined: () => {
        const token = getAccessToken()

        if (!token) {
          return true
        }

        try {
          const { exp } = jwtDecode(token)
          if (Date.now() >= exp * 1000) {
            return false
          } else {
            return true
          }
        } catch (err) {
          console.log(err)
          return false
        }
      },
      fetchAccessToken: () => {
        return fetch(hotelxNodeRefreshUrl, {
          method: 'POST',
          credentials: 'include',
        })
      },
      handleFetch: accessToken => {
        setAccessToken(accessToken)
      },
      handleError: err => {
        console.warn('Your refresh token is invalid. Try to relogin')
        console.error(err)
      },
    }),
    onError(() => {}),
    requestLink,
    uploadLink,
    // new HttpLink({
    //   uri: hotelxNodeUrl,
    //   credentials: 'include',
    // }), //new HttpLink ends here
  ]),
  cache,
})
