import React, { lazy } from 'react'

const DebtorTypeSetupListing = lazy(() =>
  import('./DebtorTypeSetupListing').then(module => ({
    default: module.DebtorTypeSetupListing,
  }))
)

const DebtorTypeSetupRoutes = [
  {
    props: { exact: true, path: '/general-settings/debtorType' },
    component: <DebtorTypeSetupListing />,
  },
]

export default DebtorTypeSetupRoutes
