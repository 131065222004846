import React, { lazy } from 'react'

//General Settings//
const GeneralSettingList = lazy(() =>
  import('./GeneralSettingList').then(module => ({
    default: module.GeneralSettingList,
  }))
)

const generalSettingRoutes = [
  {
    props: { exact: true, path: '/general-settings' },
    component: <GeneralSettingList />,
  },
]

export default generalSettingRoutes
