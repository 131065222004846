import React, { lazy } from 'react'

const DebtorListing = lazy(() =>
  import('./DebtorListing').then(module => ({
    default: module.DebtorListing,
  }))
)

const DebtorProfile = lazy(() =>
  import('./DebtorProfile').then(module => ({
    default: module.DebtorProfile,
  }))
)

const debtorRoutes = [
  {
    props: { exact: true, path: '/debtor' },
    component: <DebtorListing />,
  },
  {
    props: { exact: true, path: '/' },
    component: <DebtorListing />,
  },
  {
    props: { exact: true, path: '/home' },
    component: <DebtorListing />,
  },
  {
    props: { exact: true, path: '/debtor/profile/:debtorID' },
    component: <DebtorProfile />,
  },
]

export default debtorRoutes
