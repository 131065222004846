import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useLogoutMutation } from 'generated/graphql'
import { setAccessToken } from 'AccessToken'
export const Logout = () => {
  const history = useHistory()
  const [logout, { client }] = useLogoutMutation()
  useEffect(() => {
    async function userLogout() {
      await logout()
      setAccessToken('')
      await client!.resetStore()
    }
    userLogout()
    history.push('/login')
  }, [])
  return <></>
}
